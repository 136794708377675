import { DefaultAtoms, UserId } from "@atoms/atom-types";
import { Atoms, Identifiers } from "@project/shared";

export const atomPathes = {
  [Atoms.CategoryInfo]: (name: string) => {
    return [`cat/${Identifiers.Code}/${name}`];
  },
  [Atoms.ProjectTree]: (name: string) => [`/${name.toLowerCase()}`],
  [Atoms.WorkpackageInfo]: (name: string) => {
    return [`wp/${Identifiers.Code}/${name}`];
  },
  [Atoms.VersionList]: (name: string) => [`/${name.toLowerCase()}`],
  [Atoms.Draft]: (name: string) => [`/${name.toLowerCase()}`],
  [Atoms.ProjectUsers]: (name: string) => [`/${name.toLowerCase()}`],
  [DefaultAtoms.PersonalUserData]: (name: string) => {
    return [`usr/${UserId}/${name}`];
  },
  [Atoms.ProjectTreeSnapshot]: (name: string) => {
    return [`version/${DefaultIdentifiers.Version}/${name}`];
  },
  [Atoms.ProjectActivity]: (name: string) => [`/${name.toLowerCase()}`],
  [Atoms.TemplateInfo]: (name: string) => {
    return [`template/${Identifiers.TemplateName}/${name}`];
  },
  [Atoms.TemplateList]: (name: string) => [`/${name.toLowerCase()}`],
};
