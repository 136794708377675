import { MagicFormContext, useMayUpdate } from "@atoms/atom-components";
import type { IWorkpackage } from "@project/shared";
import { InputNumber, Progress, Row } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext } from "react";
import {
  computeActivityStats,
  computeTimedProgress,
} from "../hooks/useWorkPackage";

export const TimeProgress = () => {
  const field = "signal";
  const { updateDraft, currentValue, loading } = useContext(MagicFormContext)!;
  const activityStats = computeActivityStats(currentValue as IWorkpackage);
  const mayUpdateProgress = useMayUpdate("progress");
  const timeProgress = computeTimedProgress(currentValue as IWorkpackage);

  return loading ? (
    <SkeletonInput active style={{ height: 56, width: 300 }} />
  ) : (
    <div className="float-label">
      <div className="label label-float">zeitlicher Fortschritt</div>
      <Row
        wrap={false}
        align="middle"
        className="progress"
        style={{ width: "300px" }}
      >
        <Progress percent={timeProgress} showInfo={false} />
        <InputNumber
          key={`time_progress-input`}
          style={{ marginLeft: "10px" }}
          value={timeProgress}
          disabled
          formatter={(value) => `${value}%`}
          parser={(value) =>
            Number.parseInt(value?.replace("%", "") ?? "") || 0
          }
          onChange={(value) => {
            updateDraft({
              progress: value!,
            });
          }}
        />
      </Row>
    </div>
  );
};
