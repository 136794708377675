import { DefaultIdentifiers } from "@atoms/atom-types";
import { Atoms, Identifiers } from "@project/shared";
import { useSelector } from "react-redux";
import type { State } from "../state";
import { useEapContext } from "./useEapContext";
import { useAtom } from "@project/api";

export const useMyProjectRoles = () => {
  const myUserId = useSelector((state: State) => state.app.myUserData?.id);
  const context = useEapContext();
  const projectId = context[Identifiers.ProjectId];
  const orgId = context[Identifiers.OrganizationId];
  const roles =
    useAtom(
      Atoms.UserRoles,
      (roles) => roles.orgs[orgId]?.projects[projectId],
      {
        [DefaultIdentifiers.UserId]: myUserId,
      },
    ) ?? [];
  return roles;
};
