import { EapOrganizationRole } from "@project/shared";
import { Checkbox, Radio } from "antd";
import { useState } from "react";

export interface OrganizationRoleEditorProps {
  roles: EapOrganizationRole[];
  onChange?: (roles: EapOrganizationRole[]) => void;
}

export const OrganizationRoleEditor = ({
  roles,
  onChange,
}: OrganizationRoleEditorProps) => {
  const [selectedRoles, setSelectedRoles] =
    useState<EapOrganizationRole[]>(roles);

  const handleRoleChange = (role: EapOrganizationRole) => {
    setSelectedRoles((prevRoles) => {
      const newRoles = [...prevRoles];
      if (newRoles.includes(role)) {
        newRoles.splice(newRoles.indexOf(role), 1);
      } else {
        newRoles.push(role);
      }
      onChange?.(newRoles);
      return newRoles;
    });
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
      {Object.entries(EapOrganizationRole).map(([key, value]) => (
        <Checkbox
          key={value}
          checked={selectedRoles.includes(value)}
          onChange={() => handleRoleChange(value)}
          disabled={onChange === undefined}
        >
          {key}
        </Checkbox>
      ))}
    </div>
  );
};
