import { EapPermission, getDraftOperation } from "@project/shared";
import { AnimatePresence, motion } from "framer-motion";
import {
  type KeyboardEvent,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  getNodeCodeUIElement,
  getNodeLabelUIElement,
  getNodeUIElement,
} from "../../UIElements";
import type { State } from "../../state";
import { WbsApi } from "../../state/wbs";
import { ElementType, type WBSTreeElement } from "../../types/WBSTreeTypes";
import { useThunkDispatch } from "../../useThunkDispatch";
import {
  DraftColors,
  DraftFillHoverColors,
  DraftFillPatterns,
} from "./DraftColors";
import type { Renderer } from "./Renderer";
import { RendererContext } from "./RendererContext";
import { WBSContextMenu } from "./WBSContextMenu";
import { WBSHoverMenu } from "./WBSHoverMenu";
import { useHasPermission } from "../../hooks/useHasPermission";
import { VERSION_DRAFT } from "../../state/app";

export interface OverviewNodeProps {
  element: WBSTreeElement;
  onTab?: (e: KeyboardEvent<HTMLInputElement>) => void;
}
function shadeHexColor(color: string, percent: number): string {
  const f = Number.parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}

type DefaultNodeUtils = {
  activateEditor: () => void;
};

export const DefaultNode = ({ element, onTab }: OverviewNodeProps) => {
  const { Text } = useContext<Renderer>(RendererContext);
  const displaySettings = useSelector(
    (state: State) => state.wbs.displaySettings,
  );
  const collapsedIndex = useSelector((state: State) =>
    state.wbs.collapsedCodes.indexOf(element.code),
  );
  const isDraftVersion = useSelector(
    (state: State) => state.app.activeVersion === VERSION_DRAFT,
  );

  const isCategory = element.type === ElementType.Category;
  const level = element.code.split(".").length - 1;
  const expertMode = useSelector(
    (state: State) => state.wbs.displaySettings.expertMode,
  );
  const hasCarret = useSelector(
    (state: State) => state.wbs.edit?.code === element.code,
  );

  const bgColorsPerLevel = [
    "0,0%,80%",
    "211,58%,86%",
    "83,56%,84%",
    "32,68%,85%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
    "0,0%,88%",
  ];

  function darkenHSLColor(color: string, percent: number) {
    const [h, s, l] = color.split(",").map((x) => Number.parseInt(x));
    return `${h},${s}%,${l! - percent}%`;
  }

  const [hovered, setHovered] = useState(false);

  const [codeRef, setCodeRef] = useState<SVGTSpanElement | null>(null);
  const dispatch = useThunkDispatch();
  const hasPermission = useHasPermission();

  const [codeWidth, setCodeWidth] = useState(0);
  useLayoutEffect(() => {
    if (codeRef) {
      setCodeWidth(codeRef.getComputedTextLength());
    }
  }, [codeRef, element.code]);

  const separatorPadding = 0.75 * displaySettings.padding;

  const nodeBox = (
    <g>
      <motion.rect
        key={element.code}
        width={element.labelSize?.width}
        height={element.labelSize?.height ?? 100}
        // initial={{ opacity: 0 }}
        animate={{
          fill: hovered
            ? element.draft !== undefined
              ? DraftFillHoverColors[element.draft]
              : `hsl(${darkenHSLColor(bgColorsPerLevel[level]!, 10)})`
            : element.draft !== undefined
              ? DraftFillPatterns[element.draft]
              : `hsl(${bgColorsPerLevel[level]})` ?? displaySettings.boxColor,
          stroke: hasCarret
            ? "#0097cc"
            : element.draft !== undefined
              ? DraftColors[element.draft]
              : `hsl(${darkenHSLColor(bgColorsPerLevel[level]!, 30)})`,
          opacity: 1,
        }}
        strokeWidth="2"
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        rx="5"
      />
      <line
        x1={(codeWidth ?? 0) + 2 * separatorPadding}
        y1="0"
        x2={(codeWidth ?? 0) + 2 * separatorPadding}
        y2={element.labelSize?.height ?? 100}
        stroke={`hsl(${darkenHSLColor(bgColorsPerLevel[level]!, 20)})`}
        strokeDasharray="1 2"
      />
      <Text
        x={displaySettings.padding}
        y={displaySettings.padding + displaySettings.fontSize}
        width={element.labelSize?.width}
        fill={displaySettings.textColor}
        fontFamily="PT Sans"
        fontSize={displaySettings.fontSize}
        textDecoration={
          element.draft !== undefined &&
          getDraftOperation(element.draft) === "Delete"
            ? "line-through"
            : undefined
        }
        textRendering="geometricPrecision"
      >
        {displaySettings.showCode && !displaySettings.codeVertical ? (
          <tspan
            className={getNodeCodeUIElement(element.code)}
            fontWeight={isCategory ? 700 : 400}
            ref={setCodeRef}
          >
            {element.code}
          </tspan>
        ) : null}
        <tspan
          className={getNodeLabelUIElement(element.code)}
          dx={displaySettings.padding}
          fontStyle={!isCategory ? "italic" : undefined}
        >
          {element.label}
        </tspan>
      </Text>
    </g>
  );

  return (
    <motion.g
      className={getNodeUIElement(element.code)}
      initial={{
        translateX: element.labelPosition!.x,
        translateY: element.labelPosition!.y,
        // opacity: 0,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onDoubleClick={(e) => {
        if (
          isDraftVersion &&
          hasPermission(EapPermission.CreateDraft) &&
          (element.draft === undefined ||
            !(getDraftOperation(element.draft) === "Delete"))
        ) {
          const domRects = codeRef?.getClientRects();
          const isWithinCode = domRects && e.clientX < domRects[0]!.right;
          if (
            isWithinCode &&
            (element.draft === undefined ||
              !(getDraftOperation(element.draft) === "Add"))
          ) {
            return;
          }
          dispatch(
            WbsApi.setEdit(element.code, isWithinCode ? "code" : "label"),
          );
        }
      }}
      onClick={(e) => {
        dispatch(WbsApi.moveCarret(element.code));
      }}
      animate={{
        translateX: element.labelPosition!.x,
        translateY: element.labelPosition!.y,
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{ ease: "linear", duration: 0.2 }}
    >
      <defs>
        <pattern
          id="deletePattern"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
          patternTransform="rotate(-45 2 2)"
        >
          <rect width={4} height={4} fill="white" />
          <path d="M -1,2 l 6,0" style={{ stroke: "salmon", strokeWidth: 1 }} />
        </pattern>
        <pattern
          id="addPattern"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
          patternTransform="rotate(-45 2 2)"
        >
          <rect width={4} height={4} fill="white" />
          <path
            d="M -1,2 l 6,0"
            style={{ stroke: "lightGreen", strokeWidth: 1 }}
          />
        </pattern>
        <pattern
          id="updatePattern"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
          patternTransform="rotate(-45 2 2)"
        >
          <rect width={4} height={4} fill="white" />
          <path
            d="M -1,2 l 6,0"
            style={{ stroke: "lightblue", strokeWidth: 1 }}
          />
        </pattern>
        <pattern
          id="restorePattern"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
          patternTransform="rotate(-45 2 2)"
        >
          <rect width={4} height={4} fill="white" />
          <path
            d="M -1,2 l 6,0"
            style={{ stroke: "paleTurquoise", strokeWidth: 1 }}
          />
        </pattern>
      </defs>
      <AnimatePresence>
        {collapsedIndex !== -1 ? (
          <>
            <motion.rect
              // initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              x={2}
              y={2}
              width={element.labelSize?.width}
              height={element.labelSize?.height ?? 100}
              stroke={
                element.draft !== undefined
                  ? DraftColors[element.draft]
                  : shadeHexColor(displaySettings.boxColor, -0.2)
              }
              fill="transparent"
              rx="5"
            />
            <motion.rect
              // initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              x={4}
              y={4}
              width={element.labelSize?.width}
              height={element.labelSize?.height ?? 100}
              stroke={
                element.draft !== undefined
                  ? DraftColors[element.draft]
                  : shadeHexColor(displaySettings.boxColor, -0.2)
              }
              fill="transparent"
              rx="5"
            />
          </>
        ) : null}
      </AnimatePresence>
      {expertMode ? (
        <WBSHoverMenu element={element}>{nodeBox}</WBSHoverMenu>
      ) : (
        <WBSContextMenu element={element}>{nodeBox}</WBSContextMenu>
      )}
    </motion.g>
  );
};
