import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";
import { ReduxModal } from "./ReduxModal";

export const ImprintModalId = "imprint";
export const ImprintModal = () => {
  const dispatch = useThunkDispatch();
  return (
    <ReduxModal
      id={ImprintModalId}
      maskClosable
      okText="Schließen"
      cancelButtonProps={{ hidden: true }}
      title="Impressum"
      dispatcher={() => dispatch(ModalsApi.hide(ImprintModalId))}
    >
      <p>
        <b>program51 GmbH</b>
        <br />
        Haus Cumberland
        <br />
        Kurfürstendamm 194
        <br />
        10707 Berlin
        <br />
        +49 30 700 159 542
        <br />
        {import.meta.env.SUPPORT_EMAIL}
      </p>
      <p>
        <b>Geschäftsführung:</b>
        <br />
        Harald Lohrenscheit, Dr. Christoph Thiel
      </p>
      <p>
        <b>Handelsregister:</b>
        <br />
        HRB 204387
        <br />
        Amtsgericht Charlottenburg:
      </p>
      <p>
        <b>Umsatzsteuer ID:</b>
        <br />
        DE322705102
      </p>
      <p>
        <b>Programmierung:</b>
        <br />
        <a
          href="https://feinarbyte.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://feinarbyte.de
        </a>
      </p>
      <p>
        <b>Hosting:</b>
        <br />
        <a
          href="https://www.walkingtoweb.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.walkingtoweb.com/
        </a>
      </p>
    </ReduxModal>
  );
};
