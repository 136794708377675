import { makeForm } from "@atoms/atom-components";
import { UserPublicID } from "@atoms/atom-types";
import { Atoms, EapPermission, IWorkpackage, workpackageLabels, workpackagePermissions } from "@project/shared";
import { mapValues } from "lodash";
import { useGetUserName } from "../../components/UserName";
import { useHasPermission } from "../../hooks/useHasPermission";
import { useMyUserId } from "../../hooks/useMyUserId";


export const WorkpackageForm = makeForm<Atoms, EapPermission, IWorkpackage>({
  useMyUserId: useMyUserId as () => UserPublicID,
  useGetUserName: useGetUserName,
  useHasPermission: useHasPermission,
  atom: Atoms.WorkpackageLatest,
  permissions: workpackagePermissions,
  labels: mapValues(workpackageLabels, (label: string) => ({
    label: label,
  })),
});
