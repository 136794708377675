import type { AtomData, TypedOperation } from "@atoms/atom-types";
import type { IActivity, IToDo } from "@project/shared";
import { Tooltip, Typography } from "antd";
import { isDate } from "lodash";
import { DiffMethod, StringDiff } from "react-string-diff";

const { Text } = Typography;

export function renderTodoChanges(
  changes: TypedOperation<AtomData>[],
  old: AtomData,
) {
  const labels: Record<keyof IToDo, string> = {
    label: "Titel",
    description: "Beschreibung",
    sortIndex: "Sortierung",
    status: "Status",
    plannedFinish: "Geplantes Ende",
    done: "Erledigt",
    deleted: "Gelöscht",
    uuid: "",
  };
  return (
    <SubItemChanges
      changes={changes}
      old={old}
      subItemKey="todos"
      labels={labels}
      key={JSON.stringify(changes)}
    >
      ToDo
    </SubItemChanges>
  );
}

export function renderActivityChanges(
  changes: TypedOperation<AtomData>[],
  old: AtomData,
) {
  const labels: Record<keyof IActivity, string> = {
    label: "Titel",
    description: "Beschreibung",
    sortIndex: "Sortierung",
    status: "Status",
    plannedFinish: "Geplantes Ende",
    expectedFinish: "Erwartetes Ende",
    currentFinish: "Aktuelles Ende",
    deleted: "Gelöscht",
    uuid: "",
  };
  return (
    <SubItemChanges
      changes={changes}
      old={old}
      subItemKey="activities"
      labels={labels}
    >
      Aktivität
    </SubItemChanges>
  );
}
interface SubItemChangesProps {
  changes: TypedOperation<AtomData>[];
  old: AtomData;
  subItemKey: string;
  labels: Record<string, string>;
  children: React.ReactNode;
}

const SubItemChanges = ({
  changes,
  old,
  subItemKey,
  labels,
  children,
}: SubItemChangesProps) => {
  return (
    <>
      <Tooltip
        key={JSON.stringify(changes)}
        title={changes.map((change) => {
          if (change.op === "remove") {
            return (
              <div key={change.path}>
                <strong>{old[change.path.split("/")[2]!]}</strong> entfernt
              </div>
            );
          }
          const [uuid, field] = change.path.split("/").slice(-2);
          if (!uuid || !field || field === "sortIndex") {
            return;
          }
          if (change.op === "add") {
            return (
              <div key={change.path}>
                <strong>{change.value.description}</strong> hinzugefügt
              </div>
            );
          }
          const item = old[subItemKey][uuid];
          if (!item) {
            return;
          }
          const oldValue = item[field];
          if (change.op === "replace") {
            const newValue = change.value;
            return (
              <div key={change.path}>
                <Text
                  ellipsis
                  style={{
                    width: item.description.length > 20 ? 100 : undefined,
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {item.description}
                </Text>
                : {labels[field]}:{" "}
                {isDate(newValue) ? (
                  `${oldValue ? `${oldValue.toDateString()} ->` : null} ${newValue.toDateString()}`
                ) : oldValue ? (
                  <StringDiff
                    oldValue={oldValue?.toString() ?? ""}
                    newValue={newValue?.toString() ?? ""}
                    component="strong"
                    method={DiffMethod.WordsWithSpace}
                    styles={{
                      added: { color: "green" },
                      removed: { color: "red" },
                      default: { color: "white" },
                    }}
                  />
                ) : (
                  <Text
                    ellipsis
                    style={{
                      width: newValue > 20 ? 100 : undefined,
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {newValue}
                  </Text>
                )}
              </div>
            );
          }
          return;
        })}
        overlayStyle={{ maxWidth: "500px" }}
      >
        <strong>{children}</strong>
      </Tooltip>
    </>
  );
};
