import { faFileAlt, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Atoms, EapPermission } from "@project/shared";
import ButtonGroup from "antd/es/button/button-group";
import { useApiClient } from "@project/api";
import { useFilteredPackages } from "../hooks/useFilteredPackages";
import { useHasPermission } from "../hooks/useHasPermission";
import { useProgressModal } from "../hooks/useProgressModal";
import { downloadXlsx } from "../thunks/downloadXlsx";
import { renderReports } from "../thunks/renderReports";
import { AsyncButton } from "./reusable/AsyncButton";
import { useAtom } from "@project/api";

export const WorkPackageTableReportBtn = () => {
  const api = useApiClient();

  const projectTree =
    useAtom(Atoms.ProjectTree, ({ entries }) => entries) ?? [];
  const hasPermission = useHasPermission();
  const filteredPackages = useFilteredPackages();
  const projectUsers =
    useAtom(Atoms.ProjectUsers, ({ entries }) => entries) ?? [];

  const [
    modalRender,
    setProgress,
    setStep,
    setProgressModalOpen,
    abortController,
  ] = useProgressModal();

  return (
    <>
      {modalRender}
      <ButtonGroup>
        <AsyncButton
          type="default"
          disabled={(filteredPackages?.length ?? 0) === 0}
          onClick={async () => {
            setProgressModalOpen(true);
            try {
              await renderReports(
                api,
                filteredPackages,
                projectUsers,
                (progress, step) => {
                  setProgress(progress);
                  setStep(step);
                },
                abortController.current.signal,
              );
            } finally {
              setProgressModalOpen(false);
            }
          }}
          icon={
            <FontAwesomeIcon
              icon={faFileAlt}
              color=""
              style={{ marginRight: "5px" }}
            />
          }
        >
          Bericht erstellen
        </AsyncButton>
        {hasPermission(EapPermission.DownloadXlsx) ? (
          <AsyncButton
            // ghost={checked.length > 0}
            type="default"
            onClick={async () => {
              setProgressModalOpen(true);
              try {
                await downloadXlsx(
                  api,
                  projectTree,
                  projectUsers,
                  setProgress,
                  abortController.current.signal,
                );
              } finally {
                setProgressModalOpen(false);
              }
            }}
            disabled={filteredPackages?.length === 0}
            icon={
              <FontAwesomeIcon
                icon={faFileExcel}
                color=""
                style={{ marginRight: "5px" }}
              />
            }
          >
            XLS Download
          </AsyncButton>
        ) : null}
      </ButtonGroup>
    </>
  );
};
