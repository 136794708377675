import { AsyncButton, makeAtomTable } from "@atoms/atom-components";
import type { UserPublicID } from "@atoms/atom-types";
import { Atoms, type EapProjectRole } from "@project/shared";
import { Button, Input, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useApiClient } from "@project/api";
import { ProjectRoleEditor } from "./ProjectRoleEditor";
import { UserName } from "./UserName";
import { useSelector } from "react-redux";
import type { State } from "../state";
import { useSetTitle } from "../hooks/useSetTitle";
import type { atomDataIndex } from "@project/api";

const EapTable = makeAtomTable<atomDataIndex>();

export const ProjectUserManager = () => {
  const api = useApiClient();
  useSetTitle("Project User Manager");
  const myUserId = useSelector(
    (state: State) => state.app.myUserData?.id,
  ) as UserPublicID;
  const [newUserEmail, setNewUserEmail] = useState("");

  const handleAddUser = async () => {
    if (!newUserEmail) {
      message.error("Please enter a valid email address");
      return;
    }
    try {
      await api.Project.inviteTo(newUserEmail as UserPublicID, {
        roles: [],
        inviterId: myUserId,
        invitationDate: new Date(),
      });
      message.success("User invited successfully");
      setNewUserEmail("");
    } catch (error) {
      message.error("Failed to invite user");
    }
  };

  return (
    <Content>
      <h2>Project User Manager</h2>

      <div style={{ marginBottom: "20px" }}>
        <Input
          placeholder="Enter user email"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          style={{ width: "300px", marginRight: "10px" }}
        />
        <AsyncButton onClick={handleAddUser}>Invite User</AsyncButton>
      </div>

      <h3>Invited Users</h3>
      <EapTable
        atomType={Atoms.ProjectInvites}
        actions={(key) => (
          <AsyncButton
            onClick={async () => {
              return await api.Project.removeFromInvites(key);
            }}
            danger
          >
            Remove
          </AsyncButton>
        )}
        columns={[
          {
            title: "Email",
            dataIndex: "key",
            render: (email) => <span>{email}</span>,
          },
          {
            title: "Inviter",
            dataIndex: "inviterId",
            render: (inviterId) => <UserName publicId={inviterId} />,
          },
          {
            title: "Invitation Date",
            dataIndex: "invitationDate",
            render: (invitationDate) => (
              <span>{invitationDate.toISOString()}</span>
            ),
          },
          {
            title: "Roles",
            dataIndex: "roles",
            render: (roles, record, key) => (
              <ProjectRoleEditor
                roles={roles as EapProjectRole[]}
                onChange={(newRoles) =>
                  api.Project.updateInvitesEntry(record.key, {
                    roles: newRoles,
                    inviterId: record.inviterId,
                    invitationDate: record.invitationDate,
                  })
                }
              />
            ),
          },
        ]}
      />

      <h3>Project Members</h3>
      <EapTable
        atomType={Atoms.ProjectMembers}
        actions={(key, record) => (
          <AsyncButton
            onClick={async () => {
              return await api.Project.updateMembersEntry(key, {
                ...record,
                disabled: !record.disabled,
              });
            }}
          >
            {record.disabled ? "Enable" : "Disable"}
          </AsyncButton>
        )}
        columns={[
          {
            title: "User",
            dataIndex: "key",
            render: (userId) => <UserName publicId={userId} />,
          },
          {
            title: "Roles",
            dataIndex: "roles",
            render: (roles, record) => (
              <ProjectRoleEditor
                roles={roles as EapProjectRole[]}
                onChange={(newRoles) =>
                  api.Project.updateMembersEntry(record.key, {
                    ...record,
                    roles: newRoles,
                  })
                }
              />
            ),
          },
          {
            title: "Status",
            dataIndex: "disabled",
            render: (isDisabled) => (
              <span>{(isDisabled as boolean) ? "Disabled" : "Active"}</span>
            ),
          },
        ]}
      />
    </Content>
  );
};
