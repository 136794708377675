import type { IWorkpackage } from "../interfaces/IWorkpackage";

export const workpackageLabels: { [key in keyof IWorkpackage]: string } = {
  activities: "Aktivitäten",
  commissionerUserId: "Auftraggeber/in",
  decisionRequired: "Entscheidungsbedarf",
  done: "abgeschlossen",
  decisionRequiredReason: "Erläuterung zum Entscheidungsbedarf",
  involvedUserIds: "Beteiligte",
  dependentWPs: "abhängige AP",
  description: "Beschreibung",
  frozen: "frozen",
  deleted: "gelöscht",
  decision: "Entscheidung",
  expectedFinish: "erwartetes Enddatum",
  goals: "Lieferergebnisse",
  label: "Titel",
  objective: "Zielstellung",
  plannedFinish: "geplantes Enddatum",
  plannedStart: "geplantes Startdatum",
  progress: "Fortschritt",
  progressReason: "Grund für Fortschritt",
  responsibleUserId: "Verantwortliche/r",
  signal: "Signal",
  status: "Status",
};
