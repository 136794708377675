import type React from "react";
import { Card, Button, Typography, Space } from "antd";
import { Atoms, Identifiers } from "@project/shared";
import { Link } from "react-router-dom";
import { TeamOutlined, ProjectOutlined } from "@ant-design/icons";
import { useAtom } from "@project/api";
import { LicenseIndicator } from "./LicenseIndicator";

const { Text, Paragraph } = Typography;

interface OrganizationPreviewProps {
  organizationId: string;
}

export const OrganizationPreview: React.FC<OrganizationPreviewProps> = ({
  organizationId,
}) => {
  const organization = useAtom(Atoms.OrganizationInfo, (info) => info, {
    [Identifiers.OrganizationId]: organizationId,
  });
  const projects =
    useAtom(Atoms.ProjectList, (info) => info.entries, {
      [Identifiers.OrganizationId]: organizationId,
    }) ?? {};

  const members = useAtom(
    Atoms.OrganizationMembers,
    (members) => Object.keys(members.entries).length,
    {
      [Identifiers.OrganizationId]: organizationId,
    },
  );

  if (!organization) {
    return null;
  }

  return (
    <Card
      title={organization.name}
      extra={
        <Link to={`/admin/${organizationId}`}>
          <Button type="primary">Verwalten</Button>
        </Link>
      }
      style={{ marginBottom: 16 }}
      hoverable
    >
      <LicenseIndicator />
      <Paragraph>{organization.description}</Paragraph>
      <Space direction="vertical">
        <Text>
          <TeamOutlined /> Mitglieder: {members}
        </Text>
        <Text>
          <ProjectOutlined /> Projekte: {Object.keys(projects).length}
        </Text>
      </Space>
    </Card>
  );
};
