import { makeForm } from "@atoms/atom-components";
import { UserPublicID } from "@atoms/atom-types";
import { Atoms, EapPermission, IOrganizationInfo, IWorkpackage, workpackageLabels, workpackagePermissions } from "@project/shared";
import { mapValues } from "lodash";
import { useGetUserName } from "../../components/UserName";
import { useHasPermission } from "../../hooks/useHasPermission";
import { useMyUserId } from "../../hooks/useMyUserId";


export const OrganizationInfoForm = makeForm<Atoms, EapPermission, IOrganizationInfo>({
  useMyUserId: useMyUserId as () => UserPublicID,
  useGetUserName: useGetUserName,
  useHasPermission: useHasPermission,
  atom: Atoms.OrganizationInfo,
  // permissions: organizationInfoPermissions,
  labels: {
    name: {
      label: "Name",
    },
    description: {
      label: "Description",
    },
  },
});
