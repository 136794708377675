import { AuthProvider, useAuth } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

import { useState, useEffect, type ReactNode } from "react";
import { AtomContextProvider } from "./AtomContext";
import type {
  ContextValues,
  OidcSettings,
  UserPublicID,
} from "@atoms/atom-types";
import {
  type ApolloClient,
  ApolloProvider,
  useApolloClient,
} from "@apollo/client";
import { initApolloClient } from "./initApolloClient";
import { makeGetAuthToken } from "./makeGetAuthToken";
import { __useDefaultUsername } from "./hooks/useDefaultUsername";
import { getBackendUrl } from "./getBackendUrl";

interface AtomsProviderProps {
  children: ReactNode;
  getContextHook: () => ContextValues;
  splashScreen: ReactNode;
  /**
   * A hook that returns the username of the current user.
   * By default, the username is fetched from personaluserdataatom.
   * This is used to set the username in the Apollo cache.
   */
  getUsernameHook?: (userId: UserPublicID) => string;
}

export function AtomsProvider({
  children,
  getContextHook,
  splashScreen,
  getUsernameHook = __useDefaultUsername,
}: AtomsProviderProps) {
  const [oidcSettings, setOidcSettings] = useState<OidcSettings | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [apolloClient, setApolloClient] = useState<ApolloClient<any> | null>(
    null,
  );
  const [getAuthToken, setGetAuthToken] = useState<
    (() => Promise<string | null>) | null
  >(null);
  const { backendBaseUrl } = getBackendUrl();

  useEffect(() => {
    fetch(`${backendBaseUrl}/auth/oidc`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch OIDC settings");
        }
        return response.json();
      })
      .then((data) => {
        const getAuthToken =
          data.type === "dummy"
            ? () => Promise.resolve("dummy-user-token")
            : makeGetAuthToken(data.authority, data.client_id);
        const apolloClient = initApolloClient(getAuthToken);
        setApolloClient(apolloClient);
        setGetAuthToken(getAuthToken);
        if (data.type !== "dummy") {
          setOidcSettings(data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, []);

  if (isLoading || !apolloClient || !getAuthToken) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <AuthProvider
      {...oidcSettings}
      userStore={new WebStorageStateStore({ store: window.localStorage })}
      onSigninCallback={() => {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname,
        );
      }}
      automaticSilentRenew={true}
    >
      <ApolloProvider client={apolloClient}>
        <App
          getContextHook={getContextHook}
          splashScreen={splashScreen}
          getAuthToken={getAuthToken}
          getUsernameHook={getUsernameHook}
        >
          {children}
        </App>
      </ApolloProvider>
    </AuthProvider>
  );
}

export function App({
  children,
  getContextHook,
  splashScreen,
  getAuthToken,
  getUsernameHook,
}: {
  children: ReactNode;
  getContextHook: () => ContextValues;
  splashScreen: ReactNode;
  getAuthToken: () => Promise<string | null>;
  getUsernameHook: (userId: UserPublicID) => string;
}) {
  const apolloClient = useApolloClient();

  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading && !isAuthenticated) {
    return splashScreen;
  }

  return (
    <AtomContextProvider
      apolloClient={apolloClient}
      getContextHook={getContextHook}
      getAuthToken={getAuthToken}
      getUsernameHook={getUsernameHook}
    >
      {children}
    </AtomContextProvider>
  );
}
