import {
  DefaultAtoms,
  DefaultIdentifiers,
  type UserPublicID,
} from "@atoms/atom-types";
import { Atoms } from "@project/shared";
import { find } from "lodash";
import { memo } from "react";
import { useAtom } from "@project/api";

interface UserNameProps {
  publicId: UserPublicID;
  fallback?: string;
}

export const UserName = memo(({ publicId, fallback }: UserNameProps) => {
  const displayName = useAtom(
    DefaultAtoms.PersonalUserData,
    ({ displayName }) => displayName,
    { [DefaultIdentifiers.UserId]: publicId },
  );
  return <>{displayName || fallback || "unbekannt"}</>;
});

export const useGetUserName = () => {
  const users = useAtom(Atoms.ProjectUsers, ({ entries }) => entries);

  return (publicId: UserPublicID) => {
    const user = find(users, ({ userId }) => userId === publicId);
    return user ? user.name : "unbekannt";
  };
};
