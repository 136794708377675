import type { FrontendProvision } from "@atoms/atom-client";
import { WebStorageStateStore } from "oidc-client-ts";
import { MainComponent } from "./components/MainComponent";
import { SplashScreenNode } from "./components/SplashScreenNode";
import { useEapContext } from "./hooks/useEapContext";
import { KEYCLOAK_AUTHORITY } from "./util/keycloak";

export const ProjectProvision: FrontendProvision = {
  contextSettings: {
    children: MainComponent,
    getContextHook: useEapContext,
    getAuthToken: async () => "token",
  },
  splashScreen: SplashScreenNode,
};
