import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { useLayoutEffect, useState } from "react";
import {
  ApolloEvent,
  type ApolloOptions,
  apolloConnection,
} from "./ApolloConnection";
import { getApolloOptions } from "./getApolloOptions";

export type ApolloListeners = {
  [ApolloEvent.GRAPHQL_ERROR]?: (message: string) => Promise<void>;
  [ApolloEvent.NETWORK_ERROR]?: () => void;
  [ApolloEvent.WS_GRAPHQL_ERROR]?: (message: string) => Promise<void>;
  [ApolloEvent.WS_INTERNAL_ERROR]?: (err: Error) => void;
  [ApolloEvent.WS_CLOSED]?: (evt: CloseEvent) => void;
  [ApolloEvent.WS_CONNECTED]?: () => void;
  [ApolloEvent.WS_CONNECTING]?: () => void;
  [ApolloEvent.WS_GENERAL_ERROR]?: (err: ErrorEvent | Error) => void;
  [ApolloEvent.WS_NETWORK_ERROR]?: (evt: CloseEvent) => void;
};

interface useApolloConnectionProps {
  listeners?: ApolloListeners;
  options?: React.MutableRefObject<ApolloOptions>;
  getAuthToken: () => Promise<string | null>;
  graphQlUrl?: string;
  subscriptionUrl?: string;
  credentials?: string;
}

export const useApolloConnection = ({
  options,
  listeners = {},
  getAuthToken,
  credentials = "same-origin",
  graphQlUrl = `${location.origin}/backend/graphql`,
  subscriptionUrl = `${graphQlUrl.replace("http", "ws")}`,
}: useApolloConnectionProps):
  | ApolloClient<NormalizedCacheObject>
  | undefined => {
  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject>>();

  useLayoutEffect(() => {
    const client = apolloConnection.init(
      options?.current ??
        getApolloOptions(graphQlUrl, subscriptionUrl, credentials),
      getAuthToken,
    );

    Object.entries(listeners).forEach(([event, listener]) => {
      apolloConnection.addListener(event as ApolloEvent, listener);
    });

    setApolloClient(client);

    return () => {
      apolloConnection.removeAllListeners();
      apolloConnection.dispose();
    };
  }, [options]);

  useLayoutEffect(() => {
    Object.entries(listeners).forEach(([event, listener]) => {
      apolloConnection.addListener(event as ApolloEvent, listener);
    });
    return () => {
      apolloConnection.removeAllListeners();
    };
  }, [listeners]);

  return apolloClient;
};
