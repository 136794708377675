import { MenuOutlined } from "@ant-design/icons";
import { MagicFormContext, TextAreaOrDiv } from "@atoms/atom-components";
import type { UserPublicID } from "@atoms/atom-types";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationTriangle,
  faLock,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EapPermission,
  type IActivity,
  type IWorkpackage,
} from "@project/shared";
import { Empty } from "antd";
import Button from "antd/es/button";
import ButtonGroup from "antd/es/button/button-group";
import Col from "antd/es/col";
import { ConfigContext } from "antd/es/config-provider";
import DatePicker from "antd/es/date-picker";
import Row from "antd/es/grid/row";
import Popconfirm from "antd/es/popconfirm";
import SkeletonButton from "antd/es/skeleton/Button";
import SkeletonInput from "antd/es/skeleton/Input";
import type { ColumnsType } from "antd/es/table/interface";
import Tooltip from "antd/es/tooltip";
import dayjs from "dayjs";
import { keyBy, orderBy } from "lodash";
import moment from "moment";
import {
  Children,
  type MouseEvent,
  type ReactElement,
  cloneElement,
  memo,
  useContext,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { UIElement } from "../UIElements";
import { useApiClient } from "@project/api";
import { useHasPermission } from "../hooks/useHasPermission";
import type { State } from "../state";
import { compareDates } from "../util/compareDates";
import { getLabelProperties } from "../util/getLabelProperties";
import { ReduxTable } from "./ReduxTable";
import {
  CreateActivityModal,
  type CreateActivityModalRef,
} from "./modals/CreateActivityModal";
import {
  UpdateActivityModal,
  type UpdateActivityModalRef,
} from "./modals/UpdateActivityModal";
import { AsyncButton } from "./reusable/AsyncButton";

interface ActivityTableProps {
  readonly?: boolean;
  className?: string;
}

interface DraggableBodyRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

export const ActivityTable = memo(({ className }: ActivityTableProps) => {
  const api = useApiClient();
  const { currentValue, updateDraft, loading } = useContext(MagicFormContext)!;
  const workpackage = currentValue as IWorkpackage;
  const hasPermission = useHasPermission();

  const updateActivityModal = useRef<UpdateActivityModalRef>(null);
  const createActivityModal = useRef<CreateActivityModalRef>(null);

  const activities = orderBy(
    Object.values(workpackage?.activities ?? {})
      .reverse()
      .map((a) => {
        const activity = { ...a };
        if (activity.sortIndex === null) {
          activity.sortIndex = -1;
        }

        return activity;
      }),
    ["sortIndex"],
    ["asc"],
  ).map((a, idx) => {
    const activity = { ...a };
    activity.sortIndex = idx;
    return activity;
  });

  const myUserId = useSelector((state: State) => state.app.myUserData?.id);
  const isResponsible = currentValue.responsibleUserId === myUserId;
  const antdConfig = useContext(ConfigContext);

  const hasActivitySortPermission =
    hasPermission(EapPermission.UpdateActivitySort) ||
    (isResponsible &&
      hasPermission(EapPermission.UpdateActivitySortIfResponsible));

  const columns: ColumnsType<IActivity> = [
    {
      key: "sortIndex",
      dataIndex: "sortIndex",
      sortOrder: "ascend",
      width: 20,
      className: "drag-visible",
    },
    {
      key: "beschreibung",
      title: "Beschreibung",
      dataIndex: "description",
      width: 300,
      sortOrder: "ascend",
      sorter: loading
        ? undefined
        : (a, b) => {
            return a.description.localeCompare(b.description);
          },

      render: (value, _record, idx) => {
        if (loading) {
          return <SkeletonInput active size="small" block />;
        }

        return (
          <TextAreaOrDiv key={`ta_${idx}`} value={value} autoSize disabled />
        );
      },
    },
    {
      key: "enddatum",
      sorter: loading
        ? undefined
        : (a, b, sortOrder) => {
            return compareDates(a.plannedFinish, b.plannedFinish, sortOrder);
          },
      sortOrder: "ascend",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
        >
          <span>Enddatum</span>

          {hasPermission(EapPermission.FreezeWorkpackages) ? (
            <AsyncButton
              style={{ marginLeft: "5px" }}
              icon={
                workpackage?.frozen ? (
                  <Tooltip title="Entsperren">
                    <FontAwesomeIcon icon={faLock} color="red" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Sperren">
                    <FontAwesomeIcon icon={faUnlock} />
                  </Tooltip>
                )
              }
              onClick={async (e: MouseEvent) => {
                if (loading) {
                  return;
                }

                e.stopPropagation();

                if (!workpackage?.frozen) {
                  await api.Workpackage.freeze();
                } else {
                  await api.Workpackage.unfreeze();
                }
              }}
              disabled={
                !hasPermission(EapPermission.UpdateWorkpackages) &&
                workpackage?.commissionerUserId !== myUserId
              }
            />
          ) : null}
        </div>
      ),
      dataIndex: "plannedFinish",
      width: 150,
      render: (value, record) => {
        if (loading) {
          return <SkeletonInput active size="small" />;
        }

        return (
          <DatePicker
            placeholder={""}
            format="DD.MM.YYYY"
            value={value ? dayjs(value) : undefined}
            disabled
          />
        );
      },
    },
    {
      key: "erwartetes_ende",
      title: "Erwartetes Ende",
      dataIndex: "expectedFinish",
      sorter: loading
        ? undefined
        : (a, b) => {
            return compareDates(a.expectedFinish, b.expectedFinish);
          },
      width: 150,
      render: (value, record) => {
        if (loading) {
          return <SkeletonInput active size="small" />;
        }

        return (
          <DatePicker
            placeholder={""}
            format="DD.MM.YYYY"
            value={value ? dayjs(value) : undefined}
            disabled
          />
        );
      },
    },
    {
      key: "ist_ende",
      title: "Ist Ende",
      dataIndex: "currentFinish",
      sorter: loading
        ? undefined
        : (a, b) => {
            return compareDates(a.currentFinish, b.currentFinish);
          },
      width: 150,
      render: (value, record) => {
        if (loading) {
          return <SkeletonInput active size="small" />;
        }

        return (
          <DatePicker
            placeholder={""}
            format="DD.MM.YYYY"
            value={value ? dayjs(value) : undefined}
            disabled
          />
        );
      },
    },
    {
      key: "statusbericht",
      title: "Bericht zu den Aktivitäten",
      dataIndex: "status",
      className: "min-w-[200px] max-w-[35em]",
      render: (value, record) => {
        if (loading) {
          return <SkeletonInput active size="small" block />;
        }

        return <TextAreaOrDiv value={value} autoSize disabled />;
      },
    },
  ];

  if (
    (hasPermission(EapPermission.UpdateActivities) && isResponsible) ||
    hasPermission(EapPermission.UpdateWorkpackages) ||
    workpackage?.involvedUserIds?.includes(myUserId as UserPublicID)
  ) {
    columns.push({
      key: "aktionen",
      title: "Aktionen",
      width: 150,
      render: (_value, record, idx) => (
        <ButtonGroup key={`btn-grp_${idx}`}>
          {loading ? (
            <SkeletonButton size="small" active />
          ) : (
            <Button
              className={UIElement.Activity_EditButton}
              onClick={async () => {
                try {
                  const draft = await updateActivityModal.current?.update(
                    record,
                    workpackage,
                  );
                  if (draft) {
                    const activities = {
                      ...workpackage.activities,
                      [draft.uuid!]: draft,
                    };
                    updateDraft({ activities });
                  }
                } catch {
                  // swallow error
                }
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                title="Bearbeiten"
                style={{ marginRight: 0 }}
              />
            </Button>
          )}
          {hasPermission(EapPermission.DeleteActivities) ? (
            <>
              {loading ? (
                <SkeletonButton size="small" active />
              ) : (
                <Popconfirm
                  placement="topLeft"
                  title="Aktivität wirklich löschen?"
                  onConfirm={() => {
                    const activities = Object.assign(
                      {},
                      workpackage.activities,
                    );

                    delete activities![record.uuid];
                    updateDraft({ activities });
                  }}
                  okText="Löschen"
                  okButtonProps={{
                    type: "primary",
                    danger: true,
                    className: UIElement.Activity_ConfirmDeleteButton,
                  }}
                  cancelText="Abbrechen"
                  icon={
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{ marginRight: 0, color: "red" }}
                      className="anticon"
                    />
                  }
                >
                  <Button danger className={UIElement.Activity_DeleteButton}>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      title="Löschen"
                      style={{ marginRight: 0 }}
                    />
                  </Button>
                </Popconfirm>
              )}
            </>
          ) : null}
        </ButtonGroup>
      ),
      fixed: "right",
    });
  }

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    const newIndex = activities.findIndex(
      (activity) => activity.uuid === over?.id,
    );
    const oldIndex = activities.findIndex(
      (activity) => activity.uuid === active.id,
    );

    if (oldIndex === null || oldIndex !== newIndex) {
      const oldActivities = Object.values(activities).sort(
        (a, b) => a.sortIndex! - b.sortIndex!,
      );
      const newSortedActivities = [...oldActivities];
      newSortedActivities.splice(
        newIndex,
        0,
        newSortedActivities.splice(oldIndex!, 1)[0]!,
      );
      newSortedActivities.forEach((a, i) => (a.sortIndex = i));
      updateDraft({ activities: keyBy(newSortedActivities, "uuid") });
    }
  };

  const DraggableBodyRow = ({ children, ...props }: DraggableBodyRowProps) => {
    const uuid = props["data-row-key"];

    const {
      attributes,
      setNodeRef,
      transform,
      transition,
      isDragging,
      listeners,
      setActivatorNodeRef,
    } = useSortable({
      id: uuid,
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 },
      ),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 999 } : {}),
    };

    return (
      <tr key={uuid} {...props} ref={setNodeRef} style={style} {...attributes}>
        {Children.map(children, (child) => {
          if ((child as ReactElement).key === "sortIndex") {
            return cloneElement(child as ReactElement, {
              children: (
                <>
                  <div className="table-row-tag" />
                  {hasActivitySortPermission ? (
                    <MenuOutlined
                      ref={setActivatorNodeRef}
                      className="drag-handle"
                      {...listeners}
                    />
                  ) : null}
                </>
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  return (
    <>
      {!loading ? (
        <>
          <CreateActivityModal ref={createActivityModal} />
          <UpdateActivityModal ref={updateActivityModal} />
        </>
      ) : null}
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onSortEnd}>
        <SortableContext
          // rowKey array
          items={activities.map((activity) => activity.uuid)}
          strategy={verticalListSortingStrategy}
        >
          <ReduxTable<IActivity>
            tableId="activityTable"
            columns={columns}
            dataSource={loading ? ([1, 2, 3, 4] as any) : activities!}
            scroll={activities.length === 0 ? undefined : { x: "max-content" }}
            className={"activityTable " + className}
            pagination={false}
            locale={{
              ...antdConfig.locale?.Table,
              emptyText: (
                <Empty
                  description="Keine Aktivitäten vorhanden"
                  className="mt-10 mb-10 text-gray-400"
                />
              ),
            }}
            sticky={{
              offsetHeader: 142,
            }}
            size="large"
            title={() => (
              <Row align="middle">
                <Col
                  style={{
                    fontSize: "larger",
                    fontWeight: "bold",
                    marginRight: "50px",
                  }}
                  flex={1}
                >
                  Aktivitäten
                </Col>
                <Col>
                  {!(
                    hasPermission(EapPermission.CreateActivities) &&
                    isResponsible
                  ) &&
                  !hasPermission(
                    EapPermission.UpdateWorkpackages,
                  ) ? undefined : (
                    <AsyncButton
                      type="primary"
                      className={UIElement.Activity_CreateButton}
                      onClick={async () => {
                        if (!workpackage) {
                          return;
                        }

                        try {
                          const draft =
                            await createActivityModal.current?.create(
                              workpackage,
                            );
                          if (draft) {
                            const activities = {
                              ...workpackage.activities,
                              [draft.uuid!]: draft,
                            };
                            updateDraft({ activities });
                          }
                        } catch {
                          // swallow error
                        }
                      }}
                      disabled={loading}
                    >
                      Erstellen
                    </AsyncButton>
                  )}
                </Col>
              </Row>
            )}
            rowKey={(row) => row.uuid ?? JSON.stringify(row)}
            components={{
              body: {
                row: DraggableBodyRow,
              },
            }}
            rowClassName={
              loading
                ? undefined
                : (record, idx) => {
                    const classNames = new Array<string>();

                    const isActivityFinished =
                      Boolean(record.currentFinish) ||
                      (record.expectedFinish &&
                        moment(record.expectedFinish).isAfter(moment()));
                    const isPlannedFinishOverdue =
                      record.plannedFinish &&
                      moment(record.plannedFinish).isBefore(
                        moment().startOf("day"),
                      ) &&
                      !record.expectedFinish;
                    const isExpectedFinishOverdue =
                      record.expectedFinish &&
                      moment(record.expectedFinish).isBefore(
                        moment().startOf("day"),
                      );

                    if (
                      !isActivityFinished &&
                      (isPlannedFinishOverdue || isExpectedFinishOverdue)
                    ) {
                      classNames.push("activity-table-row-overdue");
                    }

                    classNames.push(
                      idx % 2 === 0 ? "table-row-even" : "table-row-odd",
                    );

                    if (record.label) {
                      const labelProps = getLabelProperties(record.label);
                      classNames.push("table-row-with-tag");
                      if (labelProps?.color) {
                        classNames.push(`table-row-${record.label}`);
                      }
                    }

                    return classNames.join(" ");
                  }
            }
          />
        </SortableContext>
      </DndContext>
    </>
  );
});
