import type { EapPermission, IProjectUser } from "@project/shared";
import type { DefaultOptionType } from "antd/es/select";
import { type Dictionary, find } from "lodash";

export const getFilteredProjectUserOptions = (
  projectUsersById: Dictionary<IProjectUser>,
  role?: EapPermission,
  filter?: string,
): DefaultOptionType[] => {
  const filteredUsers = Object.values(projectUsersById).filter(
    (user) =>
      user.permissions.length > 0 &&
      user.name !== "undefined undefined" && // some users without name are not correctly saved in the database
      (role !== undefined ? user.permissions?.includes(role) : true) &&
      (filter !== undefined
        ? new RegExp(filter, "i").test(user.name ?? "Unbekannt")
        : true),
  );

  return filteredUsers.map<DefaultOptionType>((u) => ({
    value: u.userId,
    label: u.name,
  }));
};

export const getProjectUserName = (
  projectUsersById: Dictionary<IProjectUser>,
  userId: string,
): string => {
  const validUsers = getFilteredProjectUserOptions(projectUsersById);
  if (find(validUsers, (u) => u.value === userId)) {
    const user = projectUsersById[userId]!;
    return user.name;
  }

  return "Unbekannter Nutzer";
};

export const nobodyOption: DefaultOptionType[] = [
  { value: "null", label: "Niemand" },
];
