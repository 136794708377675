import { Reducer } from "@feinarbyte/reduxhelper";
import type { EapPermission } from "@project/shared";
import type { WBSTreeElement } from "../types/WBSTreeTypes";

interface UserData {
  name: string;
  email: string;
  id: string;
}

export interface DefaultProject {
  orgId: string;
  projectId: string;
}

export const VERSION_DRAFT = "entwurf";

export interface AppState {
  expanded: string[];
  favorites: { [code: string]: true };
  myUserGroups?: string[];
  myUserPermissions?: EapPermission[];
  defaultProject?: DefaultProject;
  myUserData?: UserData;
  activeVersion?: string;
  versionToCompare?: string;
  deletionDraft?: Pick<WBSTreeElement, "code" | "label" | "type">;

  // UI
  siderIsDragging: boolean;
  siderHidden: boolean;
  siderWidth: number;
  doNotShowChangelog?: boolean;
  lastKnownVersion: string;
}

export const defaultAppState: AppState = {
  expanded: ["all"],
  myUserPermissions: [],
  favorites: {},

  // UI
  siderIsDragging: false,
  siderHidden: true,
  siderWidth: 500,
  lastKnownVersion: "0.0.0",
};

class AppReducer extends Reducer<AppState> {
  constructor() {
    super(
      defaultAppState,
      true,
      [
        "favorites",
        "expanded",
        "siderWidth",
        "lastKnownVersion",
        "defaultProject",
      ],
      undefined,
      "AppReducer",
    );
  }

  public swapVersionToCompare() {
    if (!this.state.versionToCompare) {
      return;
    }

    const tmp = this.state.activeVersion;
    this.state.activeVersion = this.state.versionToCompare;
    this.state.versionToCompare = tmp;
  }

  public toggleFavorite(code: string) {
    if (this.state.favorites[code]) {
      delete this.state.favorites[code];
    } else {
      this.state.favorites[code] = true;
    }
  }
}

const AppReducerInstance = new AppReducer();
export const AppApi = AppReducerInstance.getApi();
export const AppReduxReducer = AppReducerInstance.getReduxReducer();
