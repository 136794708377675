import type { ApolloClient } from "@apollo/client";
import type {
  AtomDescriptor,
  ContextValues,
  SubscribeAtomPacket,
  UserPublicID,
} from "@atoms/atom-types";
import { EventEmitter } from "eventemitter3";
import { type Operation, applyPatch } from "fast-json-patch";
import { type Delta, diff, patch } from "jsondiffpatch";
import { cloneDeep } from "lodash";
import {
  type SubscriptionCallbacks,
  subscriptionDocument,
} from "./AtomContext";
import type { SelectorFunction } from "./makeUseAtom";

export class HashValidationError extends Error {
  constructor(
    public hashFromBackend: string,
    public localHash: string,
    public atomType: string,
    public context: ContextValues,
  ) {
    super(
      `Hash validation failed for atom ${atomType} with context ${JSON.stringify(
        context,
      )}. Hash from backend: ${hashFromBackend}, local hash: ${localHash}`,
    );
  }
}
// async function sha256(message: string): Promise<string> {
//   // encode as UTF-8
//   const msgBuffer = new TextEncoder().encode(message);

//   // hash the message
//   const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

//   // convert ArrayBuffer to Array
//   const hashArray = Array.from(new Uint8Array(hashBuffer));

//   // convert bytes to hex string
//   const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
//   return hashHex;
// }
export class AtomStore<AtomType extends string = string> extends EventEmitter {
  private subscriptions: SubscriptionCallbacks = {};

  private state: { [key in AtomType]?: any } = {};

  private retries: {
    [path: string]: { count: number; timeout?: ReturnType<typeof setTimeout> };
  } = {};

  constructor(
    private apolloClient: ApolloClient<object>,
    public getContextHook: () => ContextValues,
    public __getUsernameHook: (userId: UserPublicID) => string | undefined,
    // biome-ignore lint/style/useDefaultParameterLast: <explanation>
    public onError: (error: any) => void = console.error.bind(console),
    public pathForAtomWithContext: (
      type: AtomType,
      context: ContextValues,
    ) => string,
  ) {
    super();
  }

  public updateErrorCallback(callback: (error: any) => void): void {
    this.onError = callback;
  }

  public getApolloClient(): ApolloClient<object> {
    return this.apolloClient;
  }

  public getPresentAtoms(): AtomDescriptor[] {
    return Object.keys(this.subscriptions) as AtomDescriptor[];
  }

  public getAtom<RType = any>(
    type: AtomType,
    context: ContextValues,
  ): RType | undefined {
    const atomId = this.pathForAtomWithContext(type, context);

    if (this.subscriptions[atomId]?.error) {
      throw this.subscriptions[atomId]?.error;
    }

    return this.subscriptions[atomId]?.lastValue as RType;
  }

  public subscribe<RType extends object = any>(
    type: AtomType,
    context: ContextValues,
    callback: (
      value: RType | null,
      patches: Delta,
      computedPatches?: boolean,
    ) => void,
    selector: SelectorFunction<RType, any> = (value): RType => value,
  ): void {
    const atomId = this.pathForAtomWithContext(type, context);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const matchingSubscription = this.subscriptions[atomId];
    if (!matchingSubscription) {
      // if (matchingSubscription.unsubscribeTimeout) {
      //   clearTimeout(matchingSubscription.unsubscribeTimeout);
      //   matchingSubscription.unsubscribeTimeout = undefined;
      // }
      if (this.retries[atomId]) {
        clearTimeout(this.retries[atomId]!.timeout);
        delete this.retries[atomId];
      }

      this.retries[atomId] = { count: 0 };

      const getSubscription = (): Promise<ZenObservable.Subscription> =>
        new Promise<ZenObservable.Subscription>((resolve) => {
          resolve(
            this.apolloClient
              .subscribe<{
                subscribeAtom: SubscribeAtomPacket<RType, AtomType>;
              }>({
                query: subscriptionDocument,
                fetchPolicy: "no-cache",
                variables: { type, context },
              })
              .subscribe({
                next({ data }) {
                  if (data) {
                    {
                      /* this scope will not be changed during re-generation */
                      const s = self.subscriptions[atomId];
                      if (s) {
                        if ("__doesNotExist" in data.subscribeAtom) {
                          // Atom does not exist
                          s.lastValue = null;
                          for (const callback of s.callbacks) {
                            callback(null, []);
                          }
                        } else if ("__patches" in data.subscribeAtom) {
                          const { __patches } = data.subscribeAtom as {
                            __patches: Operation[];
                            hash: string;
                          };
                          const newValue = applyPatch(
                            cloneDeep(s.lastValue ?? {}),
                            __patches,
                          ).newDocument;

                          s.lastValue = newValue;
                          self.state[type] = newValue;
                          for (const callback of s.callbacks) {
                            callback(newValue, __patches);
                          }
                        } else if ("__delta" in data.subscribeAtom) {
                          const { __delta } = data.subscribeAtom as {
                            __delta: any;
                          };
                          if (Object.keys(__delta).length === 0) {
                            return;
                          }
                          const newValue = cloneDeep(s.lastValue ?? {});
                          patch(newValue, __delta);
                          s.lastValue = newValue;
                          self.state[type] = newValue;
                          for (const callback of s.callbacks) {
                            try {
                              callback(newValue, __delta);
                            } catch (e) {
                              console.error(e);
                            }
                          }
                        } else {
                          const newValue = data.subscribeAtom as RType;
                          s.lastValue = newValue;
                          self.state[type] = newValue;
                          const patches = diff({}, newValue);
                          for (const callback of s.callbacks) {
                            callback(newValue, patches as any, true);
                          }
                        }
                      }
                    }
                  }
                },
                complete() {
                  const s = self.subscriptions[atomId];

                  if (s) {
                    s.lastValue = null;
                    s.completed = true;
                    for (const callback of s.callbacks) {
                      callback(null, []);
                    }
                  }
                },
                error({ graphQLErrors }: { graphQLErrors?: Array<Error> }) {
                  if (!graphQLErrors) {
                    return;
                  }

                  graphQLErrors.forEach((error) => {
                    self.subscriptions[atomId]!.error = error;
                    self.subscriptions[atomId]!.callbacks.forEach(
                      (callback) => {
                        callback(error as RType, []);
                      },
                    );
                  });
                },
              }),
          );
        });
      this.subscriptions[atomId] = {
        selectors: [selector],
        callbacks: [callback],
        lastValue: undefined,
        current: getSubscription(),
      };
    } else {
      this.subscriptions[atomId]!.callbacks.push(callback);
      this.subscriptions[atomId]!.selectors.push(selector);
    }
    self.addListener(atomId, callback);
  }

  public getUsernameHook(): (userId: UserPublicID) => string | undefined {
    return this.__getUsernameHook;
  }

  public async unsubscribe(
    type: AtomType,
    context: ContextValues,
    callback: (value: any, patches: any[]) => void,
  ): Promise<void> {
    const atomId = this.pathForAtomWithContext(type, context);
    this.removeListener(atomId, callback);

    if (!this.subscriptions[atomId]) {
      console.debug(`No subscription for ${atomId}`);
      return;
    }

    const index = this.subscriptions[atomId]!.callbacks.findIndex(
      (cb) => cb === callback,
    );
    this.subscriptions[atomId]!.callbacks.splice(index, 1);
    this.subscriptions[atomId]!.selectors.splice(index, 1);

    const sub = await this.subscriptions[atomId]!.current;
    if (!sub?.closed && this.listenerCount(atomId) === 0) {
      this.subscriptions[atomId]!.unsubscribeTimeout = setTimeout(() => {
        if (!sub?.closed && this.listenerCount(atomId) === 0) {
          sub?.unsubscribe();
          delete this.subscriptions[atomId];
          if (this.retries[atomId]?.timeout) {
            clearTimeout(this.retries[atomId]!.timeout);
          }
          delete this.retries[atomId];
        }
      }, 1000);
    }
  }
}
