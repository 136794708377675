import { DraftItemType, Atoms, getDraftOperation } from "@project/shared";
import { memo } from "react";
import { useSelector } from "react-redux";
import { useApiClient } from "@project/api";
import type { State } from "../../state";
import { ModalsApi } from "../../state/modals";
import { ElementType } from "../../types/WBSTreeTypes";
import { useThunkDispatch } from "../../useThunkDispatch";
import { ReduxModal } from "./ReduxModal";
import { useAtom } from "@project/api";

export const DeleteDraftWithChildrenModalId = "deleteDraftWithChildren";
export const DeleteDraftWithChildrenModal = memo(() => {
  const dispatch = useThunkDispatch();
  const api = useApiClient();
  const element = useSelector((state: State) => state.app.deletionDraft);
  const drafts = useAtom(Atoms.Draft, (d) => d.entries);
  const childrenDrafts = drafts?.filter(
    (d) =>
      element &&
      d.code.startsWith(element.code) &&
      getDraftOperation(d.type) === "Add",
  );

  return element ? (
    <ReduxModal
      id={DeleteDraftWithChildrenModalId}
      title="WBS-Element löschen"
      okText="Löschen"
      onOk={async () => {
        await api.Project.pushDraft({
          code: element.code,
          type:
            element.type === ElementType.Category
              ? DraftItemType.DeleteCategory
              : DraftItemType.DeleteWorkpackage,
          label: element.label,
        });
      }}
      onCancel={() => dispatch(ModalsApi.hide("deleteDraftWithChildren"))}
    >
      {childrenDrafts && childrenDrafts.length > 0 ? (
        <p>
          Das WBS-Element <b>{element.label}</b> enthält {childrenDrafts.length}{" "}
          neu {`hinzugefügte${childrenDrafts.length === 1 ? "s" : ""}`}{" "}
          {`Kindelement${childrenDrafts.length > 1 ? "e" : ""}`}.{" "}
          {childrenDrafts.length > 1 ? "Diese werden" : "Dieses wird"} ebenfalls
          gelöscht.
        </p>
      ) : null}
    </ReduxModal>
  ) : null;
});
