import { useContext } from "react";
import { AtomContext } from "../AtomContext";
import type { UserPublicID } from "@atoms/atom-types";

export const __useUsername = (userId: UserPublicID): string => {
  const atomStore = useContext(AtomContext);
  if (!atomStore) {
    throw new Error("atomStore not initialized");
  }

  const useUsername = atomStore.getUsernameHook();
  return useUsername(userId);
};
