import { VERSION_DRAFT } from "./state/app";

export enum UIElement {
  // Top navigation bar
  Header_Home = "pw_header_home",
  Header_ToDos = "pw_header_todos",
  Header_WBS = "pw_header_wbs",
  Header_Gantt = "pw_header_gantt",
  Header_Activity = "pw_header_activity",
  Header_Flexopus = "pw_header_flexopus",

  // WBS page
  WBS_CreateButton = "pw_wbs_create_button",
  WBS_CreateDraftButton = "pw_wbs_create_draft_button",
  WBS_CreateDraftCodeInput = "pw_wbs_create_draft_code_input",
  WBS_CreateDraftLabelInput = "pw_wbs_create_draft_label_input",
  WBS_CreateDraftDescriptionInput = "pw_wbs_create_draft_description_input",
  WBS_DraftResetButton = "pw_wbs_draft_reset_button",
  WBS_ConfirmDraftResetButton = "pw_wbs_confirm_draft_reset_button",
  WBS_CancelDraftResetButton = "pw_wbs_cancel_draft_reset_button",
  WBS_ChangesInfo = "pw_wbs_changes_info",
  WBS_ChangesInfoResetButton = "pw_wbs_changes_info_reset_button",
  WBS_VersionMenuButton = "pw_wbs_version_menu_button",
  WBS_VersionSelect = "pw_wbs_version_select",

  WBS_CreateVersionButton = "pw_wbs_create_version_button",
  WBS_TreeEmpty = "pw_wbs_tree_empty",

  WBS_HoverMenu_CreateCategoryButton = "pw_wbs_hover_menu_create_category_button",
  WBS_HoverMenu_RemoveButton = "pw_wbs_hover_menu_remove_button",
  WBS_HoverMenu_CreateWorkPackageButton = "pw_wbs_hover_menu_create_work_package_button",
  WBS_HoverMenu_OpenWorkPackageButton = "pw_wbs_hover_menu_open_work_package_button",

  WBS_RestoreButton = "pw_wbs_restore_button",
  WBS_UndoRestoreButton = "pw_wbs_undo_restore_button",

  WBS_Table_TitleInput = "pw_wbs_table_title_input",
  WBS_Table_DescriptionInput = "pw_wbs_table_description_input",

  WBS_SwitchToTreeViewButton = "pw_wbs_switch_to_tree_view_button",
  WBS_SwitchToTableViewButton = "pw_wbs_switch_to_table_view_button",

  // Modal
  Modal_Confirm = "pw_modal_confirm",
  Modal_Cancel = "pw_modal_cancel",

  CreateVersion_Modal = "pw_modal_createVersion",
  CreateDraft_Modal = "pw_modal_createDraft",
  CreateActivity_Modal = "pw_modal_createActivity",
  CreateTodo_Modal = "pw_modal_createTodo",
  CreateTodoList_Modal = "pw_modal_createTodoList",
  ChangeLog_Modal = "pw_modal_changeLog",
  UpdateActivity_Modal = "pw_modal_updateActivity",

  // Todo page
  Todo_CreateTodoListButton = "pw_todo_create_todo_list_button",
  Todo_CreateTodoButton = "pw_todo_create_todo_button",
  Todo_CreateTodoListTitleInput = "pw_todo_create_todo_list_title_input",
  Todo_CreateTodoDescriptionInput = "pw_todo_create_todo_description_input",
  Todo_CreateTodoPlannedFinishInput = "pw_todo_create_todo_planned_finish_input",
  Todo_CreateTodoStatusInput = "pw_todo_create_todo_status_input",

  Todo_ListDescriptionInput = "pw_todo_list_description_input",
  Todo_ListResponsibleInput = "pw_todo_list_responsible_input",
  Todo_ListInvolvedInput = "pw_todo_list_involved_input",
  Todo_ListDependentWBSElementsInput = "pw_todo_list_dependent_wbs_elements_input",

  Todo_ListSaveButton = "pw_todo_save_todo_list_button",
  Todo_ListRevertButton = "pw_todo_undo_changes_button",
  Todo_ListDeleteButton = "pw_todo_delete_todo_list_button",
  Todo_ListConfirmDeleteButton = "pw_todo_confirm_delete_todo_list_button",
  Todo_CloseTodoListButton = "pw_todo_close_todo_list_button",
  Todo_DeleteTodoButton = "pw_todo_delete_todo_button",
  Todo_ConfirmDeleteTodoButton = "pw_todo_confirm_delete_todo_button",

  // Workpackage page
  Workpackage_TitleInput = "pw_workpackage_title_input",
  Workpackage_DescriptionInput = "pw_workpackage_description_input",
  Workpackage_ObjectiveInput = "pw_workpackage_objective_input",
  Workpackage_GoalsInput = "pw_workpackage_goals_input",
  Workpackage_PlannedStartInput = "pw_workpackage_planned_start_input",
  Workpackage_PlannedStartDropdown = "pw_workpackage_planned_start_dropdown",
  Workpackage_PlannedFinishInput = "pw_workpackage_planned_finish_input",
  Workpackage_PlannedFinishDropdown = "pw_workpackage_planned_finish_dropdown",
  Workpackage_ComissionerInput = "pw_workpackage_comissioner_input",
  Workpackage_ComissionerDropdown = "pw_workpackage_comissioner_dropdown",
  Workpackage_ResponsibleInput = "pw_workpackage_responsible_input",
  Workpackage_ResponsibleDropdown = "pw_workpackage_responsible_dropdown",
  Workpackage_InvolvedInput = "pw_workpackage_involved_input",
  Workpackage_InvolvedDropdown = "pw_workpackage_involved_dropdown",
  Workpackage_DependentWBSElementsInput = "pw_workpackage_dependent_wbs_elements_input",
  Workpackage_DependentWBSElementsDropdown = "pw_workpackage_dependent_wbs_elements_dropdown",
  Workpackage_SaveButton = "pw_workpackage_save_button",
  Workpackage_UndoChangesButton = "pw_workpackage_undo_changes_button",

  // Workpoackage page - Activities
  Activity_CreateButton = "pw_activity_create_button",
  Activity_DescriptionInput = "pw_activity_description_input",
  Activity_PlannedFinishInput = "pw_activity_planned_finish_input",
  Activity_PlannedFinishDropdown = "pw_activity_planned_finish_dropdown",
  Activity_StatusInput = "pw_activity_status_input",
  Activity_ExpectedFinishInput = "pw_activity_expected_finish_input",
  Activity_ExpectedFinishDropdown = "pw_activity_expected_finish_dropdown",
  Activity_CurrentFinishInput = "pw_activity_current_finish_input",
  Activity_CurrentFinishDropdown = "pw_activity_current_finish_dropdown",
  Activity_DeleteButton = "pw_activity_delete_button",
  Activity_ConfirmDeleteButton = "pw_activity_confirm_delete_button",
  Activity_EditButton = "pw_activity_edit_button",
  // Controlinformations
  Workpackage_SignalInput = "pw_workpackage_signal_input",
  Workpackage_ProgressEstimationInput = "pw_workpackage_progress_estimation_input",
  Workpackage_StatusInput = "pw_workpackage_status_input",

  // Login page
  LoginUsernameInput = "pw_username_input",
  LoginPasswordInput = "pw_password_input",
}

export const getVersionUIElement = (version?: string) => {
  if (!version) {
    return `pw_wbs_version_${VERSION_DRAFT}`;
  }

  return `pw_wbs_version_${version}`;
};

export const getNodeUIElement = (code: string) => {
  return `pw_wbs_node_${code.replace(/\./g, "_")}`;
};

export const getNodeLabelUIElement = (code: string) => {
  return `pw_wbs_node_${code.replace(/\./g, "_")}_label`;
};

export const getNodeCodeUIElement = (code: string) => {
  return `pw_wbs_node_${code.replace(/\./g, "_")}_code`;
};

export const dateToInputString = (date: Date) =>
  date.toISOString().split("T")[0];
