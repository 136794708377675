import { ProjectProvision } from "@project/frontend-provision";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ErrorBoundary key="root">
      {ProjectProvision.contextSettings.children}
    </ErrorBoundary>
  </StrictMode>,
);
