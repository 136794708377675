import { type ReactNode, createContext, useContext } from "react";
import type { ContextValues } from "@atoms/atom-types";

export const ExtraContext = createContext<ContextValues>({});

export const SetId = ({
  identifier,
  value,
  children,
}: {
  identifier: string;
  value: string | undefined;
  children: ReactNode;
}) => {
  const parentContext = useContext(ExtraContext);
  return (
    <ExtraContext.Provider value={{ ...parentContext, [identifier]: value }}>
      {children}
    </ExtraContext.Provider>
  );
};
