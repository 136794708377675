import {
  faArrowUpRightFromSquare,
  faArrowsAltH,
  faArrowsAltV,
  faFileMedical,
  faFilter,
  faProjectDiagram,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DraftItemType,
  Atoms,
  EapPermission,
  getDraftOperation,
} from "@project/shared";
import { Alert } from "antd";
import "antd/dist/reset.css";
import Dropdown from "antd/es/dropdown/dropdown";
import type { ItemType } from "antd/es/menu/interface";
import type { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useApiClient } from "@project/api";
import { useHasPermission } from "../../hooks/useHasPermission";
import type { State } from "../../state";
import { AppApi, VERSION_DRAFT } from "../../state/app";
import { ModalsApi } from "../../state/modals";
import { WbsApi } from "../../state/wbs";
import {
  ChildAlignment,
  ElementType,
  type WBSTreeElement,
} from "../../types/WBSTreeTypes";
import { useThunkDispatch } from "../../useThunkDispatch";
import { CreateAddDraftModalId } from "../modals/CreateAddDraftModal";
import { useFullscreenContext } from "./FullscreenContext";
import { useScopeAwareNavigate } from "../../hooks/useScopeAwareNavigate";
import { useAtom } from "@project/api";

export interface WBSMenuProps {
  element: WBSTreeElement;
  children: ReactNode;
}

export const WBSContextMenu = ({ element, children }: WBSMenuProps) => {
  const flippedIndex = useSelector((state: State) =>
    state.wbs.flippedCodes.indexOf(element.code),
  );
  const collapsedIndex = useSelector((state: State) =>
    state.wbs.collapsedCodes.indexOf(element.code),
  );
  const api = useApiClient();
  const navigate = useScopeAwareNavigate();
  const drafts = useAtom(Atoms.Draft, (d) => d.entries);

  const dispatch = useThunkDispatch();
  const versionComparisonMode = useSelector(
    (state: State) => state.app.versionToCompare !== undefined,
  );
  const selectedVersion = useSelector(
    (state: State) => state.app.activeVersion,
  );
  const isDraftVersion = selectedVersion === VERSION_DRAFT;
  const hasPermission = useHasPermission();
  const isDraft = element.draft !== undefined;
  const dropDownRef = useFullscreenContext();

  const items: ItemType[] = [
    !isDraftVersion && !versionComparisonMode
      ? {
          key: "warning",
          onClick: () => dispatch(AppApi.set("activeVersion", VERSION_DRAFT)),
          icon: (
            <Alert
              type="warning"
              style={{ marginBottom: 8, fontSize: 14, textAlign: "center" }}
              message={
                <>
                  Zum Bearbeiten, bitte in den
                  <br />
                  Entwufsmodus wechseln.
                </>
              }
            />
          ),
        }
      : null,
    !isDraft
      ? {
          key: "goto",
          icon: (
            <FontAwesomeIcon
              icon={
                element.type === ElementType.Category
                  ? faFilter
                  : faArrowUpRightFromSquare
              }
            />
          ),
          label:
            element.type === ElementType.Category
              ? "Kategorie filtern"
              : "Arbeitspaket öffnen",
          onClick: () => {
            if (element.type === ElementType.Category) {
              navigate(`/wbs/${element.code}`);
            } else {
              navigate(`/p/${element.code}`);
            }
          },
        }
      : null,
    !versionComparisonMode &&
    isDraftVersion &&
    element.type !== ElementType.Package &&
    !(
      element.draft !== undefined &&
      getDraftOperation(element.draft) === "Delete"
    ) &&
    hasPermission(EapPermission.CreateDraft)
      ? {
          key: "divider-1",
          type: "divider",
        }
      : null,
    !versionComparisonMode &&
    isDraftVersion &&
    element.type !== ElementType.Package &&
    !(
      element.draft !== undefined &&
      getDraftOperation(element.draft) === "Delete"
    ) &&
    hasPermission(EapPermission.CreateDraft)
      ? {
          key: "create_category",
          icon: <FontAwesomeIcon icon={faProjectDiagram} />,
          label: "Neues WBS-Element",
          onClick: () => {
            dispatch(
              WbsApi.set("draft", {
                parent: element.code,
                type: DraftItemType.AddCategory,
              }),
            );
            dispatch(ModalsApi.show(CreateAddDraftModalId));
          },
        }
      : null,
    !versionComparisonMode &&
    isDraftVersion &&
    element.type !== ElementType.Package &&
    !(
      element.draft !== undefined &&
      getDraftOperation(element.draft) === "Delete"
    ) &&
    hasPermission(EapPermission.CreateDraft)
      ? {
          key: "create_workpackage",
          icon: <FontAwesomeIcon icon={faFileMedical} />,
          label: "Neues Arbeitspaket",
          onClick: () => {
            dispatch(
              WbsApi.set("draft", {
                parent: element.code,
                type: DraftItemType.AddWorkpackage,
              }),
            );
            dispatch(ModalsApi.show(CreateAddDraftModalId));
          },
        }
      : null,
    !versionComparisonMode &&
    isDraftVersion &&
    element.type !== ElementType.Package &&
    !(
      element.draft !== undefined &&
      getDraftOperation(element.draft) === "Delete"
    ) &&
    hasPermission(EapPermission.CreateDraft) &&
    collapsedIndex === -1 &&
    element.children.length > 1
      ? {
          key: "divider-2",
          type: "divider",
        }
      : null,
    collapsedIndex === -1 && element.children.length > 1
      ? {
          key: "flip",
          icon: (
            <FontAwesomeIcon
              icon={
                element.childAlignment === ChildAlignment.Horizontal
                  ? faArrowsAltV
                  : faArrowsAltH
              }
            />
          ),
          label: "Ausrichtung ändern",
          onClick: () => {
            if (flippedIndex === -1) {
              dispatch(WbsApi.add("flippedCodes", element.code));
            } else {
              dispatch(WbsApi.remove("flippedCodes", flippedIndex));
            }
          },
        }
      : null,
    !versionComparisonMode && isDraftVersion
      ? element.draft !== undefined
        ? hasPermission(EapPermission.DeleteDraft) && !element.computedDraft
          ? {
              key: "delete_draft",
              icon: <FontAwesomeIcon icon={faTrash} />,
              label: "Änderung verwerfen",
              onClick: async () => {
                await dispatch(() =>
                  api.Project.removeDraft({ code: element.code }),
                );
              },
              danger: true,
            }
          : null
        : hasPermission(EapPermission.CreateDraft)
          ? {
              key: "delete",
              icon: <FontAwesomeIcon icon={faTrash} />,
              label: "Löschen",
              onClick: async () => {
                if (
                  drafts?.find(
                    (d) =>
                      d.code.startsWith(element.code) &&
                      getDraftOperation(d.type) === "Add",
                  )
                ) {
                  dispatch(
                    AppApi.set("deletionDraft", {
                      code: element.code,
                      label: element.label ?? "",
                      type: element.type,
                    }),
                  );
                  dispatch(ModalsApi.show("deleteDraftWithChildren"));
                  return;
                } else {
                  return api.Project.pushDraft({
                    code: element.code,
                    type:
                      element.type === ElementType.Category
                        ? DraftItemType.DeleteCategory
                        : DraftItemType.DeleteWorkpackage,
                    label: element.label,
                  });
                }
              },
              danger: true,
            }
          : null
      : null,
  ].filter(Boolean) as ItemType[];

  if (items?.length === 0) {
    return (
      <g
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children}
      </g>
    );
  }

  return (
    <Dropdown
      trigger={["contextMenu"]}
      menu={{ items }}
      getPopupContainer={
        dropDownRef.current !== null ? () => dropDownRef.current! : undefined
      }
    >
      {children}
    </Dropdown>
  );
};
