import { Atoms } from "@project/shared";
import { useAtom } from "@project/api";

export interface OwnProps {
  userId: string;
  fallbackName?: string;
}
export const ProjectUserName = ({
  userId,
  fallbackName = "Unbekannt",
}: OwnProps) => {
  const projectUser = useAtom(Atoms.ProjectUsers, (p) =>
    p.entries.find((u) => u.userId === userId),
  );

  return <>{projectUser?.name}</>;
};
