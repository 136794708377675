/*
 * This file has been generated by apiClient
 * Hash: 3f36c8c6bd87ae00dd59867d1ba3fe11374017ae3bb27575cb06f95f242afaa1
 */

import type { ApolloClient } from "@atoms/atom-client";
import { makeUseApiClient } from "@atoms/atom-client";
import type {
  ContextValues,
  UserPublicID,
  VersionedAtomData,
  WithDefaultValues,
} from "@atoms/atom-types";
import type {
  EapOrganizationRole,
  EapProjectRole,
  IActivity,
  IBookedLicenses,
  ICategory,
  IDraft,
  IOrganizationInfo,
  IOrganizationMembership,
  IProjectInfo,
  IProjectMembership,
  ITemplateInfo,
  ITodoList,
  IVersion,
  IWorkpackage,
} from "@project/shared";
import gql from "graphql-tag";
export const APIClient = {
  Organization: {
    addUserToByEmail: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      email: string,
      name: string,
      roles: Array<EapOrganizationRole>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          addUserToOrganizationByEmail: Promise<boolean>;
        }>({
          mutation: gql`
mutation addUserToOrganizationByEmail($context: JSON!, $email: String!, $name: String!, $roles: [EapOrganizationRole!]!) {
    addUserToOrganizationByEmail(context: $context, email: $email, name: $name, roles: $roles)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            email,
            name,
            roles,
          },
        });

        return res!.addUserToOrganizationByEmail;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    createProject: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      projectInfo: IProjectInfo,
      extraContext?: ContextValues,
    ): Promise<string> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          createProject: Promise<string>;
        }>({
          mutation: gql`
mutation createProject($context: JSON!, $projectInfo: JSON!) {
    createProject(context: $context, projectInfo: $projectInfo)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, projectInfo },
        });

        return res!.createProject;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    updateBookedLicenses: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      update: Partial<IBookedLicenses>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          updateBookedLicenses: Promise<boolean>;
        }>({
          mutation: gql`
mutation updateBookedLicenses($context: JSON!, $update: JSON!) {
    updateBookedLicenses(context: $context, update: $update)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, update },
        });

        return res!.updateBookedLicenses;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    update: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      update: Partial<IOrganizationInfo>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          updateOrganizationInfo: Promise<boolean>;
        }>({
          mutation: gql`
mutation updateOrganizationInfo($context: JSON!, $update: JSON!) {
    updateOrganizationInfo(context: $context, update: $update)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, update },
        });

        return res!.updateOrganizationInfo;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    updateMembersEntry: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      key: UserPublicID,
      newValue: IOrganizationMembership,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          updateOrganizationMembersEntry: Promise<boolean>;
        }>({
          mutation: gql`
mutation updateOrganizationMembersEntry($context: JSON!, $key: JSON!, $newValue: JSON!) {
    updateOrganizationMembersEntry(context: $context, key: $key, newValue: $newValue)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            key,
            newValue,
          },
        });

        return res!.updateOrganizationMembersEntry;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
  },

  Project: {
    addCategoryDraft: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      parentCode: string,
      extraContext?: ContextValues,
    ): Promise<string> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          addCategoryDraft: Promise<string>;
        }>({
          mutation: gql`
mutation addCategoryDraft($context: JSON!, $parentCode: String!) {
    addCategoryDraft(context: $context, parentCode: $parentCode)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, parentCode },
        });

        return res!.addCategoryDraft;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    addToMembers: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      key: UserPublicID,
      newValue: IProjectMembership,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          addToProjectMembers: Promise<boolean>;
        }>({
          mutation: gql`
mutation addToProjectMembers($context: JSON!, $key: JSON!, $newValue: JSON!) {
    addToProjectMembers(context: $context, key: $key, newValue: $newValue)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            key,
            newValue,
          },
        });

        return res!.addToProjectMembers;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    addUserToByEmail: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      email: string,
      name: string,
      roles: Array<EapProjectRole>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          addUserToProjectByEmail: Promise<boolean>;
        }>({
          mutation: gql`
mutation addUserToProjectByEmail($context: JSON!, $email: String!, $name: String!, $roles: [EapProjectRole!]!) {
    addUserToProjectByEmail(context: $context, email: $email, name: $name, roles: $roles)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            email,
            name,
            roles,
          },
        });

        return res!.addUserToProjectByEmail;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    addWorkpackageDraft: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      parentCode: string,
      extraContext?: ContextValues,
    ): Promise<string> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          addWorkpackageDraft: Promise<string>;
        }>({
          mutation: gql`
mutation addWorkpackageDraft($context: JSON!, $parentCode: String!) {
    addWorkpackageDraft(context: $context, parentCode: $parentCode)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, parentCode },
        });

        return res!.addWorkpackageDraft;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    changeDraftCode: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      code: string,
      newCode: string,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          changeDraftCode: Promise<boolean>;
        }>({
          mutation: gql`
mutation changeDraftCode($context: JSON!, $code: String!, $newCode: String!) {
    changeDraftCode(context: $context, code: $code, newCode: $newCode)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            code,
            newCode,
          },
        });

        return res!.changeDraftCode;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    changeDraftDescription: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      code: string,
      newDescription: string,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          changeDraftDescription: Promise<boolean>;
        }>({
          mutation: gql`
mutation changeDraftDescription($context: JSON!, $code: String!, $newDescription: String!) {
    changeDraftDescription(context: $context, code: $code, newDescription: $newDescription)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            code,
            newDescription,
          },
        });

        return res!.changeDraftDescription;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    changeDraftLabel: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      code: string,
      newLabel: string,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          changeDraftLabel: Promise<boolean>;
        }>({
          mutation: gql`
mutation changeDraftLabel($context: JSON!, $code: String!, $newLabel: String!) {
    changeDraftLabel(context: $context, code: $code, newLabel: $newLabel)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            code,
            newLabel,
          },
        });

        return res!.changeDraftLabel;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    deleteAllDrafts: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          deleteAllDrafts: Promise<boolean>;
        }>({
          mutation: gql`mutation deleteAllDrafts($context: JSON!) {
        deleteAllDrafts(context: $context)
   }`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext } },
        });

        return res!.deleteAllDrafts;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    pushDraft: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      data: IDraft,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          pushDraft: Promise<boolean>;
        }>({
          mutation: gql`
mutation pushDraft($context: JSON!, $data: JSON!) {
    pushDraft(context: $context, data: $data)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, data },
        });

        return res!.pushDraft;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    removeDraft: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      data: Partial<IDraft>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          removeDraft: Promise<boolean>;
        }>({
          mutation: gql`
mutation removeDraft($context: JSON!, $data: JSON!) {
    removeDraft(context: $context, data: $data)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, data },
        });

        return res!.removeDraft;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    removeFromMembers: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      key: UserPublicID,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          removeFromProjectMembers: Promise<boolean>;
        }>({
          mutation: gql`
mutation removeFromProjectMembers($context: JSON!, $key: JSON!) {
    removeFromProjectMembers(context: $context, key: $key)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, key },
        });

        return res!.removeFromProjectMembers;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    restoreBackup: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      backup: string,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          restoreBackup: Promise<boolean>;
        }>({
          mutation: gql`
mutation restoreBackup($context: JSON!, $backup: String!) {
    restoreBackup(context: $context, backup: $backup)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, backup },
        });

        return res!.restoreBackup;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    setDraft: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      draft: Array<IDraft>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          setDraft: Promise<boolean>;
        }>({
          mutation: gql`
mutation setDraft($context: JSON!, $draft: [JSON!]!) {
    setDraft(context: $context, draft: $draft)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, draft },
        });

        return res!.setDraft;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    update: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      update: Partial<IProjectInfo>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          updateProjectInfo: Promise<boolean>;
        }>({
          mutation: gql`
mutation updateProjectInfo($context: JSON!, $update: JSON!) {
    updateProjectInfo(context: $context, update: $update)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, update },
        });

        return res!.updateProjectInfo;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    updateMembersEntry: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      key: UserPublicID,
      newValue: IProjectMembership,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          updateProjectMembersEntry: Promise<boolean>;
        }>({
          mutation: gql`
mutation updateProjectMembersEntry($context: JSON!, $key: JSON!, $newValue: JSON!) {
    updateProjectMembersEntry(context: $context, key: $key, newValue: $newValue)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            key,
            newValue,
          },
        });

        return res!.updateProjectMembersEntry;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
  },
  Global: {
    createOrganization: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      bookedLicenses: WithDefaultValues<
        IBookedLicenses,
        "deleted" | "licenses"
      >,
      organizationInfo: IOrganizationInfo,
      extraContext?: ContextValues,
    ): Promise<string> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          createOrganization: Promise<string>;
        }>({
          mutation: gql`
mutation createOrganization($context: JSON!, $bookedLicenses: JSON!, $organizationInfo: JSON!) {
    createOrganization(context: $context, bookedLicenses: $bookedLicenses, organizationInfo: $organizationInfo)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            bookedLicenses,
            organizationInfo,
          },
        });

        return res!.createOrganization;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    nukeAllData: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          nukeAllData: Promise<boolean>;
        }>({
          mutation: gql`mutation nukeAllData($context: JSON!) {
        nukeAllData(context: $context)
   }`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext } },
        });

        return res!.nukeAllData;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
  },
  Workpackage: {
    freeze: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          freezeWorkpackage: Promise<boolean>;
        }>({
          mutation: gql`mutation freezeWorkpackage($context: JSON!) {
        freezeWorkpackage(context: $context)
   }`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext } },
        });

        return res!.freezeWorkpackage;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    readActivities: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      extraContext?: ContextValues,
    ): Promise<Record<string, IActivity>> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          readActivities: Promise<Record<string, IActivity>>;
        }>({
          mutation: gql`mutation readActivities($context: JSON!) {
        readActivities(context: $context)
   }`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext } },
        });

        return res!.readActivities;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    read: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      extraContext?: ContextValues,
    ): Promise<VersionedAtomData<IWorkpackage> | null> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          readWorkpackage2: Promise<VersionedAtomData<IWorkpackage> | null>;
        }>({
          mutation: gql`mutation readWorkpackage2($context: JSON!) {
        readWorkpackage2(context: $context)
   }`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext } },
        });

        return res!.readWorkpackage2;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    unfreeze: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          unfreezeWorkpackage: Promise<boolean>;
        }>({
          mutation: gql`mutation unfreezeWorkpackage($context: JSON!) {
        unfreezeWorkpackage(context: $context)
   }`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext } },
        });

        return res!.unfreezeWorkpackage;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    update: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      data: Partial<IWorkpackage>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          updateWorkpackage2: Promise<boolean>;
        }>({
          mutation: gql`
mutation updateWorkpackage2($context: JSON!, $data: JSON!) {
    updateWorkpackage2(context: $context, data: $data)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, data },
        });

        return res!.updateWorkpackage2;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
  },
  Category: {
    create: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      categoryInfo: WithDefaultValues<ICategory, "description">,
      extraContext?: ContextValues,
    ): Promise<string> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          createCategory: Promise<string>;
        }>({
          mutation: gql`
mutation createCategory($context: JSON!, $categoryInfo: JSON!) {
    createCategory(context: $context, categoryInfo: $categoryInfo)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, categoryInfo },
        });

        return res!.createCategory;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    delete: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      code: string,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          deleteCategory2: Promise<boolean>;
        }>({
          mutation: gql`
mutation deleteCategory2($context: JSON!, $code: String!) {
    deleteCategory2(context: $context, code: $code)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, code },
        });

        return res!.deleteCategory2;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    update: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      data: Partial<ICategory>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          updateCategory2: Promise<boolean>;
        }>({
          mutation: gql`
mutation updateCategory2($context: JSON!, $data: JSON!) {
    updateCategory2(context: $context, data: $data)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, data },
        });

        return res!.updateCategory2;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
  },
  Version: {
    createFromDrafts: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      info: Omit<IVersion, "author" | "date">,
      omitDrafts: Array<string>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          createVersionFromDrafts: Promise<boolean>;
        }>({
          mutation: gql`
mutation createVersionFromDrafts($context: JSON!, $info: JSON!, $omitDrafts: [String!]!) {
    createVersionFromDrafts(context: $context, info: $info, omitDrafts: $omitDrafts)
}`,
          fetchPolicy: "no-cache",
          variables: {
            context: { ...context, ...extraContext },
            info,
            omitDrafts,
          },
        });

        return res!.createVersionFromDrafts;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
  },
  Template: {
    create: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      templateInfo: ITemplateInfo,
      extraContext?: ContextValues,
    ): Promise<string> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          createTemplate: Promise<string>;
        }>({
          mutation: gql`
mutation createTemplate($context: JSON!, $templateInfo: JSON!) {
    createTemplate(context: $context, templateInfo: $templateInfo)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, templateInfo },
        });

        return res!.createTemplate;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    delete: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      template: string,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          deleteTemplateInfo2: Promise<boolean>;
        }>({
          mutation: gql`
mutation deleteTemplateInfo2($context: JSON!, $template: String!) {
    deleteTemplateInfo2(context: $context, template: $template)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, template },
        });

        return res!.deleteTemplateInfo2;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    read: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      extraContext?: ContextValues,
    ): Promise<ITemplateInfo | null> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          readTemplateInfo2: Promise<ITemplateInfo | null>;
        }>({
          mutation: gql`mutation readTemplateInfo2($context: JSON!) {
        readTemplateInfo2(context: $context)
   }`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext } },
        });

        return res!.readTemplateInfo2;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    update: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      data: Partial<Omit<ITemplateInfo, "template">>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          updateTemplateInfo2: Promise<boolean>;
        }>({
          mutation: gql`
mutation updateTemplateInfo2($context: JSON!, $data: JSON!) {
    updateTemplateInfo2(context: $context, data: $data)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, data },
        });

        return res!.updateTemplateInfo2;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
  },
  TodoList: {
    create: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      todoListInfo: WithDefaultValues<
        ITodoList,
        | "deleted"
        | "dependentWbsElements"
        | "description"
        | "done"
        | "involvedUserIds"
        | "label"
        | "responsibleUserId"
        | "todos"
      >,
      extraContext?: ContextValues,
    ): Promise<string> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          createTodoList: Promise<string>;
        }>({
          mutation: gql`
mutation createTodoList($context: JSON!, $todoListInfo: JSON!) {
    createTodoList(context: $context, todoListInfo: $todoListInfo)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, todoListInfo },
        });

        return res!.createTodoList;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    delete: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          deleteTodoList: Promise<boolean>;
        }>({
          mutation: gql`mutation deleteTodoList($context: JSON!) {
        deleteTodoList(context: $context)
   }`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext } },
        });

        return res!.deleteTodoList;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    update: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      data: Partial<ITodoList>,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          updateTodoList: Promise<boolean>;
        }>({
          mutation: gql`
mutation updateTodoList($context: JSON!, $data: JSON!) {
    updateTodoList(context: $context, data: $data)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, data },
        });

        return res!.updateTodoList;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
  },

  AgentSession: {
    changeTemplate: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      templateName: string,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          changeTemplate: Promise<boolean>;
        }>({
          mutation: gql`
mutation changeTemplate($context: JSON!, $templateName: String!) {
    changeTemplate(context: $context, templateName: $templateName)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, templateName },
        });

        return res!.changeTemplate;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    confirmToolCall: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      toolCallId: string,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          confirmToolCall: Promise<boolean>;
        }>({
          mutation: gql`
mutation confirmToolCall($context: JSON!, $toolCallId: String!) {
    confirmToolCall(context: $context, toolCallId: $toolCallId)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, toolCallId },
        });

        return res!.confirmToolCall;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    createSession: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      agentName: string,
      extraContext?: ContextValues,
    ): Promise<string> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          createSession: Promise<string>;
        }>({
          mutation: gql`
mutation createSession($context: JSON!, $agentName: String!) {
    createSession(context: $context, agentName: $agentName)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, agentName },
        });

        return res!.createSession;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    promptSession: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          promptSession: Promise<boolean>;
        }>({
          mutation: gql`mutation promptSession($context: JSON!) {
        promptSession(context: $context)
   }`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext } },
        });

        return res!.promptSession;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
    sendMessage: async (
      apolloClient: ApolloClient<any>,
      onError: (error: any) => void,
      context: ContextValues,
      content: string,
      extraContext?: ContextValues,
    ): Promise<boolean> => {
      try {
        const { data: res } = await apolloClient.mutate<{
          sendMessage: Promise<boolean>;
        }>({
          mutation: gql`
mutation sendMessage($context: JSON!, $content: String!) {
    sendMessage(context: $context, content: $content)
}`,
          fetchPolicy: "no-cache",
          variables: { context: { ...context, ...extraContext }, content },
        });

        return res!.sendMessage;
      } catch (error) {
        onError(error);
        throw error;
      }
    },
  },
};
export const useApiClient = makeUseApiClient(APIClient);
