import { Identifiers } from "@project/shared";
import { useEapContext } from "./useEapContext";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import type { State } from "../state";

export const useActiveProject = () => {
  const defaultProject = useSelector(
    (state: State) => state.app.defaultProject,
  );
  const {
    [Identifiers.ProjectId]: projectId,
    [Identifiers.OrganizationId]: organizationId,
  } = useEapContext();

  const isDefaultProject =
    defaultProject &&
    projectId === defaultProject.projectId &&
    organizationId === defaultProject.orgId;

  return {
    projectId,
    organizationId,
    isDefaultProject,
  };
};

export const useScopeAwareNavigate = () => {
  const navigate = useNavigate();
  const { projectId, organizationId, isDefaultProject } = useActiveProject();

  const awareNavigate = useCallback(
    (pathOrDelta: string | number) => {
      if (typeof pathOrDelta === "number") {
        navigate(pathOrDelta);
      } else {
        if (organizationId && projectId && !isDefaultProject) {
          navigate(`/-/${organizationId}/${projectId}${pathOrDelta}`);
        } else {
          navigate(pathOrDelta);
        }
      }
    },
    [navigate, organizationId, projectId, isDefaultProject],
  );
  return awareNavigate;
};
