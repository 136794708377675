import type React from "react";
import { useState } from "react";
import {
  Card,
  Button,
  Typography,
  Space,
  Modal,
  Input,
  message,
  Form,
  Select,
} from "antd";
import { Atoms, Identifiers, EapProjectRole } from "@project/shared";
import { Link } from "react-router-dom";
import { TeamOutlined, CodeOutlined } from "@ant-design/icons";
import { useApiClient } from "@project/api";
import type { State } from "../../state";
import type { UserPublicID } from "@atoms/atom-types";
import { useSelector } from "react-redux";
import { useAtom } from "@project/api";

const { Text, Paragraph } = Typography;

interface ProjectPanelProps {
  organizationId: string;
  projectId: string;
}

export const ProjectPanel: React.FC<ProjectPanelProps> = ({
  organizationId,
  projectId,
}) => {
  const api = useApiClient();
  const myUserId = useSelector(
    (state: State) => state.app.myUserData?.id,
  ) as UserPublicID;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const project = useAtom(Atoms.ProjectInfo, (info) => info, {
    [Identifiers.ProjectId]: projectId,
    [Identifiers.OrganizationId]: organizationId,
  });

  const members = useAtom(
    Atoms.ProjectMembers,
    (members) => Object.keys(members.entries).length,
    {
      [Identifiers.OrganizationId]: organizationId,
      [Identifiers.ProjectId]: projectId,
    },
  );

  const handleInvite = async (values: {
    email: string;
    name: string;
    roles: EapProjectRole[];
  }) => {
    try {
      await api.Project.addUserToByEmail(
        values.email,
        values.name,
        values.roles,
        {
          [Identifiers.ProjectId]: projectId,
        },
      );
      message.success("Benutzer erfolgreich eingeladen");
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error("Fehler beim Einladen des Benutzers");
    }
  };

  if (!project) {
    return null;
  }

  return (
    <Card
      title={project.name}
      extra={
        <Link to={`/-/${organizationId}/${projectId}`}>
          <Button type="primary">Zum Projekt</Button>
        </Link>
      }
      style={{ marginBottom: 16 }}
      hoverable
    >
      <Paragraph>{project.description}</Paragraph>
      <Space direction="vertical">
        <Text>
          <TeamOutlined /> Mitglieder: {members}
        </Text>
        <Text>
          <CodeOutlined /> Code-Schema: {project.codeSchema}
        </Text>
      </Space>
      <Button onClick={() => setIsModalVisible(true)} style={{ marginTop: 16 }}>
        Projektmanager einladen
      </Button>

      <Modal
        title="Benutzer zum Projekt einladen"
        open={isModalVisible}
        onOk={() => form.submit()}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
        okText="Einladen"
        cancelText="Abbrechen"
      >
        <Form form={form} onFinish={handleInvite} layout="vertical">
          <Form.Item
            name="email"
            label="E-Mail"
            rules={[{ required: true, message: "Bitte E-Mail eingeben" }]}
          >
            <Input placeholder="E-Mail-Adresse des Benutzers eingeben" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Bitte Namen eingeben" }]}
          >
            <Input placeholder="Name des Benutzers eingeben" />
          </Form.Item>
          <Form.Item
            name="roles"
            label="Rollen"
            rules={[
              {
                required: true,
                message: "Bitte mindestens eine Rolle auswählen",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Rollen auswählen"
              options={Object.keys(EapProjectRole).map((role) => ({
                label: role,
                value: role,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};
