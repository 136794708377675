import { merge } from "lodash";
import type { ApolloOptions, ApolloOptionsOverride } from "./ApolloConnection";

type RetryOptions = {
  initialDelay: number;
  multiplier: number;
  randomness: number;
  maxDelay: number;
};

let activeSocket: any, timedOut: any;
export function getApolloOptions(
  graphQlUrl = `${location.host}/backend`,
  graphQlSubscriptionUrl = `${location.protocol.replace("http", "ws")}${graphQlUrl}/graphql`,
  credentials = "same-origin",
  apolloOptionsOverride?: ApolloOptionsOverride,
  subscriptionRetryOptions?: Partial<RetryOptions>,
): ApolloOptions {
  //   const { graphQlUrl, graphQlSubscriptionUrl, credentials } = getBackendUrl();

  const defaultOptions: ApolloOptions = {
    client: {
      queryDeduplication: true,
      defaultOptions: {
        mutate: {
          errorPolicy: "all",
        },
        query: {
          errorPolicy: "all",
        },
      },
    },
    link: {
      http: { credentials: credentials, uri: graphQlUrl },
      ws: {
        lazy: true,
        retryAttempts: 10,
        url: graphQlSubscriptionUrl,
        on: {
          connected: (socket: any) => (activeSocket = socket),
          ping: (received) => {
            if (!received) {
              // sent
              timedOut = setTimeout(() => {
                if (activeSocket.readyState === WebSocket.OPEN) {
                  console.debug("closing socket");
                  activeSocket.close(4408, "Request Timeout");
                }
              }, 5_000);
            } // wait 5 seconds for the pong and then close the connection
          },
          pong: (received) => {
            if (received) {
              clearTimeout(timedOut);
            } // pong is received, clear connection close timeout
          },
        },
        keepAlive: 10000,
        retryWait: async (retryNr) => {
          if ((globalThis as any).state?.apollo?.fatalError) {
            console.warn("fatal error, aborting retry");
            await new Promise((resolve) =>
              setTimeout(resolve, Number.MAX_VALUE),
            );
          }

          const options: RetryOptions = {
            initialDelay: 2000,
            multiplier: 2,
            randomness: 2000,
            maxDelay: 20000,
            ...subscriptionRetryOptions,
          };

          const delay =
            retryNr === 0
              ? options.initialDelay
              : Math.min(
                  options.initialDelay * Math.pow(options.multiplier, retryNr) +
                    Math.random() * options.randomness,
                  options.maxDelay + Math.random() * options.randomness,
                );

          await new Promise((resolve) => setTimeout(resolve, delay));
        },
      },
      retry: {
        delay: {
          initial: 500,
          max: 30000,
          jitter: true,
        },
        attempts: {
          max: 5,
          // do not retry Server Errors (e.g. HTTP 400 bad request)
          retryIf: (error, _operation) =>
            Boolean(error) && error.name !== "ServerError",
        },
      },
      timeout: { timeout: 30000 },
    },
  };

  if (apolloOptionsOverride) {
    return merge(defaultOptions, apolloOptionsOverride);
  } else {
    return defaultOptions;
  }
}
