import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Atoms, Identifiers, type IWorkpackage } from "@project/shared";
import moment from "moment";
import { useCallback } from "react";
import { useApiClient } from "@project/api";
import { useEapContext } from "../hooks/useEapContext";
import { generatePdf, renderReportTemplate } from "../util/renderReport";
import { AsyncButton } from "./reusable/AsyncButton";
import { useAtom } from "@project/api";

interface CreateWPReportBtnProps {
  workPackage: IWorkpackage;
}
export const CreateWPReportBtn = ({ workPackage }: CreateWPReportBtnProps) => {
  const api = useApiClient();
  const { [Identifiers.Code]: wpCode } = useEapContext();

  const projectUsers = useAtom(Atoms.ProjectUsers);
  const onClick = useCallback(async () => {
    const wpTemplate = await api.Template.read({
      [Identifiers.TemplateName]: "report_workpackage",
    });
    const actTemplate = await api.Template.read({
      [Identifiers.TemplateName]: "report_activities",
    });

    if (!wpTemplate || !actTemplate) {
      throw new Error("Could not load report templates");
    }

    await generatePdf(
      await renderReportTemplate(
        workPackage,
        wpCode,
        projectUsers!,
        wpTemplate,
        actTemplate,
      ),
      `${wpCode}_${moment().format("YYYYMMDD-HHmm")}.pdf`,
    );
  }, [projectUsers, workPackage]);

  return (
    <AsyncButton
      disabled={!projectUsers}
      onClick={onClick}
      key="download"
      icon={<FontAwesomeIcon icon={faFileAlt} />}
      title="Bericht herunterladen"
    />
  );
};
