import { DefaultIdentifiers, type ContextValues } from "@atoms/atom-types";
import { createContext, useContext, useState, useEffect } from "react";
import { AiSider } from "./AISider";
import { SetId } from "@atoms/atom-client";

export interface AiBackendApi {
  sendMessage: (
    message: string,
    extraContext?: ContextValues,
  ) => Promise<boolean>;
  createSession: (
    agentName: string,
    extraContext?: ContextValues,
  ) => Promise<string>;
  changeTemplate: (
    templateName: string,
    extraContext?: ContextValues,
  ) => Promise<boolean>;
  confirmToolCall: (
    toolCallId: string,
    context?: ContextValues,
  ) => Promise<boolean>;
}

export type AiContextValues = {
  currentSessionId?: string;
  currentTemplate?: string;
  customToolComponents?: Record<string, React.FC<any>>;
  extraContext?: ContextValues;
  setExtraContext: (extraContext: ContextValues | undefined) => void;
  setCurrentTemplate: (template: string | undefined) => void;
  createSession: (agentName: string) => Promise<string>;
} & AiBackendApi;

const AiContext = createContext<AiContextValues | null>(null);

export const useAiApi = () => {
  return useContext(AiContext);
};

export const AiContextProvider = ({
  children,
  backendApi,
  enabled = false,
  customToolComponents,
}: {
  children: React.ReactNode;
  backendApi?: AiBackendApi;
  enabled?: boolean;
  customToolComponents?: Record<string, React.FC<any>>;
}) => {
  const [currentSessionId, setCurrentSessionId] = useState<string>();
  const [currentTemplate, setCurrentTemplate] = useState<string>();
  const [extraContext, setExtraContext] = useState<ContextValues | undefined>();

  if (!backendApi) {
    return <>{children}</>;
  }

  useEffect(() => {
    if (currentTemplate && currentSessionId) {
      backendApi.changeTemplate(currentTemplate, {
        [DefaultIdentifiers.AgentSessionId]: currentSessionId,
      });
    }
  }, [currentTemplate, backendApi, currentSessionId]);

  const createSession = async (agentName: string) => {
    const sessionId = await backendApi.createSession(agentName);
    setCurrentSessionId(sessionId);
    return sessionId;
  };

  if (!enabled) {
    return <>{children}</>;
  }
  console.log("currentSessionId", currentSessionId);
  return (
    <AiContext.Provider
      value={{
        currentSessionId,
        currentTemplate,
        setCurrentTemplate,
        customToolComponents,
        extraContext,
        setExtraContext,
        createSession,
        confirmToolCall: async (toolCallId, extraContext) => {
          return backendApi.confirmToolCall(toolCallId, {
            [DefaultIdentifiers.AgentSessionId]: currentSessionId,
            ...extraContext,
          });
        },
        changeTemplate: async (templateName, extraContext) => {
          return backendApi.changeTemplate(templateName, {
            [DefaultIdentifiers.AgentSessionId]: currentSessionId,
            ...extraContext,
          });
        },
        sendMessage: async (message, evenMoreContext) => {
          return backendApi.sendMessage(message, {
            [DefaultIdentifiers.AgentSessionId]: currentSessionId,
            ...extraContext,
            ...evenMoreContext,
          });
        },
      }}
    >
      <SetId
        identifier={DefaultIdentifiers.AgentSessionId}
        value={currentSessionId}
      >
        <AiSider>{children}</AiSider>
      </SetId>
    </AiContext.Provider>
  );
};
