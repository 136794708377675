import type {
  AtomAccessPermissions,
  AtomData,
  FieldLevelPermission,
  UserPublicID,
} from "@atoms/atom-types";
import { Input } from "antd";
import { createContext, useContext } from "react";
import type { LabelType } from "./RevisionConflictModal";

export const MagicFormContext = createContext<FormContextType | undefined>(
  undefined,
);
export const MagicFormProvider = MagicFormContext.Provider;

export type ChangeSet<AtomDataType extends AtomData = AtomData> =
  Partial<AtomDataType>;

export interface SuggestedChange<AtomDataType extends AtomData = AtomData> {
  source?: string;
  explanation?: string;
  changeSet: ChangeSet<AtomDataType>;
}

export type FormContextType<
  AtomDataType extends AtomData = AtomData,
  PermissionsType extends string = string,
> = {
  currentValue: AtomDataType;
  permissions?: AtomAccessPermissions<AtomDataType, PermissionsType>;
  hasPermission: (perm: PermissionsType | PermissionsType[]) => boolean;
  updateDraft: (draft: Partial<AtomDataType>) => void;
  resetDraft: () => void;
  submit: (ignoreSuggestedChanges?: boolean) => Promise<void>;
  onCancel: () => void;
  myUserId: UserPublicID;
  discardSuggestedChanges: () => void;
  resolveSuggestedChange: (
    field: keyof AtomDataType,
    value: AtomDataType[keyof AtomDataType],
  ) => void;
  getUserName: (userId: UserPublicID) => string;
  atomType: string;
  suggestedChanges?: SuggestedChange<AtomDataType>;
  labels: LabelType<AtomDataType>;
  draft: Readonly<Partial<AtomDataType>> | null;
  valueFromBackend: Readonly<AtomDataType> | null | undefined;
  loading?: boolean;
};

export interface MagicInputProps<AtomType extends AtomData = AtomData> {
  field: keyof AtomType & string;
  label?: string;
  validate?: (state: AtomType) => string | undefined;
}

export const useMayUpdate = <
  AtomType extends AtomData = AtomData,
  PermissionsType extends string = string,
>(
  field: keyof AtomType & string,
): boolean => {
  const { permissions, hasPermission, myUserId, valueFromBackend, loading } =
    useContext(MagicFormContext) as unknown as FormContextType<
      AtomType,
      PermissionsType
    >;

  if (loading) {
    return false;
  }
  if (!permissions) {
    return true;
  }
  const matchingFieldPermission = permissions?.fieldPermissions?.find(
    (permission) => permission.fields.includes(field),
  ) as FieldLevelPermission<keyof AtomType & string, PermissionsType>;

  for (const [dynamicPermission, dynamicPermissionFunction] of Object.entries(
    permissions?.dynamicPermissions ?? {},
  )) {
    if (
      dynamicPermissionFunction(valueFromBackend as AtomType, {
        hasPermission,
        getActingUserOrThrow: () => myUserId,
      })
    ) {
      if (
        matchingFieldPermission &&
        matchingFieldPermission.update?.includes(
          dynamicPermission as PermissionsType,
        )
      ) {
        return true;
      }
    }
  }

  if (!matchingFieldPermission || !matchingFieldPermission.update) {
    return false;
  }
  // todo compare against user permissions
  return hasPermission && hasPermission(matchingFieldPermission.update);
};

export const useMayUpdateAny = <
  AtomType extends AtomData = AtomData,
  PermissionsType extends string = string,
>(
  field: keyof AtomType & string,
): boolean => {
  const { permissions, hasPermission } = useContext(
    MagicFormContext,
  ) as unknown as FormContextType<AtomType, PermissionsType>;

  return (
    permissions?.fieldPermissions?.some((permission) => permission.update) ||
    false
  );
};
export const useLabel = <AtomType extends AtomData = AtomData>(
  field: keyof AtomType & string,
): string => {
  const { labels } = useContext(
    MagicFormContext,
  ) as unknown as FormContextType<AtomType>;
  return (labels[field]?.label ?? field) as string;
};

export const MagicInput = <AtomType extends AtomData = AtomData>({
  field,
  validate,
  label,
}: MagicInputProps<AtomType>) => {
  const { currentValue } = useMagicForm();
  const mayUpdate = useMayUpdate(field);
  return (
    <Input name={field} disabled={!mayUpdate} value={currentValue[field]} />
  );
};

export const useMagicForm = <
  AtomType extends AtomData = AtomData,
  PermissionsType extends string = string,
>() => {
  return useContext(MagicFormContext) as unknown as FormContextType<
    AtomType,
    PermissionsType
  >;
};
