import { Button, Layout } from "antd";
import { useCallback, useEffect, useState } from "react";
import { AiChat } from "./AIChat";
import { Content } from "antd/es/layout/layout";
import { RobotOutlined, CloseOutlined } from "@ant-design/icons";
import { useAiApi } from "./AiContext";
import { AtomNotFoundBoundary } from "@atoms/atom-client";

const { Sider } = Layout;

export const AiSider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const api = useAiApi();
  const sessionId = api?.currentSessionId;

  useEffect(() => {
    if (!sessionId) {
      setCollapsed(true);
    }
  }, [sessionId]);

  const toggleSider = useCallback(() => {
    if (!collapsed) {
      return setCollapsed(true);
    }
    if (!sessionId) {
      api
        ?.createSession(api?.currentTemplate ?? "default")
        .then(() => {
          setCollapsed(false);
        })
        .catch(() => {
          console.error("Failed to create session");
          setCollapsed(true);
        });
    }
  }, [collapsed, sessionId]);

  return (
    <Layout style={{ maxHeight: "100vh", overflow: "hidden" }} key="ai-layout">
      {children}
      <Sider
        key="ai-sider"
        theme="light"
        collapsible
        collapsed={collapsed}
        collapsedWidth={0}
        width={800}
        trigger={null}
        reverseArrow
      >
        {sessionId ? (
          <AtomNotFoundBoundary>
            <AiChat sessionId={sessionId} />
          </AtomNotFoundBoundary>
        ) : (
          <Content>
            <div>No session</div>
          </Content>
        )}
      </Sider>
      <Button
        type="primary"
        shape="circle"
        size="large"
        onClick={toggleSider}
        icon={collapsed ? <RobotOutlined /> : <CloseOutlined />}
        style={{
          position: "fixed",
          right: collapsed ? 24 : 524,
          bottom: 24,
          zIndex: 1000,
          boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
        }}
      />
    </Layout>
  );
};
