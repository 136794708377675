import { setAutoFreeze } from "immer";
import { combineReducers } from "redux";
import { ApolloWsReduxReducer, type ApolloWsState } from "./apollo";
import { AppReduxReducer, type AppState } from "./app";
import { FiltersReduxReducer, type FiltersState } from "./filters";
import { ModalsReduxReducer, type ModalsState } from "./modals";
import {
  type TableSettingsRecord,
  TableSettingsReduxReducer,
  type TableStateRecord,
  TableStateReduxReducer,
} from "./table";
import {
  TodoFiltersReduxReducer,
  type TodoFiltersState,
} from "./todolistfilters";
import { WbsReduxReducer, type WbsState } from "./wbs";

setAutoFreeze(false);

export const rootReducer = combineReducers({
  app: AppReduxReducer,
  filters: FiltersReduxReducer,
  todo_filters: TodoFiltersReduxReducer,
  modal: ModalsReduxReducer,
  table: TableStateReduxReducer,
  tableSettings: TableSettingsReduxReducer,
  wbs: WbsReduxReducer,
  apollo: ApolloWsReduxReducer,
});

// tslint:disable-next-line: no-empty-interface
export interface State {
  app: AppState;
  filters: FiltersState;
  todo_filters: TodoFiltersState;
  modal: ModalsState;
  table: TableStateRecord;
  tableSettings: TableSettingsRecord;
  wbs: WbsState;
  apollo: ApolloWsState;
}

export type GetState = () => State;
