export enum EapProjectRole {
  Guest = "Guest",
  Responsible = "Responsible",
  Commissioner = "Commissioner",
  ProjectManager = "ProjectManager",
  UserManager = "UserManager",
}

export const EapProjectRoleLabel = {
  [EapProjectRole.Guest]: "Gast",
  [EapProjectRole.Responsible]: "Verantwortlicher",
  [EapProjectRole.Commissioner]: "Auftraggeber",
  [EapProjectRole.ProjectManager]: "Projektmanager",
  [EapProjectRole.UserManager]: "Benutzermanager",
};
