import { Atoms } from "@project/shared";
import { useEffect, type ReactNode } from "react";
import { useAtom } from "@project/api";

interface ProjectTreeLoaderProps {
  children: ReactNode;
}

export const ProjectTreeLoader = ({ children }: ProjectTreeLoaderProps) => {
  useAtom(Atoms.ProjectTree);

  return <>{children}</>;
};
