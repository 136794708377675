import { Button, Descriptions, Popover, Tag } from "antd";
import type React from "react";
import type { IToolCall } from "@atoms/atom-types";
import { useAiApi } from "./AiContext";

export const ToolCallBubble: React.FC<{
  toolCall: IToolCall;
}> = ({ toolCall }) => {
  const api = useAiApi();
  const ToolComponent = api?.customToolComponents?.[toolCall.function.name];

  const handleAccept = () => {
    // Execute the tool call
    api?.confirmToolCall(toolCall.id);
    // api?.executeToolCall(toolCall);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        marginTop: "8px",
      }}
    >
      <Tag>{toolCall.function.name}</Tag>
      <Popover
        content={
          ToolComponent ? (
            <ToolComponent toolCall={toolCall} />
          ) : (
            <Descriptions size="small" column={1}>
              {toolCall.function.arguments &&
                Object.entries(JSON.parse(toolCall.function.arguments)).map(
                  ([key, value]) => (
                    <Descriptions.Item key={key} label={key}>
                      {JSON.stringify(value)}
                    </Descriptions.Item>
                  ),
                )}
            </Descriptions>
          )
        }
        title="Parameters"
        trigger="hover"
      >
        <Button type="primary" size="small" onClick={handleAccept}>
          Accept
        </Button>
      </Popover>
    </div>
  );
};
