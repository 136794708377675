import { Identifiers } from "@project/shared";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import type { State } from "../state";
import { DefaultIdentifiers } from "@atoms/atom-types";

export type EapContextValues = {
  [key in Identifiers | DefaultIdentifiers]?: any;
};

export function useEapContext(): EapContextValues {
  const defaultProject = useSelector(
    (state: State) => state.app.defaultProject,
  );
  const { code, templateName, todoListId, organizationId, projectId } =
    useParams<{
      code?: string;
      templateName?: string;
      todoListId?: string;
      organizationId?: string;
      projectId?: string;
    }>();
  return {
    [Identifiers.Code]: code,
    [DefaultIdentifiers.Version]: "latest",
    [Identifiers.TemplateName]: templateName,
    [Identifiers.TodoListId]: todoListId,
    [Identifiers.OrganizationId]: organizationId ?? defaultProject?.orgId,
    [Identifiers.ProjectId]: projectId ?? defaultProject?.projectId,
  };
}
