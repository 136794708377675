import type { UserPublicID } from "@atoms/atom-types";
import { Reducer } from "@feinarbyte/reduxhelper";

export interface TodoFiltersState {
  responsibleUserIds: UserPublicID[] | null;
  involvedUserIds: UserPublicID[] | null;
  active: boolean;
  label: string;
  favoritesOnly: boolean;
}

export const defaultTodoFiltersState: TodoFiltersState = {
  active: false,
  favoritesOnly: false,
  label: "",
  responsibleUserIds: null,
  involvedUserIds: null,
};

class TodoFiltersReducer extends Reducer<TodoFiltersState> {
  constructor() {
    super(
      defaultTodoFiltersState,
      true,
      undefined,
      ["label"],
      "TodoFiltersReducer",
    );
  }

  public resetAllExceptLabel() {
    Object.assign(this.state, {
      ...defaultTodoFiltersState,
      label: this.state.label,
    });
  }
  public resetAll() {
    Object.assign(this.state, defaultTodoFiltersState);
  }
}

const TodoFiltersReducerInstance = new TodoFiltersReducer();
export const TodoFiltersApi = TodoFiltersReducerInstance.getApi();
export const TodoFiltersReduxReducer =
  TodoFiltersReducerInstance.getReduxReducer();
