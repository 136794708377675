import { Button, Checkbox } from "antd";
import "github-markdown-css/github-markdown-light.css";
import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useSelector } from "react-redux";
import rehypeRaw from "rehype-raw";
import type { State } from "../../state";
import { AppApi } from "../../state/app";
import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";
import { ReduxModal } from "./ReduxModal";

export const ChangeLogModalID = "changeLog";
const ChangeLogModal = () => {
  const [changelogContent, setChangelogContent] = useState("");

  const doNotShowChangelog = useSelector(
    (state: State) => state.app.doNotShowChangelog,
  );
  const lastKnownVersion = useSelector(
    (state: State) => state.app.lastKnownVersion ?? null,
  );
  const currentVersion = import.meta.env.FRONTEND_VERSION;

  const dispatch = useThunkDispatch();

  useEffect(() => {
    fetch("/CHANGELOG.md", { headers: { "Cache-Control": "no-cache" } })
      .then((response) => response.text())
      .then((text) => setChangelogContent(text))
      .catch((e) => {
        console.error(e);
        setChangelogContent("Failed to load changelog");
      });
  }, []);

  useEffect(() => {
    if (!doNotShowChangelog && lastKnownVersion !== currentVersion) {
      dispatch(ModalsApi.show(ChangeLogModalID));
    }
  }, [doNotShowChangelog, lastKnownVersion, currentVersion]);

  useEffect(() => {
    dispatch(AppApi.set("lastKnownVersion", currentVersion));
  }, []);

  return (
    <ReduxModal
      id={ChangeLogModalID}
      width={800}
      okText="Close"
      maskClosable
      cancelButtonProps={{ style: { display: "none" } }}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={doNotShowChangelog}
            onChange={(e) =>
              dispatch(AppApi.set("doNotShowChangelog", e.target.checked))
            }
          >
            Changelog bei neuen Versionen nicht mehr anzeigen
          </Checkbox>
          <Button
            onClick={() => dispatch(ModalsApi.hide(ChangeLogModalID))}
            type="primary"
          >
            Schließen
          </Button>
        </div>
      }
      styles={{
        body: {
          maxHeight: "calc(100vh - 333px)",
          overflow: "auto",
        },
      }}
    >
      <ReactMarkdown
        className="markdown-body font-['PT_Sans'] text-sm"
        rehypePlugins={[rehypeRaw] as any}
      >
        {changelogContent}
      </ReactMarkdown>
    </ReduxModal>
  );
};

export default ChangeLogModal;
