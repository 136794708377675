export enum EapPermission {
  CreateProject = "create_project",
  CreateOrganization = "create_organization",
  CreateActivities = "create_activities",
  CreateCategories = "create_categories",
  CreateWorkpackages = "create_workpackages",
  CreateVersion = "create_version",
  CreateDraft = "create_draft",
  CreateTemplate = "create_template",
  CreateTodoList = "create_todolist",
  CreateTodos = "create_todos",
  DeleteActivities = "delete_activities",
  DeleteWorkpackages = "delete_workpackages",
  DeleteTodoList = "delete_todolist",
  DeleteTodos = "delete_todos",
  FreezeWorkpackages = "freeze_workpackages",
  DeleteCategories = "delete_categories",
  DeleteTemplate = "delete_template",
  DeleteDraft = "delete_draft",
  ReadActivities = "read_activities",
  ReadCategories = "read_categories",
  ReadUsers = "read_users",
  ReadWorkpackages = "read_workpackages",
  ReadDrafts = "read_drafts",
  ReadVersion = "read_version",
  ReadTemplate = "read_template",
  ReadTemplateList = "read_templatelist",
  UpdateActivities = "update_activities",
  ReadTodoList = "read_todolist",
  UpdateCategories = "update_categories",
  UpdateWorkpackagesControlinfo = "update_workpackages-controlinfo",
  UpdateWorkpackages = "update_workpackages",
  UpdateWorkpackagesCommissioner = "update_workpackages-commissioner",
  UpdateWorkpackagesMetaInfo = "update_workpackages-metainfo",
  UpdateWorkpackagesResponsible = "update_workpackages-responsible",
  UpdateWorkpackagesInvolved = "update_workpackages-involved",
  UpdateWorkpackagesDone = "update_workpackages-done",
  UpdateWorkpackagesDecision = "update_workpackages-decision",
  UpdateWorkpackagesPlannedStart = "update_workpackages-plannedstart",
  UpdateWorkpackagesPlannedFinish = "update_workpackages-plannedfinish",
  UpdateActivitySort = "update_activitysort",
  UpdateActivitySortIfResponsible = "update_activitysort-ifresponsible",
  UpdateActivitySortIfInvolved = "update_activitysort-ifinvolved",
  UpdateTodoList = "update_todolist",
  UpdateTodos = "update_todos",
  UpdateTemplate = "update_template",
  RoleResponsible = "role_responsible",
  RoleCommissioner = "role_commissioner",
  RoleUserManager = "role_usermanager",
  RoleProjectManager = "role_projectmanager",
  DownloadXlsx = "download_xlsx",
  AdminRestorebackup = "admin_restorebackup",
  UpdateTodoSortIfResponsible = "update_todosort-ifresponsible",
  UpdateTodoSort = "update_todosort",
  ReadOrganization = "read_organization",
  UpdateOrganization = "update_organization",
  ReadProject = "read_project",
  UpdateProject = "update_project",
}
