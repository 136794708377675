import type { EapPermission } from "@project/shared";
import * as Sentry from "@sentry/react";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { AppApi } from "../state/app";
import { useThunkDispatch } from "../useThunkDispatch";
import { useAsyncLayoutEffect } from "./useAsyncEffect";

export const useHandleOidcEvents = () => {
  const dispatch = useThunkDispatch();
  const {
    isAuthenticated,
    activeNavigator,
    isLoading,
    signinRedirect,
    user,
    events,
    signinSilent,
    error,
  } = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // automatically sign-in
  useAsyncLayoutEffect(async () => {
    if (
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading &&
      !hasTriedSignin &&
      !error
    ) {
      try {
        await signinRedirect();
      } catch (e) {
        console.error("Auto Sign-in failed", e);
      } finally {
        setHasTriedSignin(true);
      }
    }

    if (hasTriedSignin && !isAuthenticated) {
      console.error("Auto Sign-in failed");
    }
  }, [
    hasTriedSignin,
    isAuthenticated,
    activeNavigator,
    isLoading,
    signinRedirect,
  ]);

  useEffect(() => {
    if (isAuthenticated && user) {
      dispatch(
        AppApi.set(
          "myUserGroups",
          (user.profile?.["groups"] as string[])?.map((s: string) =>
            s.replace("/", ""),
          ),
        ),
      );
      const resourceAccess = user.profile?.resource_access as
        | {
            backend: { roles: EapPermission[] };
          }
        | undefined;
      const permissions = resourceAccess?.["backend"]?.roles;
      if (permissions) {
        dispatch(
          AppApi.set("myUserPermissions", permissions as EapPermission[]),
        );
      }
      dispatch(
        AppApi.set("myUserData", {
          name: user.profile?.["name"] ?? "",
          email: user.profile?.["email"] ?? "",
          id: user.profile?.sub ?? "",
        }),
      );
      Sentry.setUser({
        id: user.profile.sub ?? "",
        email: user.profile.email ?? "",
        username: user.profile.name ?? "",
      });
    }
  }, [isAuthenticated, user, dispatch]);

  useEffect(() => {
    const removeUserSignedOut = events.addUserSignedOut(() => {
      Sentry.setUser(null);
    });
    const removeAccessTokenExpiring = events.addAccessTokenExpiring(
      async () => {
        try {
          await signinSilent();
        } catch (e) {
          console.error("access token renew failed", e);
        }
      },
    );
    const removeAccessTokenExpired = events.addAccessTokenExpired(() => {
      console.debug("access token expired");
    });

    return () => {
      removeUserSignedOut();
      removeAccessTokenExpiring();
      removeAccessTokenExpired();
    };
  }, [events, signinSilent]);

  useEffect(() => {
    if (error) {
      notification.error({
        placement: "top",
        key: "keycloak-error",
        message: "Authentifizierungsfehler",
        description: <i>{error.message}</i>,
        duration: 0,
      });
      console.error("oidc error", error);
    }
  }, [error]);
};
