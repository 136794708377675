import type { UserPublicID } from "@atoms/atom-types";
import { useAuth } from "oidc-react";
import { useSelector } from "react-redux";
import type { State } from "../state";

export function useMyUserId(): UserPublicID | null {
  const myUserId = useSelector((state: State) => state.app.myUserData?.id);
  if (myUserId) {
    return myUserId as UserPublicID;
  }
  const r = useAuth();
  const [metaData] =
    (r.userData?.profile["urn:zitadel:iam:user:metadata"] as any[]) ?? [];
  return metaData
    ? (atob(metaData.publicId) as UserPublicID)
    : "user_public_id_placeholder";
}
