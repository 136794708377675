import type { IProjectTreeElement, ITodoList } from "@project/shared";
import {
  Atoms,
  Identifiers,
  type IActivity,
  type IWorkpackage,
  type IWorkpackageCompact,
} from "@project/shared";
import { filter, maxBy, orderBy } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { isWorkpackage } from "./isWorkpackage";
import { useAtom } from "@project/api";

export function useWorkPackages(
  version?: string,
): Array<IProjectTreeElement<IWorkpackageCompact>> | null | undefined {
  // const workPackages = useThunkPolling(getWorkpackages());
  if (!version) {
    return useAtom(Atoms.ProjectTree, (tree) =>
      tree.entries.filter(
        (e): e is IProjectTreeElement<IWorkpackageCompact> =>
          isWorkpackage(e) && e.deleted === false,
      ),
    );
  }
  return useAtom(
    Atoms.ProjectTreeSnapshot,
    (tree) =>
      tree.entries.filter(
        (e): e is IProjectTreeElement<IWorkpackageCompact> =>
          isWorkpackage(e) && e.deleted === false,
      ),
    {
      [DefaultIdentifiers.Version]: version,
    },
  );
}

export function useWorkPackage(
  code: string,
  onlyLatest: true,
): IWorkpackageCompact | undefined;
export function useWorkPackage(
  code: string,
  onlyLatest: false,
): Array<IWorkpackageCompact> | undefined;
export function useWorkPackage(
  code: string,
  onlyLatest = false,
): IWorkpackageCompact | Array<IWorkpackageCompact> | undefined {
  // const workPackages = useThunkPolling(getWorkpackages());
  const workPackages =
    useAtom(
      Atoms.ProjectTree,
      (tree) =>
        filter(
          tree.entries,
          (e) => isWorkpackage(e) && (code === undefined || e.code === code),
        ) as IWorkpackageCompact[],
    ) ?? [];

  const latestWorkPackage = useMemo(() => {
    return orderBy(workPackages, "id", "desc")[0];
  }, [workPackages]);

  return onlyLatest ? latestWorkPackage : workPackages;
}

interface ActivityStats {
  finishedActivities: number;
  lastActivityFinishedAt: Date | null;
  allActivities: number;
  combinedProgress: number;
}

export function computeActivityStats(
  workPackage: IWorkpackageCompact,
  activitiesOverride: IActivity[],
): ActivityStats;
export function computeActivityStats(
  workPackage: IWorkpackage,
  activitiesOverride?: IActivity[],
): ActivityStats;
export function computeActivityStats(
  workPackage: IWorkpackage | IWorkpackageCompact,
  activitiesOverride?: IActivity[],
): ActivityStats {
  const activities =
    activitiesOverride ??
    Object.values((workPackage as IWorkpackage).activities ?? {});
  const finishedActivities =
    activities?.filter((x) => x.currentFinish).length ?? 0;
  const allActivities = activities?.length ?? 0;

  const lastActivityFinishedAt =
    maxBy(
      activities,
      (a) => (a.expectedFinish && Number(a.expectedFinish)) || 0,
    )?.expectedFinish ?? null;
  let combinedProgress = workPackage.progress;
  if (allActivities > 0) {
    combinedProgress = Math.round(
      (workPackage.progress ?? 0) / 2 +
        (50 * finishedActivities) / allActivities,
    );
  }
  return {
    finishedActivities,
    lastActivityFinishedAt,
    allActivities,
    combinedProgress,
  };
}
export function computeTodoStats(todoList: ITodoList) {
  const todos = Object.values(todoList.todos ?? {});
  const finishedTodos = todos?.filter((x) => x.done).length ?? 0;
  const allTodos = todos?.length ?? 0;

  let combinedProgress = 0;
  if (allTodos > 0) {
    combinedProgress = (finishedTodos / allTodos) * 100;
  }
  return {
    finishedTodos,
    allTodos,
    combinedProgress,
  };
}

export function computeTimedProgress(
  workPackage: IWorkpackageCompact | IWorkpackage,
) {
  if (!workPackage?.plannedFinish || !workPackage.plannedStart) {
    return 0;
  }

  const daysToFinish = moment(workPackage.plannedFinish)
    .startOf("day")
    .diff(moment(new Date()).startOf("day"), "days");
  const daysToStart = moment(workPackage.plannedStart)
    .startOf("day")
    .diff(moment(new Date()).startOf("day"), "days");
  const daysFromStartToEnd = moment(workPackage.plannedFinish)
    .startOf("day")
    .diff(moment(workPackage.plannedStart).startOf("day"), "days");

  if (daysToFinish <= 0) {
    return 100;
  } else if (daysToStart >= 0) {
    return 0;
  } else {
    return Math.floor(100 * (-daysToStart / daysFromStartToEnd));
  }
}
