import { ErrorBoundary } from "react-error-boundary";
import { AtomNotFoundError } from "./makeUseAtom";

export const AtomNotFoundBoundary = ({
  children,
  fallback,
}: { children: React.ReactNode; fallback?: React.ReactElement }) => {
  return (
    <ErrorBoundary
      fallback={
        fallback || (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              padding: "2rem",
            }}
          >
            <h1 style={{ fontSize: "4rem", margin: 0 }}>404</h1>
            <p style={{ fontSize: "1.5rem", color: "#666" }}>Atom not found</p>
          </div>
        )
      }
      onError={(error) => {
        console.error(error);
        if (!(error instanceof AtomNotFoundError)) {
          throw error;
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
