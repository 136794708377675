import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { useApiClient, useAtom } from "@project/api";
import { Atoms, Identifiers } from "@project/shared";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Modal,
  Space,
  Tabs,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import type React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetTitle } from "../../hooks/useSetTitle";
import { ErrorBoundary } from "../ErrorBoundary";
import { LicenseIndicator } from "./LicenseIndicator";
import { LicenseManager } from "./LicenseManager";
import { OrganizationInfoEditor } from "./OrganizationInfoEditor";
import { OrganizationUserManager } from "./OrganizationUserManager";
import { ProjectPanel } from "./ProjectPanel";
const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

export const OrganizationAdmin: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const api = useApiClient();
  const navigate = useNavigate();

  useSetTitle(`Organisationsverwaltung: ${organizationId}`);

  const projects =
    useAtom(Atoms.ProjectList, (info) => info.entries, {
      [Identifiers.OrganizationId]: organizationId,
    }) ?? {};

  const handleCreateProject = async (values: {
    projectId: string;
    name: string;
    description: string;
    codeSchema: string;
  }) => {
    if (projects[values.projectId]) {
      message.error("Projekt-ID existiert bereits!");
      return;
    }
    try {
      await api.Organization.createProject(values, {
        [Identifiers.OrganizationId]: organizationId,
        [Identifiers.ProjectId]: values.projectId,
      });
      setIsModalVisible(false);
      form.resetFields();
      message.success("Projekt erfolgreich erstellt!");
    } catch (error) {
      message.error(
        "Fehler beim Erstellen des Projekts. Bitte versuchen Sie es erneut.",
      );
    }
  };

  return (
    <Content style={{ padding: "24px" }}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Button icon={<ArrowLeftOutlined />} onClick={() => navigate("/admin")}>
          Zurück zur Verwaltung
        </Button>

        <Card>
          <Title level={2}>Organisation: {organizationId}</Title>
          <LicenseIndicator />
          <Tabs defaultActiveKey="1">
            <TabPane tab="Benutzerverwaltung" key="1">
              <ErrorBoundary key="/admin/:organizationId/users">
                <OrganizationUserManager />
              </ErrorBoundary>
            </TabPane>
            <TabPane tab="Lizenzverwaltung" key="2">
              <LicenseManager />
            </TabPane>
            <TabPane tab="Organisationsinfo" key="3">
              <OrganizationInfoEditor />
            </TabPane>
            <TabPane tab="Projektverwaltung" key="4">
              <Paragraph>
                Hier können Sie neue Projekte erstellen und bestehende
                verwalten.
              </Paragraph>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                Neues Projekt erstellen
              </Button>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: "100%", marginTop: "20px" }}
              >
                {Object.entries(projects).map(([projectId, project]) => (
                  <ProjectPanel
                    key={projectId}
                    organizationId={organizationId!}
                    projectId={projectId}
                  />
                ))}
              </Space>
            </TabPane>
          </Tabs>
        </Card>

        <Modal
          title="Projekt erstellen"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form form={form} onFinish={handleCreateProject} layout="vertical">
            <Form.Item
              name="projectId"
              label="Projekt-ID"
              rules={[
                { required: true, message: "Projekt-ID ist erforderlich" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="Projektname"
              rules={[
                { required: true, message: "Projektname ist erforderlich" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Projektbeschreibung">
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name="codeSchema"
              label="Code-Schema"
              rules={[
                { required: true, message: "Code-Schema ist erforderlich" },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Projekt erstellen
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Space>
    </Content>
  );
};
