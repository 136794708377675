import {
  faArrowUpRightFromSquare,
  faArrowsAltH,
  faArrowsAltV,
  faFileMedical,
  faFilter,
  faProjectDiagram,
  faTrash,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DraftItemType,
  Atoms,
  EapPermission,
  getDraftOperation,
} from "@project/shared";
import { Alert, Button, Popover } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { useSelector } from "react-redux";
import { UIElement } from "../../UIElements";
import { useApiClient } from "@project/api";
import { useHasPermission } from "../../hooks/useHasPermission";
import { useScopeAwareNavigate } from "../../hooks/useScopeAwareNavigate";
import type { State } from "../../state";
import { AppApi, VERSION_DRAFT } from "../../state/app";
import { ModalsApi } from "../../state/modals";
import { WbsApi } from "../../state/wbs";
import {
  ChildAlignment,
  ElementType,
  type WBSTreeElement,
} from "../../types/WBSTreeTypes";
import { useThunkDispatch } from "../../useThunkDispatch";
import { CreateAddDraftModalId } from "../modals/CreateAddDraftModal";
import { AsyncButton } from "../reusable/AsyncButton";
import { useFullscreenContext } from "./FullscreenContext";
import type { WBSMenuProps } from "./WBSContextMenu";
import { useAtom } from "@project/api";

export const WBSHoverMenu = ({ element, children }: WBSMenuProps) => {
  const flippedIndex = useSelector((state: State) =>
    state.wbs.flippedCodes.indexOf(element.code),
  );
  const collapsedIndex = useSelector((state: State) =>
    state.wbs.collapsedCodes.indexOf(element.code),
  );
  const versionComparisonMode = useSelector(
    (state: State) => state.app.versionToCompare !== undefined,
  );
  const api = useApiClient();
  const navigate = useScopeAwareNavigate();
  const dispatch = useThunkDispatch();
  const hasPermission = useHasPermission();
  const selectedVersion = useSelector(
    (state: State) => state.app.activeVersion,
  );
  const isDraftVersion = selectedVersion === VERSION_DRAFT;
  const isDraft = element.draft !== undefined;
  const drafts = useAtom(Atoms.Draft, (d) => d.entries);

  const dropDownRef = useFullscreenContext();

  if (isDraft && versionComparisonMode && element.children.length < 2) {
    return <>{children}</>;
  }

  return (
    <>
      <Popover
        getPopupContainer={
          dropDownRef.current !== null ? () => dropDownRef.current! : undefined
        }
        placement="right"
        content={
          <div
            className={`pw_wbs_hover_menu_${element.code.replace(".", "_")}`}
          >
            {!isDraftVersion && !versionComparisonMode ? (
              <Alert
                type="warning"
                style={{ marginBottom: 8, fontSize: 14, textAlign: "center" }}
                message={
                  <>
                    Zum Bearbeiten, bitte in den
                    <br />
                    <Button
                      type="text"
                      onClick={() =>
                        dispatch(AppApi.set("activeVersion", VERSION_DRAFT))
                      }
                    >
                      Entwufsmodus wechseln.
                    </Button>
                  </>
                }
              />
            ) : null}
            {!isDraft ? (
              <Button
                shape="circle"
                key="goto"
                className={UIElement.WBS_HoverMenu_OpenWorkPackageButton}
                icon={
                  <FontAwesomeIcon
                    icon={
                      element.type === ElementType.Category
                        ? faFilter
                        : faArrowUpRightFromSquare
                    }
                  />
                }
                title={
                  element.type === ElementType.Category
                    ? "Kategorie filtern"
                    : "Arbeitspaket öffnen"
                }
                onClick={() => {
                  if (element.type === ElementType.Category) {
                    navigate(`/wbs/${element.code}`);
                  } else {
                    navigate(`/p/${element.code}`);
                  }
                }}
              />
            ) : null}
            {!versionComparisonMode &&
            element.type !== ElementType.Package &&
            !(
              element.draft !== undefined &&
              getDraftOperation(element.draft) === "Delete"
            ) &&
            hasPermission(EapPermission.CreateDraft) ? (
              <ButtonGroup className="ml-2">
                <AsyncButton
                  disabled={!isDraftVersion}
                  className={UIElement.WBS_HoverMenu_CreateCategoryButton}
                  shape="circle"
                  key="create_category"
                  icon={<FontAwesomeIcon icon={faProjectDiagram} />}
                  title="Neues WBS-Element"
                  onClick={() => api.Project.addCategoryDraft(element.code)}
                  onSuccess={(code) => {
                    dispatch(WbsApi.setEdit(code, "label"));
                  }}
                  type="primary"
                />
                <AsyncButton
                  disabled={!isDraftVersion}
                  shape="circle"
                  key="create_workpackage"
                  className={UIElement.WBS_HoverMenu_CreateWorkPackageButton}
                  icon={<FontAwesomeIcon icon={faFileMedical} />}
                  title="Neues Arbeitspaket"
                  onClick={() => {
                    dispatch(
                      WbsApi.set("draft", {
                        parent: element.code,
                        type: DraftItemType.AddWorkpackage,
                      }),
                    );
                    dispatch(ModalsApi.show(CreateAddDraftModalId));
                  }}
                  onSuccess={(code) => {
                    dispatch(WbsApi.setEdit(code, "label"));
                  }}
                  type="primary"
                />
              </ButtonGroup>
            ) : null}
            {collapsedIndex === -1 && element.children.length > 1 ? (
              <Button
                shape="circle"
                key="flip"
                icon={
                  <FontAwesomeIcon
                    icon={
                      element.childAlignment === ChildAlignment.Horizontal
                        ? faArrowsAltV
                        : faArrowsAltH
                    }
                  />
                }
                title="Ausrichtung ändern"
                onClick={() => {
                  if (flippedIndex === -1) {
                    dispatch(WbsApi.add("flippedCodes", element.code));
                  } else {
                    dispatch(WbsApi.remove("flippedCodes", flippedIndex));
                  }
                }}
                className="ml-2"
              />
            ) : null}
            {!versionComparisonMode ? (
              element.draft !== undefined ? (
                hasPermission(EapPermission.DeleteDraft) &&
                !element.computedDraft ? (
                  <AsyncButton
                    shape="circle"
                    disabled={!isDraftVersion}
                    key="delete_draft"
                    icon={<FontAwesomeIcon icon={faUndo} />}
                    title="Änderung verwerfen"
                    onClick={() =>
                      api.Project.removeDraft({ code: element.code })
                    }
                    danger
                    className={`ml-2 ${UIElement.WBS_HoverMenu_RemoveButton}`}
                  />
                ) : null
              ) : hasPermission(EapPermission.CreateDraft) ? (
                <AsyncButton
                  shape="circle"
                  key="delete"
                  disabled={!isDraftVersion}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  title="Löschen"
                  onClick={() => {
                    if (
                      drafts?.find(
                        (d) =>
                          d.code.startsWith(element.code) &&
                          getDraftOperation(d.type) === "Add",
                      )
                    ) {
                      dispatch(
                        AppApi.set("deletionDraft", {
                          code: element.code,
                          label: element.label ?? "",
                          type: element.type,
                        }),
                      );
                      dispatch(ModalsApi.show("deleteDraftWithChildren"));
                      return;
                    } else {
                      return api.Project.pushDraft({
                        code: element.code,
                        type:
                          element.type === ElementType.Category
                            ? DraftItemType.DeleteCategory
                            : DraftItemType.DeleteWorkpackage,
                        label: element.label,
                      });
                    }
                  }}
                  danger
                  className={`ml-2 ${UIElement.WBS_HoverMenu_RemoveButton}`}
                />
              ) : null
            ) : null}
          </div>
        }
        overlayInnerStyle={{
          backgroundColor: "#ffffffa6",
          padding: "8px",
          backdropFilter: "blur(4px)",
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export function findCode(element: WBSTreeElement): string {
  const factor = element.code.split(".").length > 3 ? 10 : 1;
  for (let i = 1; i < 20; i++) {
    const possibleCode = element.code + "." + i * factor;
    if (element.children.find((e) => e.code === possibleCode) === undefined) {
      return possibleCode;
    }
  }
  throw new Error("Could not find code");
}
