import type { UserPublicID } from "@atoms/atom-types";
import { Reducer } from "@feinarbyte/reduxhelper";

export interface FiltersState {
  attentionRequiredOnly: boolean;
  checked: string[];
  responsibleUserIds: UserPublicID[] | null;
  commissionerUserIds: UserPublicID[] | null;
  involvedUserIds: UserPublicID[] | null;
  active: boolean;
  label: string;
  dueWithin40DaysOnly: boolean;
  favoritesOnly: boolean;
  endDate: Date | null;
  startDate: { beginning: Date | null; end: Date | null } | null;
}

export const defaultFiltersState: FiltersState = {
  attentionRequiredOnly: false,
  checked: [],
  active: false,
  favoritesOnly: false,
  label: "",
  responsibleUserIds: null,
  commissionerUserIds: null,
  involvedUserIds: null,
  dueWithin40DaysOnly: false,
  endDate: null,
  startDate: null,
};

class FiltersReducer extends Reducer<FiltersState> {
  constructor() {
    super(defaultFiltersState, true, undefined, ["label"], "FiltersReducer");
  }

  public resetAllExceptLabel() {
    Object.assign(this.state, {
      ...defaultFiltersState,
      label: this.state.label,
    });
  }
  public resetAll() {
    Object.assign(this.state, defaultFiltersState);
  }
}

const FiltersReducerInstance = new FiltersReducer();
export const FiltersApi = FiltersReducerInstance.getApi();
export const FiltersReduxReducer = FiltersReducerInstance.getReduxReducer();
