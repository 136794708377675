import { Content } from "antd/es/layout/layout";
import { Atoms, Identifiers } from "@project/shared";
import { DefaultIdentifiers } from "@atoms/atom-types";
import { useSelector } from "react-redux";
import type { State } from "../state";
import { ProjectInvitePanel } from "./ProjectInvitePanel";
import { ProjectPreview } from "./ProjectPreview";
import { Card, Row, Col, Typography, Empty, Button } from "antd";
import { ErrorBoundary } from "./ErrorBoundary";
import { PlusOutlined } from "@ant-design/icons";
import { useAtom } from "@project/api";

const { Title, Paragraph } = Typography;

export const MyProjects = () => {
  const myUserId = useSelector((state: State) => state.app.myUserData?.id);
  const myEmail = useSelector((state: State) => state.app.myUserData?.email);

  const projectIds =
    useAtom(
      Atoms.UserRoles,
      (userRoles) =>
        Object.entries(userRoles.orgs).flatMap(([organizationId, org]) =>
          Object.keys(org.projects).map((projectId) => ({
            projectId,
            organizationId,
          })),
        ),
      { [DefaultIdentifiers.UserId]: myUserId },
    ) ?? [];

  return (
    <Content style={{ padding: "24px" }}>
      <Title level={2}>Willkommen in Ihrem Projekt-Dashboard</Title>
      <Paragraph>
        Hier können Sie alle Ihre Projekte und Einladungen einsehen und
        verwalten.
      </Paragraph>
      <Row gutter={[16, 16]}>
        {projectIds.length === 0 ? (
          <Col span={24}>
            <Card>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    Sie haben derzeit keine Projekte oder Einladungen.
                  </span>
                }
              />
            </Card>
          </Col>
        ) : (
          <>
            {projectIds?.map(({ projectId, organizationId }) => (
              <Col key={projectId} xs={24} sm={12} md={8} lg={6}>
                <ErrorBoundary key={projectId}>
                  <ProjectPreview
                    projectId={projectId}
                    organizationId={organizationId}
                  />
                </ErrorBoundary>
              </Col>
            ))}
          </>
        )}
      </Row>
    </Content>
  );
};
