import type { AtomData } from "@atoms/atom-types";
import { Alert, Modal } from "antd";
import Form from "antd/es/form";
import Col from "antd/es/grid/col";
import Row from "antd/es/row";
import Paragraph from "antd/es/typography/Paragraph";
import { StringDiff } from "react-string-diff";

import { type ReactNode, useContext } from "react";
import { type FormContextType, MagicFormContext } from "./MagicForm";

export type LabelType<T> = Record<
  keyof T,
  { label: string; renderer?: (value: any) => ReactNode }
>;

export const RevisionConflictModal = <T extends AtomData = AtomData>() => {
  const { submit, labels, draft, suggestedChanges, discardSuggestedChanges } =
    useContext(MagicFormContext) as unknown as FormContextType<T>;

  if (!draft || !suggestedChanges) {
    return null;
  }
  console.log(suggestedChanges);

  return (
    <Modal
      width={"800px"}
      title="Änderungen überprüfen"
      open={Boolean(suggestedChanges)}
      onOk={() => {
        // ignore suggested changes
        submit(true);
      }}
      onCancel={() => {
        // calling this without a lambda does not work somehow..
        discardSuggestedChanges();
      }}
      okButtonProps={{ danger: true }}
      okText="Speichern"
    >
      <Alert
        message="Während das Formular bearbeitet wurde hat ein anderer Benutzer eine neue Version gespeichert. Falls Sie das gleiche Feld bearbeitet haben, werden die Änderungen des anderen Benutzers überschrieben!"
        type="warning"
      />

      <Form className="mt-4">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <h3 className="mb-2">Neue Version</h3>
            {Object.entries(suggestedChanges?.changeSet ?? {}).map(
              ([fieldName, suggestedValue]) => {
                const labelKey = fieldName;
                const label = labels[labelKey] as LabelType<T>[keyof T &
                  string];
                if (!label) {
                  return null;
                }
                return (
                  <div key={fieldName}>
                    <strong>{label.label}</strong>:
                    <Paragraph
                      ellipsis={{ expandable: true, rows: 2, symbol: "mehr" }}
                    >
                      {suggestedValue !== null && suggestedValue !== undefined
                        ? label.renderer
                          ? label.renderer(suggestedValue)
                          : suggestedValue.toString()
                        : null}
                    </Paragraph>
                  </div>
                );
              },
            )}
          </Col>
          {Object.keys(draft ?? {}).length > 0 ? (
            <Col span={12}>
              <h3 className="mb-2">Lokale Änderung</h3>
              {Object.entries(draft ?? {}).map(([key, value]) => {
                const labelKey = key as keyof T & string;
                const label = labels[labelKey] as LabelType<T>[keyof T &
                  string];
                if (!label) {
                  return null;
                }
                return (
                  <div key={key}>
                    <strong>{label.label}</strong>:
                    <Paragraph
                      ellipsis={{ expandable: true, rows: 2, symbol: "mehr" }}
                    >
                      {value !== null ? (
                        label.renderer ? (
                          label.renderer(value)
                        ) : typeof value === "string" &&
                          draft[key as keyof T & string] !== undefined ? (
                          <StringDiff
                            oldValue={draft[key as keyof T & string] ?? ""}
                            newValue={value}
                          />
                        ) : (
                          value.toString()
                        )
                      ) : null}
                    </Paragraph>
                  </div>
                );
              })}
            </Col>
          ) : null}
        </Row>
      </Form>
    </Modal>
  );
};
