import type { ContextValues } from "@atoms/atom-types";
import { useContext } from "react";
import { AtomContext, ExtraContext } from "@atoms/atom-client";

export function makeUseApiClient<API extends object>(APIClient: API) {
  return function useApiClient(
    extraContext: ContextValues = {},
  ): MapResolvers<API> {
    const atomStore = useContext(AtomContext);
    if (!atomStore) {
      throw new Error("atomStore not initialized");
    }
    const { getContextHook, onError } = atomStore;
    const extraContext2 = useContext(ExtraContext) ?? {};
    const apolloClient = atomStore.getApolloClient();
    if (!apolloClient) {
      throw new Error("apollo client not initialized");
    }
    const context = getContextHook();
    if (!apolloClient) {
      throw new Error("apollo client not initialized");
    }
    return new Proxy(APIClient, {
      get: (target, prop): MapResolvers<API> => {
        if (!(prop in target)) {
          return new Proxy(
            {},
            {
              get: (_, prop2) => {
                throw new Error(
                  `${String(prop)}#${String(prop2)} not found in API`,
                );
              },
            },
          ) as any;
        }
        return new Proxy((target as any)[prop], {
          get: (target2, prop): MapResolvers<API> => {
            return new Proxy(target2[prop], {
              apply: (target3, thisArg, args): MapResolvers<API> => {
                return target3.apply(thisArg, [
                  apolloClient,
                  onError,
                  { ...context, ...extraContext2, ...extraContext },
                  ...args,
                ]);
              },
            });
          },
        });
      },
    }) as unknown as MapResolvers<API>;
  };
}

export type MapFunctions<T> = {
  [K in keyof T]: FunctionWithoutFirstThreeArgs<T[K]>;
};
export type FunctionWithoutFirstThreeArgs<T> = T extends (
  a: any,
  b: any,
  c: any,
  ...args: infer U
) => infer R
  ? (...args: U) => R
  : never;
export type MapResolvers<T> = { [P in keyof T]: MapFunctions<T[P]> };
