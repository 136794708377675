import { Content } from "antd/es/layout/layout";
import { Atoms, Identifiers } from "@project/shared";
import { DefaultIdentifiers } from "@atoms/atom-types";
import { useSelector } from "react-redux";
import type { State } from "../state";
import { Card, Row, Col, Typography, Empty, Button } from "antd";
import { ErrorBoundary } from "./ErrorBoundary";
import { PlusOutlined } from "@ant-design/icons";
import { OrganizationPreview } from "./Admin/OrganizationPreview";
import { OrganizationInvitePanel } from "./OrganizationInvitePanel";
import { useAtom } from "@project/api";

const { Title, Paragraph } = Typography;

export const MyOrganizations = () => {
  const myUserId = useSelector((state: State) => state.app.myUserData?.id);

  const organizationIds =
    useAtom(
      Atoms.UserRoles,
      (userRoles) =>
        Object.entries(userRoles.orgs)
          .filter(([_, { roles }]) => roles.length > 0)
          .map(([id, _]) => id),
      {
        [DefaultIdentifiers.UserId]: myUserId,
      },
    ) ?? [];

  return (
    <Content style={{ padding: "24px" }}>
      <Title level={2}>Willkommen in Ihrem Organisations-Dashboard</Title>
      <Paragraph>
        Hier können Sie alle Ihre Organisationen und Einladungen einsehen und
        verwalten.
      </Paragraph>
      <Row gutter={[16, 16]}>
        {organizationIds.length === 0 ? (
          <Col span={24}>
            <Card>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    Sie haben derzeit keine Organisationen oder Einladungen.
                  </span>
                }
              >
                <Button type="primary" icon={<PlusOutlined />}>
                  Neue Organisation erstellen
                </Button>
              </Empty>
            </Card>
          </Col>
        ) : (
          <>
            {organizationIds?.map((organizationId) => (
              <Col key={organizationId} xs={24} sm={12} md={8} lg={6}>
                <ErrorBoundary key={organizationId}>
                  <OrganizationPreview organizationId={organizationId} />
                </ErrorBoundary>
              </Col>
            ))}
          </>
        )}
      </Row>
    </Content>
  );
};
