import type { AtomData } from "@atoms/atom-types";
import { Select, Tag } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import { type ReactNode, useContext } from "react";
import { Link } from "react-router-dom";
import { FloatLabel } from "./FloatLabel";
import { MagicFormContext, useLabel, useMayUpdate } from "./MagicForm";

export const MagicAutoComplete = <AtomType extends AtomData = AtomData>({
  field,
  options,
}: {
  field: keyof AtomType & string;
  options: { key: string; value: string; label?: ReactNode }[];
}) => {
  const { updateDraft, currentValue, loading } = useContext(MagicFormContext)!;
  const mayUpdate = useMayUpdate(field);
  const label = useLabel(field);

  return loading ? (
    <SkeletonInput active style={{ height: 48 }} block />
  ) : (
    <FloatLabel
      key={`${field}_label`}
      label={label}
      value={
        currentValue[field] && currentValue[field]?.length > 0
          ? currentValue[field]?.join()
          : undefined
      }
      className="unprintable-display-none"
    >
      <Select
        key={`${field}_input`}
        mode="multiple"
        style={{ width: "100%", overflow: "hidden" }}
        allowClear={mayUpdate}
        value={currentValue[field] ?? []}
        disabled={!mayUpdate}
        onSelect={(value) => {
          if (value !== undefined && value !== null) {
            const newValues = currentValue[field]
              ? [...currentValue[field], value.toString()]
              : [value.toString()];

            updateDraft({
              [field]: newValues,
            });
          }
        }}
        onDeselect={(value) => {
          if (value !== undefined && value !== null) {
            const newValues =
              currentValue[field]?.filter((code: any) => code !== value) ?? [];

            updateDraft({
              [field]: newValues,
            });
          }
        }}
        onClear={() =>
          updateDraft({
            [field]: [],
          })
        }
        tagRender={({ label, value, closable, onClose }) => (
          <Tag
            key={`${value}_${label}`}
            onMouseDown={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            }}
            closable={closable}
            onClose={onClose}
            style={{
              marginRight: 3,
              display: "inline-flex",
              maxWidth: "100%",
            }}
          >
            <span
              style={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Link to={`/p/${value}`}>{label}</Link>
            </span>
          </Tag>
        )}
      >
        {options?.map(({ key, value }) => (
          <Select.Option key={`${key}_${key}`} value={key}>
            <strong>{key}</strong> - {value}
          </Select.Option>
        ))}
      </Select>
    </FloatLabel>
  );
};
