import {
  Identifiers,
  type IProjectUser,
  type IWorkpackage,
} from "@project/shared";
import moment from "moment";
import type { ReactElement } from "react";
import type { APClient } from "@project/api";
import type { FilteredPackages } from "../hooks/useFilteredPackages";
import { generatePdf, renderReportTemplate } from "../util/renderReport";

export const renderReports = async (
  api: APClient,
  filteredPackages: FilteredPackages | undefined,
  projectUsers: IProjectUser[],
  onProgress: (percent: number, step: ReactElement) => void,
  signal?: AbortSignal,
) => {
  if (signal && signal.aborted) return;

  const wpTemplate = await api.Template.read({
    [Identifiers.TemplateName]: "report_workpackage",
  });
  const actTemplate = await api.Template.read({
    [Identifiers.TemplateName]: "report_activities",
  });

  if ((filteredPackages?.length ?? 0) === 0 || !wpTemplate || !actTemplate) {
    throw new Error("Unable to render report, no packages or templates found");
  }

  const reports: Blob[] = [];
  for (const [i, p] of filteredPackages!.entries()) {
    if (signal && signal.aborted) return;

    const activities = await api.Workpackage.readActivities({
      [Identifiers.Code]: p.code,
    });
    reports.push(
      ...(await renderReportTemplate(
        { ...(p as unknown as IWorkpackage), activities },
        p.code,
        { entries: projectUsers },
        wpTemplate,
        actTemplate,
      )),
    );
    onProgress(
      Math.round(((i + 1) / filteredPackages!.length) * 100),
      <span key={p.code}>
        Erstelle Bericht für Arbeitspaket <code>{p.code}</code>
      </span>,
    );
  }

  await generatePdf(reports, `report_${moment().format("YYYYMMDD-HHmm")}.pdf`);
};
