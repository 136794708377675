import {
  Atoms,
  Identifiers,
  OrganizationPermissionsByRole,
  ProjectPermissionsByRole,
  type EapPermission,
} from "@project/shared";
import { intersection } from "lodash";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import type { State } from "../state";
import { useEapContext } from "./useEapContext";
import { DefaultIdentifiers } from "@atoms/atom-types";
import { useAtom } from "@project/api";

export function useHasPermission() {
  const context = useEapContext();
  const myUserId = useSelector((state: State) => state.app.myUserData?.id);
  const roles = useAtom(Atoms.UserRoles, (roles) => roles.orgs, {
    [DefaultIdentifiers.UserId]: myUserId,
  });

  const orgId = context[Identifiers.OrganizationId];
  const projectId = context[Identifiers.ProjectId] as string;
  const projectRoles = roles?.[orgId]?.projects?.[projectId] ?? [];
  const orgRoles = roles?.[orgId]?.roles ?? [];
  const permissions: EapPermission[] = [
    ...projectRoles.flatMap((role) => ProjectPermissionsByRole[role]),
    ...orgRoles.flatMap((role) => OrganizationPermissionsByRole[role]),
  ];

  return useCallback(
    (perm: EapPermission | EapPermission[]) => {
      if (Array.isArray(perm)) {
        return intersection(perm, permissions).length > 0;
      } else {
        return permissions.includes(perm);
      }
    },
    [permissions],
  );
}
