import { Atoms } from "@project/shared";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { useAtom } from "@project/api";

export const ProjectInfoEditor = () => {
  const info = useAtom(Atoms.ProjectInfo);
  return (
    <Content>
      <Title level={2}>{info?.name}</Title>
      <Text>{info?.description}</Text>
      <Text>{info?.codeSchema}</Text>
    </Content>
  );
};
