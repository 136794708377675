import { Atoms } from "@project/shared";
import { useAtom } from "@project/api";
import { calculateLatestVersionedAtomValue } from "@atoms/atom-types";
import { useMemo } from "react";

export const LicenseIndicator = () => {
  const consumedLicenses = useAtom(Atoms.Licenses);
  const bookedLicenses = useAtom(Atoms.BookedLicenses);
  const value = useMemo(
    () =>
      calculateLatestVersionedAtomValue(bookedLicenses?.__history ?? [])
        .licenses,
    [bookedLicenses?.__history],
  );
  return (
    <span>
      Verwendete Lizenzen: {consumedLicenses?.count} / {value}
    </span>
  );
};
