import { Content } from "antd/es/layout/layout";
import { SetId, useApiClient } from "@project/api";
import { Atoms, Identifiers, Prompts } from "@project/shared";
import { AsyncButton } from "@atoms/atom-components";
import {
  Card,
  Button,
  Typography,
  Space,
  Form,
  Input,
  Modal,
  message,
  InputNumber,
} from "antd";
import { OrganizationPreview } from "./OrganizationPreview";
import { useState } from "react";
import { useSetTitle } from "../../hooks/useSetTitle";
import { PlusOutlined } from "@ant-design/icons";
import { useAtom } from "@project/api";
import { useAiTemplate } from "@atoms/ai-module/frontend";

const { Title, Paragraph } = Typography;

export const AdminView = () => {
  const organizationList = useAtom(Atoms.OrganizationList);
  const api = useApiClient();
  useSetTitle("Administration");
  useAiTemplate(Prompts.Admin);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCreateOrganization = async (values: {
    organizationId: string;
    name: string;
    description: string;
    licenses: number;
  }) => {
    if (organizationList?.entries[values.organizationId]) {
      message.error("Organisations-ID existiert bereits!");
      return;
    }
    try {
      await api.Global.createOrganization(
        {
          licenses: values.licenses,
          deleted: false,
        },
        {
          description: values.description,
          name: values.name,
        },
        {
          [Identifiers.OrganizationId]: values.organizationId,
        },
      );
      form.resetFields();
      setIsModalVisible(false);
      message.success("Organisation erfolgreich erstellt!");
    } catch (error) {
      message.error(
        "Fehler beim Erstellen der Organisation. Bitte versuchen Sie es erneut.",
      );
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <Content style={{ padding: "24px" }}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Card>
          <Title level={2}>Organisationsverwaltung</Title>
          <Paragraph>
            Hier können Sie neue Organisationen erstellen und bestehende
            verwalten.
          </Paragraph>
          <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
            Neue Organisation erstellen
          </Button>
        </Card>

        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          {Object.keys(organizationList?.entries ?? {}).map(
            (organizationId) => (
              <SetId
                key={organizationId}
                identifier={Identifiers.OrganizationId}
                value={organizationId}
              >
                <OrganizationPreview organizationId={organizationId} />
              </SetId>
            ),
          )}
        </Space>

        <Modal
          title="Organisation erstellen"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            form={form}
            onFinish={handleCreateOrganization}
            layout="vertical"
          >
            <Form.Item
              name="organizationId"
              label="Organisations-ID"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie die Organisations-ID ein!",
                },
                {
                  validator: (_, value) =>
                    organizationList?.entries[value]
                      ? Promise.reject(
                          new Error("Organisations-ID existiert bereits!"),
                        )
                      : Promise.resolve(),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie den Namen der Organisation ein!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Beschreibung"
              rules={[
                {
                  required: true,
                  message:
                    "Bitte geben Sie eine Beschreibung der Organisation ein!",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name="licenses"
              label="Anzahl Lizenzen"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie die Anzahl der Lizenzen ein!",
                },
              ]}
              initialValue={0}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <AsyncButton type="primary" htmlType="submit">
                Organisation erstellen
              </AsyncButton>
            </Form.Item>
          </Form>
        </Modal>
      </Space>
    </Content>
  );
};
