import { DraftItemType, Atoms, getNewSubCode } from "@project/shared";
import Form from "antd/es/form";
import FormItem from "antd/es/form/FormItem";
import Input, { type InputRef } from "antd/es/input";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { UIElement } from "../../UIElements";
import { useApiClient } from "@project/api";
import { useCodeValidation } from "../../hooks/useCodeValidation";
import type { State } from "../../state";
import { FloatLabel } from "../reusable/FloatLabel";
import { ReduxModal, type ReduxModalProps } from "./ReduxModal";
import { useAtom } from "@project/api";

export const CreateAddDraftModalId = "createDraft";
export const CreateAddDraftModal = ({
  ...props
}: Omit<ReduxModalProps, "id" | "children">) => {
  const { parent, type: draftType } = useSelector(
    (state: State) =>
      state.wbs.draft ?? { parent: "", type: DraftItemType.AddCategory },
    shallowEqual,
  );
  const api = useApiClient();
  const projectTree = useAtom(Atoms.ProjectTree, ({ entries }) => entries);
  const drafts = useAtom(Atoms.Draft, (d) => d.entries);

  const [code, setCode] = useState<string | undefined>();
  const [label, setLabel] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();
  const [hasValidCode, setHasValidCode] = useState(false);
  const [hasValidLabel, setHasValidLabel] = useState(false);

  const [form] = Form.useForm();
  const firstInputRef = useRef<InputRef | null>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const codeValidationResult = useCodeValidation(code ?? "");

  useEffect(() => {
    if (codeValidationResult !== undefined) {
      setHasValidCode(false);
    } else {
      setHasValidCode(true);
    }
  }, [codeValidationResult]);

  useEffect(() => {
    setHasValidLabel(label !== undefined && label.length > 0);
  }, [label]);

  useEffect(() => {
    if (modalOpen) {
      setCode(
        getNewSubCode(
          parent,
          drafts ?? [],
          projectTree ?? [],
          '{"1": "A-Z", "2": "1-9", "3": "1-9", "N": "10-90"}',
        ),
      );
    } else {
      setCode(undefined);
      setLabel(undefined);
      setDescription(undefined);
    }
  }, [modalOpen]);

  if (draftType === null) {
    return null;
  }

  return (
    <ReduxModal
      {...props}
      id={CreateAddDraftModalId}
      form={form}
      onOk={async () => {
        await api.Project.pushDraft({
          type: draftType,
          code: code!,
          label,
          to: undefined,
          description,
        });
      }}
      title={
        draftType === DraftItemType.AddCategory
          ? "Neues WBS-Element anlegen"
          : draftType === DraftItemType.AddWorkpackage
            ? "Neues Arbeitspaket anlegen"
            : ""
      }
      okButtonProps={{ disabled: !hasValidCode || !hasValidLabel }}
      firstInputRef={firstInputRef}
      afterOpenChange={setModalOpen}
    >
      <Form form={form}>
        <FormItem
          validateStatus={codeValidationResult ? "error" : undefined}
          help={codeValidationResult}
        >
          <FloatLabel label="Code" value={code}>
            <Input
              value={code}
              className={UIElement.WBS_CreateDraftCodeInput}
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </FloatLabel>
        </FormItem>
        <FormItem validateStatus={hasValidLabel ? "success" : "error"}>
          <FloatLabel label="Name" value={label}>
            <Input
              id="new-draft-label-input"
              value={label}
              onChange={(evt) => {
                setLabel(evt.target.value);
              }}
              onPressEnter={(evt) => {
                evt.stopPropagation();
              }}
              className={UIElement.WBS_CreateDraftLabelInput}
              ref={firstInputRef}
            />
          </FloatLabel>
        </FormItem>
        <FormItem>
          <FloatLabel label="Beschreibung" value={description}>
            <Input.TextArea
              value={description}
              onChange={(evt) => setDescription(evt.target.value)}
              className={UIElement.WBS_CreateDraftDescriptionInput}
            />
          </FloatLabel>
        </FormItem>
      </Form>
    </ReduxModal>
  );
};
