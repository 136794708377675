import { calculateLatestVersionedAtomValue } from "@atoms/atom-types";
import { Atoms, useApiClient, useAtom, VersionedHistory } from "@project/api";
import { Button, InputNumber, Space } from "antd";
import { useMemo, useState } from "react";

export const LicenseManager = () => {
  const consumedLicenses = useAtom(Atoms.Licenses);
  const licenses = useAtom(Atoms.BookedLicenses);
  const api = useApiClient();
  const [pendingLicenses, setPendingLicenses] = useState<number>();

  const value = useMemo(
    () => calculateLatestVersionedAtomValue(licenses?.__history ?? []),
    [licenses?.__history],
  );

  return (
    <div>
      <Space>
        <div>Gebuchte Lizenzen:</div>
        <InputNumber
          value={pendingLicenses ?? value.licenses}
          min={consumedLicenses?.count ?? 0}
          onChange={(val) => setPendingLicenses(val ?? value.licenses)}
        />
        <Button
          type="primary"
          disabled={pendingLicenses === value.licenses}
          onClick={() => {
            if (pendingLicenses != null) {
              api.Organization.updateBookedLicenses({
                licenses: pendingLicenses,
              }).then(() => setPendingLicenses(undefined));
            }
          }}
        >
          Lizenzen aktualisieren
        </Button>
      </Space>

      <VersionedHistory
        atomType={Atoms.BookedLicenses}
        name="Lizenzen"
        subItem={{
          key: "licenses",
          render: (changes) => {
            if (
              changes[0]?.path === "/licenses" &&
              changes[0]?.op === "replace"
            ) {
              return (
                <div>
                  hat die Zahl der Lizenzen auf {changes[0]?.value} geändert
                </div>
              );
            }
            return null;
          },
        }}
        labels={{ licenses: "Anzahl", deleted: "Gelöscht" }}
      />
    </div>
  );
};
