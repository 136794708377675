import { PageHeader } from "@ant-design/pro-layout";
import {
  MagicFormContext,
  MagicResetButton,
  MagicSaveButton,
  useMayUpdate,
} from "@atoms/atom-components";
import type { UserPublicID } from "@atoms/atom-types";
import {
  faCheckCircle as faCheckCircleRegular,
  faClock,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faClockRotateLeft,
  faSync,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IProjectTreeElement, IWorkpackage } from "@project/shared";
import { Atoms, EapPermission, type ICompactCategory } from "@project/shared";
import { Affix, Breadcrumb, Button, Col, Row, Tag } from "antd";
import type { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import SkeletonInput from "antd/es/skeleton/Input";
import moment from "moment";
import { type ReactNode, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isWorkpackage } from "../hooks/isWorkpackage";
import { useApiClient } from "@project/api";
import { computeActivityStats } from "../hooks/useWorkPackage";
import { FiltersApi } from "../state/filters";
import { useThunkDispatch } from "../useThunkDispatch";
import { getCategoryPath } from "../util/getCategoryPath";
import { CreateWPReportBtn } from "./CreateWPReportBtn";
import { AsyncButton } from "./reusable/AsyncButton";
import { ConditionalPopconfirm } from "./reusable/ConditionalPopconfirm";
import { useAtom } from "@project/api";

export const WorkpackageHeader = ({
  readonly,
  setShowHistory,
}: {
  readonly?: boolean;
  showHistory: boolean;
  setShowHistory: (showHistory: boolean) => void;
}) => {
  const params = useParams<{ code: string }>();
  const code = params.code;
  const navigate = useNavigate();
  const {
    updateDraft,
    currentValue,
    loading,
    hasPermission,
    draft,
    myUserId,
    valueFromBackend,
  } = useContext(MagicFormContext)!;
  const workPackage = currentValue as IWorkpackage;
  const workpackageDraft = draft as Partial<IWorkpackage> | null;
  const workPackageFromBackend = valueFromBackend as IWorkpackage;
  const api = useApiClient();
  const projectCategories = useAtom(Atoms.ProjectTree, (tree) =>
    tree.entries.filter(
      (e): e is IProjectTreeElement<ICompactCategory> => !isWorkpackage(e),
    ),
  );
  const activityStats = computeActivityStats(workPackage);
  const dispatch = useThunkDispatch();
  const mayEditPlannedFinish = useMayUpdate("expectedFinish");

  const labels: ReactNode[] = [<Tag key="wp_code">{code}</Tag>];
  if (workPackage.progress === 0) {
    labels.push(
      <Tag key="progress_not-started" icon={<FontAwesomeIcon icon={faClock} />}>
        geplant
      </Tag>,
    );
  } else if (workPackage.done) {
    labels.push(
      <Tag
        color="success"
        key="progress_finished"
        icon={<FontAwesomeIcon icon={faCheckCircleRegular} />}
      >
        abgeschlossen
      </Tag>,
    );
  } else {
    labels.push(
      <Tag
        color="processing"
        key="progress"
        icon={<FontAwesomeIcon icon={faSync} />}
      >
        in Bearbeitung
      </Tag>,
    );
  }

  const categoryPath = useMemo(() => {
    if (!projectCategories || !code) {
      return;
    }
    return getCategoryPath(projectCategories, code);
  }, [projectCategories, code]);

  return (
    <Affix offsetTop={64}>
      <div
        style={{
          backgroundColor: "white",
          marginLeft: "-10px",
          marginRight: "-10px",
          borderBottom: "1px solid rgb(216, 216, 216)",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          height: "79px", // fixed height so that the "offsetHeader" of the tables works
        }}
      >
        <PageHeader
          className="pt-2 pb-2 text-white"
          style={{ marginLeft: "10px", marginRight: "10px" }}
          onBack={() => navigate(-1)}
          breadcrumbRender={() => {
            if (loading) {
              return (
                <>
                  <SkeletonInput style={{ width: 50 }} size="small" active />
                  {" > "}
                  <SkeletonInput style={{ width: 200 }} size="small" active />
                  {" > "}
                  <SkeletonInput style={{ width: 100 }} size="small" active />
                </>
              );
            }

            return (
              <Breadcrumb
                key={`$breadcrumbs`}
                separator=">"
                className="unprintable-display-none"
                items={categoryPath?.map<BreadcrumbItemType>((category) => ({
                  key: category.code,
                  href: "",
                  onClick: (e) => {
                    e.preventDefault();
                    dispatch(FiltersApi.set("checked", [category.code]));
                    navigate("/");
                  },
                  title: category.label,
                }))}
              />
            );
          }}
          title={
            loading ? (
              <SkeletonInput size="small" active />
            ) : (
              <span className="font-bold text-lg">{currentValue.label}</span>
            )
          }
          subTitle={
            <Row align="middle" className="headerLabels">
              {loading ? (
                <SkeletonInput size="small" />
              ) : (
                labels.map((l, idx) => {
                  return <Col key={`${code}_${idx}`}>{l}</Col>;
                })
              )}
            </Row>
          }
          extra={[
            <CreateWPReportBtn key={"btn_report"} workPackage={workPackage} />,
            <Button key="btn_history" onClick={() => setShowHistory(true)}>
              <FontAwesomeIcon icon={faClockRotateLeft} style={{ margin: 0 }} />
            </Button>,
            !readonly &&
            hasPermission([
              EapPermission.UpdateWorkpackages,
              EapPermission.UpdateWorkpackagesDone,
            ]) ? (
              workPackageFromBackend?.done ? (
                <AsyncButton
                  key="btn_wp_reopen"
                  disabled={workpackageDraft !== null}
                  onClick={async () =>
                    await api.Workpackage.update({ done: false })
                  }
                >
                  AP wieder öffnen
                </AsyncButton>
              ) : (
                <AsyncButton
                  key="btn_wp_close"
                  disabled={
                    workpackageDraft !== null ||
                    activityStats?.combinedProgress !== 100
                  }
                  title={
                    activityStats?.combinedProgress !== 100
                      ? "Gemittelter Fortschritt < 100%"
                      : "AP abschließen"
                  }
                  onClick={async () => {
                    if (
                      workPackageFromBackend!.progress === 100 &&
                      activityStats?.combinedProgress === 100
                    ) {
                      await api.Workpackage.update({ done: true });
                    }
                  }}
                  icon={<FontAwesomeIcon icon={faCheckCircle} />}
                >
                  AP abschließen
                </AsyncButton>
              )
            ) : null,
            !readonly &&
            hasPermission([
              EapPermission.UpdateWorkpackages,
              EapPermission.UpdateWorkpackagesCommissioner,
              EapPermission.UpdateWorkpackagesControlinfo,
              EapPermission.UpdateWorkpackagesMetaInfo,
              EapPermission.UpdateWorkpackagesResponsible,
            ]) ? (
              <MagicResetButton key="btn_reset" />
            ) : null,
            !readonly &&
            (hasPermission([
              EapPermission.UpdateWorkpackages,
              EapPermission.UpdateWorkpackagesCommissioner,
              EapPermission.UpdateWorkpackagesControlinfo,
              EapPermission.UpdateWorkpackagesMetaInfo,
              EapPermission.UpdateWorkpackagesResponsible,
              EapPermission.UpdateActivities,
            ]) ||
              currentValue.involvedUserIds?.includes(
                myUserId as UserPublicID,
              )) ? (
              <ConditionalPopconfirm
                key="btn_save"
                placement="bottomRight"
                title={
                  <>
                    <strong>Achtung</strong>: Erwartetes Ende der letzten
                    Aktivität (
                    <strong>
                      {moment(activityStats.lastActivityFinishedAt).format(
                        "DD.MM.YYYY",
                      )}
                    </strong>
                    ) ist später als erwartetes Ende des Arbeitspaketes (
                    <strong>
                      {moment(currentValue.expectedFinish).format("DD.MM.YYYY")}
                    </strong>
                    )
                    {mayEditPlannedFinish ? (
                      <Button
                        disabled={loading}
                        ghost
                        onClick={() =>
                          updateDraft({
                            expectedFinish:
                              activityStats.lastActivityFinishedAt,
                          })
                        }
                      >
                        Erwartetes Ende des Arbeitspaketes anpassen
                      </Button>
                    ) : null}
                  </>
                }
                // onConfirm={() =>
                //   workpackageDraft !== null
                //     ? wpRevisionConflict.current?.handleSave(workpackageDraft)
                //     : null
                // }
                okText="Trotzdem speichern"
                cancelText="Abbrechen"
                disabled={
                  workpackageDraft === null ||
                  activityStats.lastActivityFinishedAt === null ||
                  currentValue.expectedFinish === null ||
                  moment(activityStats.lastActivityFinishedAt)
                    .endOf("day")
                    .isSameOrBefore(
                      moment(currentValue.expectedFinish).endOf("day"),
                    )
                }
              >
                <MagicSaveButton />
                {/* <AsyncButton
                  type="primary"
                  className={UIElement.Workpackage_SaveButton}
                  disabled={
                    workpackageDraft === null ||
                    isEqual(workPackage, workPackageFromBackend)
                  }
                  onClick={async () => {
                    if (
                      // check against popconfirm conditions
                      activityStats.lastActivityFinishedAt === null ||
                      currentValue.expectedFinish === null ||
                      moment(activityStats.lastActivityFinishedAt)
                        .endOf("day")
                        .isSameOrBefore(
                          moment(currentValue.expectedFinish).endOf("day"),
                        )
                    ) {
                      //   await wpRevisionConflict.current?.handleSave(
                      //     workpackageDraft!,
                      //   );
                      resetDraft();
                    }
                  }}
                  icon={<FontAwesomeIcon icon={faSave} />}
                >
                  Speichern
                </AsyncButton> */}
              </ConditionalPopconfirm>
            ) : null,
          ]}
        />
      </div>
    </Affix>
  );
};
