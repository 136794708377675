import { __useUsername } from "@atoms/atom-client";
import type { UserPublicID } from "@atoms/atom-types";
import { Avatar } from "antd";
import type { AvatarSize } from "antd/es/avatar/AvatarContext";
import { merge } from "lodash";
import type { CSSProperties } from "react";

interface ProjectUserAvatarProps {
  userId: UserPublicID;
  style?: CSSProperties;
  size?: AvatarSize;
}

export const ProjectUserAvatar = ({
  userId,
  style,
  size,
}: ProjectUserAvatarProps) => {
  const projectUserName = __useUsername(userId);
  const initials =
    projectUserName
      .split(" ")
      .map((p) => p[0])
      .join("") ?? "NA";

  return (
    <Avatar
      className="avatar noWrap"
      style={merge(style, {
        backgroundColor: consistentStringToColor(projectUserName ?? "NA"),
      })}
      size={size}
    >
      {initials}
    </Avatar>
  );
};

export const UserAvatar = ({ userId, style, size }: ProjectUserAvatarProps) => {
  const user = __useUsername(userId);
  const initials =
    user
      ?.split(" ")
      .map((p) => p[0])
      .join("") ?? "NA";

  return (
    <Avatar
      className="avatar noWrap"
      style={merge(style, {
        backgroundColor: consistentStringToColor(user ?? "NA"),
      })}
      size={size}
    >
      {initials}
    </Avatar>
  );
};

const consistentStringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};
