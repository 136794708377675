import { EapProjectRole } from "@project/shared";
import { Checkbox, Radio } from "antd";
import { useState } from "react";

export interface ProjectRoleEditorProps {
  roles: EapProjectRole[];
  onChange?: (roles: EapProjectRole[]) => void;
}

export const ProjectRoleEditor = ({
  roles,
  onChange,
}: ProjectRoleEditorProps) => {
  const [selectedRoles, setSelectedRoles] = useState<EapProjectRole[]>(roles);

  const handleRoleChange = (role: EapProjectRole) => {
    setSelectedRoles((prevRoles) => {
      const newRoles = [...prevRoles];
      if (newRoles.includes(role)) {
        newRoles.splice(newRoles.indexOf(role), 1);
      } else {
        newRoles.push(role);
      }
      onChange?.(newRoles);
      return newRoles;
    });
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
      {Object.entries(EapProjectRole).map(([key, value]) => (
        <Checkbox
          key={value}
          checked={selectedRoles.includes(value)}
          onChange={() => handleRoleChange(value)}
          disabled={onChange === undefined}
        >
          {key}
        </Checkbox>
      ))}
    </div>
  );
};
