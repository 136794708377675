import { type ApolloClient, ApolloProvider, gql } from "@apollo/client";
import type { ContextValues, UserPublicID } from "@atoms/atom-types";
import { pathFromTemplatesAndContext } from "@atoms/atom-types";
import type { Delta } from "jsondiffpatch";
import { type ReactNode, createContext, memo, useEffect, useMemo } from "react";
import { AtomStore } from "./AtomStore";
import { useApolloConnection } from "./apollo/useApolloConnection";
import type { SelectorFunction } from "./makeUseAtom";
import { __useDefaultUsername } from "./hooks/useDefaultUsername";

export const subscriptionDocument = gql`
  subscription subscribeAtom($type: String!, $context: JSON!) {
    subscribeAtom(type: $type, context: $context)
  }
`;

export type SubscriptionProps = {
  current: Promise<ZenObservable.Subscription>;
  /** after the last subscriber is removed, the websocket subscription remains open for a second to see if something is resubscribed */
  unsubscribeTimeout?: ReturnType<typeof setTimeout>;
  selectors: Array<SelectorFunction<any, any>>;
  callbacks: Array<
    (data: any, changes: Delta, computedChanges?: boolean) => void
  >;
  lastValue: any | null;
  completed?: boolean;
  error?: Error;
};

export type SubscriptionCallbacks = { [path: string]: SubscriptionProps };

export const AtomContext = createContext<AtomStore | null>(null);

export interface AtomContextSettings {
  children: ReactNode;
  getContextHook: () => ContextValues;
  onError?: (error: any) => void;
  graphQlUrl?: string;
  subscriptionUrl?: string;
  provideApollo?: boolean;
  getAuthToken: () => Promise<string | null>;
  getUsernameHook?: (userId: UserPublicID) => string;
  atomPathes?: {
    [key: string]: (name: string) => string[];
  };
  apolloClient?: ApolloClient<any>;
  credentials?: string;
}

export const AtomContextProvider = memo(function AtomContextProviderUnmemoized({
  children,
  getContextHook,
  onError,
  apolloClient,
  getAuthToken,
  provideApollo,
  getUsernameHook,
  graphQlUrl,
  atomPathes,
  subscriptionUrl,
  credentials,
}: AtomContextSettings) {
  if (!apolloClient) {
    apolloClient = useApolloConnection({
      graphQlUrl,
      subscriptionUrl,
      credentials,
      getAuthToken,
    });
  }
  const atomStore = useMemo(() => {
    const as = new AtomStore(
      apolloClient!,
      getContextHook,
      getUsernameHook ?? __useDefaultUsername,
      onError,
      (type, context) => {
        const template = atomPathes?.[type];
        if (template) {
          const path = pathFromTemplatesAndContext(template(type), context)[0];
          if (path) return path;
        }

        return `atom:${type}${JSON.stringify(context)}`;
      },
    );
    (globalThis as any).as = as;
    return as;
  }, [apolloClient, getContextHook, getUsernameHook]);

  useEffect(() => {
    if (onError) {
      atomStore.updateErrorCallback(onError);
    }
  }, [onError]);

  return apolloClient ? (
    provideApollo ? (
      <ApolloProvider client={apolloClient}>
        <AtomContext.Provider value={atomStore}>
          {children}
        </AtomContext.Provider>
      </ApolloProvider>
    ) : (
      <AtomContext.Provider value={atomStore}>{children}</AtomContext.Provider>
    )
  ) : null;
});

export interface UrlParams {
  gameId?: string;
  tournamentName?: string;
  tableNumber?: string;
  clubName?: string;
}
