import {
  faChevronDown,
  faClockRotateLeft,
  faDiagramProject,
  faExternalLinkAlt,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tag } from "antd";
import { Header as AntHeader } from "antd/es/layout/layout";
import Menu, { type MenuProps } from "antd/es/menu/menu";
import SkeletonAvatar from "antd/es/skeleton/Avatar";
import { type ReactNode, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UIElement } from "../UIElements";
import { ENVIRONMENTS } from "../util/constants";
import { UserButton } from "./UserButton";
import {
  useActiveProject,
  useScopeAwareNavigate,
} from "../hooks/useScopeAwareNavigate";
import "./header.scss";
import { DataProtectionDeclarationModal } from "./modals/DataProtectionDeclarationModal";
import { ImprintModal } from "./modals/ImprintModal";

interface HeaderProps {
  mock?: boolean;
  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
}
export const Header = ({ leftChildren, rightChildren, mock }: HeaderProps) => {
  const { projectId, organizationId, isDefaultProject } = useActiveProject();
  const navigate = !mock ? useScopeAwareNavigate() : () => {};
  const location = !mock ? useLocation() : { pathname: "" };
  const environment = import.meta.env.HOSTING_ENVIRONMENT;
  const [current, setCurrent] = useState(location.pathname);

  useLayoutEffect(() => {
    setCurrent(location.pathname);
  }, [location.pathname]);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "link") {
      return;
    }

    navigate(key);
  };

  const itemsLeft: MenuProps["items"] = [
    {
      label: "Home",
      key: "/",
      className: UIElement.Header_Home,
    },
    ...(projectId && organizationId
      ? [
          {
            label: "ToDos",
            key: "/todos",
            className: UIElement.Header_ToDos,
            icon: <FontAwesomeIcon icon={faListCheck} />,
          },
          {
            label: "WBS",
            key: "/wbs/main",
            className: UIElement.Header_WBS,
            icon: <FontAwesomeIcon icon={faDiagramProject} />,
            onTitleClick: () => navigate("/wbs"),
            children: [
              {
                label: "Baum",
                key: "/wbs",
              },
              {
                label: "Dokumentation",
                key: "/wbs/table",
              },
            ],
          },
          {
            label: "Aktivität",
            className: UIElement.Header_Activity,
            key: "/activity",
            icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
          },
        ]
      : []),
  ];

  const itemsRight: MenuProps["items"] = [
    !!import.meta.env.SHOW_EAP_FLEXOPUS_MENU_ITEM
      ? {
          label: (
            <a
              href="https://eap.flexopus.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Flexopus
            </a>
          ),
          key: "link",
          className: UIElement.Header_Flexopus,
          icon: <FontAwesomeIcon icon={faExternalLinkAlt} />,
        }
      : undefined,
  ].filter(Boolean) as MenuProps["items"];

  return (
    <>
      {!mock ? (
        <>
          <DataProtectionDeclarationModal />
          <ImprintModal />
        </>
      ) : null}
      <AntHeader className="header">
        <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          {mock ? (
            <img src="/logo.png" alt="Home" key="logo" />
          ) : (
            <>
              <Link
                to="/"
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {environment !== ENVIRONMENTS.prd ? (
                  <Tag
                    color={
                      environment === ENVIRONMENTS.dev ? "green" : "orange"
                    }
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translateX(-50%) translateY(-50%)",
                    }}
                  >
                    <b>{environment.toUpperCase()}</b>
                  </Tag>
                ) : null}
                <img src="/logo.png" alt="Home" key="logo" />
              </Link>
            </>
          )}
          <Menu
            mode="horizontal"
            onClick={onClick}
            selectedKeys={[current]}
            theme="dark"
            items={itemsLeft}
            className="w-full pl-7"
          />
          <Menu
            mode="horizontal"
            onClick={onClick}
            selectedKeys={[current]}
            theme="dark"
            items={itemsRight}
            className="pr-7"
          />
        </div>
        {rightChildren}
        {mock ? (
          <Button type="text" style={{ padding: "0px" }} id="user-button">
            <div
              style={{
                height: "inherit",
                display: "flex",
                alignItems: "center",
                marginRight: "0px",
                marginLeft: "10px",
              }}
            >
              <SkeletonAvatar
                active
                size="default"
                className="avatar noWrap bg-white rounded-full !w-[32px] h-[32px]"
                style={{ border: "1px solid white" }}
              />
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  fontSize: ".65rem",
                  transition: "transform .1s",
                  color: "white",
                }}
              />
            </div>
          </Button>
        ) : (
          <UserButton />
        )}
      </AntHeader>
    </>
  );
};
