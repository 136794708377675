import { Layout } from "antd";
import { Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";
import { NotFoundView } from "./NotFoundView";
import { ProjectInfoEditor } from "./ProjectInfoEditor";
import { ProjectTreeLoader } from "./ProjectTreeLoader";
import { ProjectUserManager } from "./ProjectUserManager";
import { RouterErrorView } from "./RouterErrorView";
import { SplashScreen } from "./reusable/SplashScreen";
import { useMyProjectRoles } from "../hooks/useMyProjectRoles";
import type { State } from "../state";
import { useSelector } from "react-redux";
import { useEapContext } from "../hooks/useEapContext";
import { Identifiers } from "@project/shared";
import { MyProjects } from "./MyProjects";

// lazily loaded components
const TodoListTableView = lazy(() => import("./TodoListTableView"));
const TodoListView = lazy(() => import("./TodoListView"));
const WBSView = lazy(() => import("./WBS/WBSView"));
const WBSTableView = lazy(() => import("./WBS/WBSTableView"));
const ProjectActivityView = lazy(() => import("./ProjectActivityView"));
const TemplateEditor = lazy(() => import("./TemplateEditor"));
const GanttView = lazy(() => import("./GanttView"));

// preloaded components
const WorkpackageListViewImport = import("./WorkpackageListView");
const WorkpackageListView = lazy(() => WorkpackageListViewImport);
const WorkpackageViewImport = import("./WorkpackageView");
const WorkpackageView = lazy(() => WorkpackageViewImport);

export const ProjectRoutes = () => {
  const context = useEapContext();
  const isProjectSpecified = useParams().projectId !== undefined;
  const roles = useMyProjectRoles() ?? [];
  if (!Boolean(context[Identifiers.ProjectId])) {
    return <MyProjects />;
  }

  if (roles.length === 0) {
    if (isProjectSpecified) {
      return <NotFoundView />;
    }
    return <Navigate to="/projects" replace />;
  }
  return (
    <Routes>
      <Route
        element={
          <ErrorBoundary key="ProjectTreeLoader">
            <ProjectTreeLoader>
              <Outlet />
            </ProjectTreeLoader>
          </ErrorBoundary>
        }
        errorElement={<RouterErrorView key="ProjectTreeLoader" />}
      >
        <Route
          path="template"
          element={
            <ErrorBoundary key="template">
              <Suspense fallback={<SplashScreen />}>
                <TemplateEditor />
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="template" />}
        />
        <Route
          path="template/:templateName"
          element={
            <ErrorBoundary key="/template/:templateName">
              <Suspense fallback={<SplashScreen />}>
                <TemplateEditor />
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="/template/:templateName" />}
        />

        <Route
          path="wbs/:subCode"
          element={
            <ErrorBoundary key="/wbs/:subCode">
              <Suspense fallback={<SplashScreen />}>
                <WBSView />
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="/wbs/:subCode" />}
        />
        <Route
          path="wbs"
          element={
            <ErrorBoundary key="/wbs">
              <Suspense fallback={<SplashScreen />}>
                <WBSView />
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="/wbs" />}
        />
        <Route
          path="wbs/table"
          element={
            <ErrorBoundary key="/wbs/table">
              <Suspense fallback={<SplashScreen />}>
                <Layout>
                  <WBSTableView />
                </Layout>
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="/wbs/table" />}
        />
        <Route
          path="activity"
          element={
            <ErrorBoundary key="/activity">
              <Suspense fallback={<SplashScreen />}>
                <ProjectActivityView />
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="/activity" />}
        />
        <Route
          path="todos"
          element={
            <ErrorBoundary key="/todos">
              <Suspense fallback={<SplashScreen />}>
                <Layout>
                  <TodoListTableView />
                </Layout>
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="/todos" />}
        />
        <Route
          path="todos/:todoListId"
          element={
            <ErrorBoundary key="/todos/:todoListId">
              <Suspense fallback={<SplashScreen />}>
                <Layout>
                  <TodoListView />
                </Layout>
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="/todos/:todoListId" />}
        />
        <Route
          path="p/:code"
          element={
            <ErrorBoundary key="/p/:code">
              <Suspense fallback={<SplashScreen />}>
                <Layout>
                  <WorkpackageView />
                </Layout>
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="/p/:code" />}
        />
        <Route
          path="gantt"
          element={
            <ErrorBoundary key="/gantt">
              <Suspense fallback={<SplashScreen />}>
                <GanttView />
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="/gantt" />}
        />
        <Route
          path="users"
          element={
            <ErrorBoundary key="users">
              <Suspense fallback={<SplashScreen />}>
                <Layout>
                  <ProjectUserManager />
                </Layout>
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="users" />}
        />
        <Route
          path="info"
          element={
            <ErrorBoundary key="info">
              <Suspense fallback={<SplashScreen />}>
                <Layout>
                  <ProjectInfoEditor />
                </Layout>
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="info" />}
        />
        <Route
          path="/"
          element={
            <ErrorBoundary key="index">
              <Suspense fallback={<SplashScreen />}>
                <WorkpackageListView />
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="index" />}
        />
        <Route
          path="*"
          element={
            <ErrorBoundary key="NotFound">
              <Suspense fallback={<SplashScreen />}>
                <NotFoundView />
              </Suspense>
            </ErrorBoundary>
          }
          errorElement={<RouterErrorView key="NotFound" />}
        />
      </Route>
    </Routes>
  );
};
