import {
  faChevronDown,
  faFile,
  faIdCard,
  faListAlt,
  faPowerOff,
  faQuestionCircle as faQuestionCircleSolid,
  faUserShield,
  faUsers,
  faProjectDiagram,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EapPermission } from "@project/shared";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Dropdown from "antd/es/dropdown";
import type { MenuProps } from "antd/es/menu";
import { type CSSProperties, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHasPermission } from "../hooks/useHasPermission";
import type { State } from "../state";
import { ModalsApi } from "../state/modals";
import { useThunkDispatch } from "../useThunkDispatch";
import { UserAvatar } from "@atoms/atom-components";
import { ChangeLogModalID } from "./modals/ChangeLogModal";
import { DataProtectionDeclarationModalId } from "./modals/DataProtectionDeclarationModal";
import { ImprintModalId } from "./modals/ImprintModal";
import { AsyncButton } from "./reusable/AsyncButton";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { getBackendUrl } from "@atoms/atom-client";
import type { UserPublicID } from "@atoms/atom-types";

interface OwnProps {
  style?: CSSProperties;
}
export const UserButton = ({ style }: OwnProps) => {
  const dispatch = useThunkDispatch();
  const { isAuthenticated, signoutRedirect, signinRedirect } = useAuth();

  const myUserGroups = useSelector((state: State) => state.app.myUserGroups);
  const myUserData = useSelector((state: State) => state.app.myUserData);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [backendVersion, setBackendVersion] = useState<string>("loading..");

  const { backendBaseUrl } = getBackendUrl();

  const hasPermission = useHasPermission();

  useEffect(() => {
    void (async () => {
      setBackendVersion(
        await fetch(`${backendBaseUrl}/version`)
          .then(async (res) => {
            const json = await res.json();

            return json["version"];
          })
          .catch((e) => "unknown"),
      );
    })();
  }, [backendBaseUrl]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Benutzerinformationen",
      children: [
        {
          key: "1-1",
          disabled: true,
          className: "info",
          label: (
            <div style={{ width: "250px" }}>
              <b>Name:</b>{" "}
              {(myUserData?.name.length ?? 0) > 0
                ? myUserData?.name
                : "Kein Name"}
              <br />
              <b>E-Mail:</b>{" "}
              <code className="text-xs">
                {myUserData?.email ?? "Unbekannt"}
              </code>
              <br />
              <b>Gruppe(n):</b>{" "}
              <code className="text-xs break-words">
                {!myUserGroups || myUserGroups.length === 0
                  ? "keine"
                  : myUserGroups.map((role, idx) => {
                      if (idx < myUserGroups.length - 1) {
                        return `${role.charAt(0)}${role.slice(1).toLowerCase()}, `;
                      } else {
                        return `${role.charAt(0)}${role.slice(1).toLowerCase()}`;
                      }
                    })}
              </code>
            </div>
          ),
        },
      ],
    },
    {
      key: "2",
      label: "Anwendungsinformationen",
      children: [
        {
          key: "2-1",
          disabled: true,
          className: "info",
          label: (
            <>
              <b>Versionen</b>
              <Divider style={{ marginTop: 3, marginBottom: 3 }} />
              Frontend:{" "}
              <code className="text-xs">
                {import.meta.env.FRONTEND_VERSION}
              </code>
              <br />
              Backend: <code className="text-xs">{backendVersion}</code>
            </>
          ),
        },
        {
          type: "divider",
        },
        {
          key: "changelog",
          label: (
            <>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(ModalsApi.toggle(ChangeLogModalID));
                }}
              >
                <FontAwesomeIcon
                  icon={faListAlt}
                  style={{ marginRight: "5px" }}
                />
                Changelog
              </Link>
            </>
          ),
        },
      ],
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: (
        <>
          <a href={`mailto:${import.meta.env.SUPPORT_EMAIL}`}>
            <FontAwesomeIcon icon={faQuestionCircleSolid} /> Kontakt
          </a>
        </>
      ),
    },
    {
      key: "gdpr",
      label: (
        <>
          <a
            onClick={() =>
              dispatch(ModalsApi.show(DataProtectionDeclarationModalId))
            }
          >
            <FontAwesomeIcon
              icon={faUserShield}
              style={{ marginRight: "5px" }}
            />
            Datenschutzerklärung
          </a>
        </>
      ),
    },
    {
      key: "imprint",
      label: (
        <>
          <a onClick={() => dispatch(ModalsApi.show(ImprintModalId))}>
            <FontAwesomeIcon icon={faIdCard} style={{ marginRight: "5px" }} />
            Impressum
          </a>
        </>
      ),
    },
    {
      key: "projects",
      label: (
        <>
          <Link to="/projects">
            <FontAwesomeIcon
              icon={faProjectDiagram}
              style={{ marginRight: "5px" }}
            />
            Meine Projekte
          </Link>
        </>
      ),
    },
    {
      key: "organizations",
      label: (
        <>
          <Link to="/organizations">
            <FontAwesomeIcon icon={faBuilding} style={{ marginRight: "5px" }} />
            Meine Organisationen
          </Link>
        </>
      ),
    },
    {
      key: "admin",
      label: (
        <>
          <Link to="/admin">
            <FontAwesomeIcon icon={faUserCog} style={{ marginRight: "5px" }} />
            Administration
          </Link>
        </>
      ),
    },

    hasPermission([
      EapPermission.RoleUserManager,
      EapPermission.CreateTemplate,
      EapPermission.UpdateTemplate,
    ])
      ? {
          type: "divider",
        }
      : undefined,
    hasPermission([EapPermission.CreateTemplate, EapPermission.UpdateTemplate])
      ? {
          key: "templates",
          label: (
            <>
              <Link to="/template">
                <FontAwesomeIcon icon={faFile} style={{ marginRight: "5px" }} />
                Berichtsvorlagen
              </Link>
            </>
          ),
        }
      : undefined,
    hasPermission(EapPermission.RoleUserManager)
      ? {
          key: "4",
          label: (
            <>
              <a
                href={`${import.meta.env.KEYCLOAK_URL}/admin/${import.meta.env.KEYCLOAK_REALM}/console/`}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  style={{ marginRight: "5px" }}
                />
                Nutzerverwaltung
              </a>
            </>
          ),
        }
      : undefined,
    {
      type: "divider",
    },
    {
      key: "5",
      label: (
        <>
          <AsyncButton
            block
            type="primary"
            danger
            onClick={() =>
              signoutRedirect({
                post_logout_redirect_uri: window.location.origin,
              })
            }
          >
            <FontAwesomeIcon icon={faPowerOff} style={{ marginRight: "5px" }} />
            Ausloggen
          </AsyncButton>
        </>
      ),
    },
  ].filter(Boolean) as MenuProps["items"];

  return isAuthenticated && myUserData ? (
    <>
      <Dropdown
        placement="bottomRight"
        menu={{ items }}
        onOpenChange={(open) => setDropdownVisible(open)}
      >
        <Button
          type="text"
          style={{ ...style, padding: "0px" }}
          id="user-button"
        >
          <div
            style={{ height: "inherit", display: "flex", alignItems: "center" }}
          >
            <UserAvatar
              userId={(myUserData?.id as UserPublicID) ?? ""}
              style={{
                marginRight: "0px",
                marginLeft: "10px",
                border: "1px solid white",
              }}
              size="default"
            />
            <FontAwesomeIcon
              rotation={dropdownVisible ? 180 : undefined}
              icon={faChevronDown}
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                fontSize: ".65rem",
                transition: "transform .1s",
                color: "white",
              }}
            />
          </div>
        </Button>
      </Dropdown>
    </>
  ) : (
    <AsyncButton
      type="primary"
      style={{ ...style }}
      onClick={() => signinRedirect()}
    >
      Einloggen
    </AsyncButton>
  );
};
