import type { AtomData, UserPublicID } from "@atoms/atom-types";
import { AutoComplete } from "antd";
import type { DefaultOptionType } from "antd/es/cascader";
import SkeletonInput from "antd/es/skeleton/Input";
import { useContext, useState } from "react";
import { FloatLabel } from "./FloatLabel";
import { MagicFormContext, useLabel, useMayUpdate } from "./MagicForm";

export interface UserSelectorProps<AtomType extends AtomData = AtomData> {
  readonly?: boolean;
  field: keyof AtomType & string;
  options: DefaultOptionType[];
}

export const MagicUserSelector = <AtomType extends AtomData = AtomData>({
  readonly,
  field,
  options,
}: UserSelectorProps<AtomType>) => {
  const { updateDraft, loading, currentValue, getUserName } =
    useContext(MagicFormContext)!;
  const mayUpdate = useMayUpdate(field);
  const label = useLabel(field);
  const [filterValue, setFilterValue] = useState<string | undefined>(undefined);
  return loading ? (
    <SkeletonInput active style={{ height: 48, marginBottom: "10px" }} block />
  ) : (
    <FloatLabel
      key={`${field}-selector`}
      label={label}
      value={
        filterValue ??
        (currentValue[field] ? getUserName(currentValue[field]) : "")
      }
      style={{ marginBottom: "10px" }}
      className="print-bordered"
    >
      <AutoComplete
        allowClear
        key={`${field}_input_commissioner`}
        value={
          filterValue ??
          (currentValue[field] ? getUserName(currentValue[field]) : "")
        }
        disabled={readonly || !mayUpdate}
        options={options}
        onChange={(value) => {
          setFilterValue(value);
          if (value === "" && currentValue[field] !== null) {
            updateDraft({
              [field]: null,
            });
          }
        }}
        onSelect={(value) => {
          setFilterValue(undefined);
          updateDraft({
            [field]: value as UserPublicID,
          });
        }}
        onClear={() => {
          setFilterValue(undefined);
          updateDraft({
            [field]: null,
          });
        }}
      />
    </FloatLabel>
  );
};
