import type { AtomData } from "@atoms/atom-types";
import { DatePicker } from "antd";
import SkeletonInput from "antd/es/skeleton/Input";
import dayjs from "dayjs";
import { useContext } from "react";
import { FloatLabel } from "./FloatLabel";
import { MagicFormContext, useLabel, useMayUpdate } from "./MagicForm";

export const MagicDatePicker = <AtomType extends AtomData = AtomData>({
  field,
}: {
  field: keyof AtomType & string;
}) => {
  const { updateDraft, currentValue, loading } = useContext(MagicFormContext)!;
  const mayUpdate = useMayUpdate(field);
  const label = useLabel(field);

  return loading ? (
    <SkeletonInput active style={{ height: 48, marginBottom: "10px" }} block />
  ) : (
    <FloatLabel
      key={`${field}-label`}
      label={
        !mayUpdate && !currentValue[field]
          ? "Startdatum unbekannt"
          : "Startdatum"
      }
      value={currentValue[field]?.toString()}
      className="print-bordered"
      style={{ marginBottom: "10px" }}
    >
      <DatePicker
        key={`${field}_input`}
        placeholder={""}
        format="DD.MM.YYYY"
        value={currentValue[field] ? dayjs(currentValue[field]) : undefined}
        disabled={!mayUpdate}
        onChange={(moment) => {
          updateDraft({
            [field]: moment?.toDate() ?? null,
          });
        }}
        inputReadOnly
      />
    </FloatLabel>
  );
};
