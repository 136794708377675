import { EapOrganizationRole } from "../enums/EapOrganizationRole";
import { EapProjectRole } from "../enums/EapProjectRole";
import { EapPermission } from "../enums/Permission";

export const AdminPermissions: EapPermission[] = [
  EapPermission.CreateProject,
  EapPermission.CreateOrganization,
  EapPermission.ReadOrganization,
  EapPermission.ReadProject,
];

export const GuestPermissions: EapPermission[] = [
  // Sicher
  EapPermission.ReadWorkpackages,

  // Fragen:
  // WBS: extra permission needed?
  //Bericht bzw. report drucken: extra permission needed?
  // readdrafts: was genau sind Drafts
  // EapPermission.UpdateWorkpackagesControlinfo, // where responsible authorized?
  // Freeze.Workpackages: ganzes AP oder nur Aktivität?
  // MagicSignal: Was ist das?
  // workpackage.permissions.ts nachschauen!

  EapPermission.ReadUsers, //Auftraggeber etc. in AP lesen können? - Zukunft
  EapPermission.ReadActivities, // Benötigt um ganzes AP einzusehen

  // EapPermission.ReadCategories,
  // EapPermission.ReadDrafts,
  // EapPermission.ReadTemplate,
  // EapPermission.ReadTemplateList,
  // EapPermission.ReadVersion,
];

export const ProjectPermissionsByRole: Record<EapProjectRole, EapPermission[]> =
  {
    [EapProjectRole.Guest]: [...GuestPermissions],
    [EapProjectRole.Responsible]: [
      ...GuestPermissions,

      // activities - braucht man auch "involved"?
      EapPermission.UpdateActivitySortIfResponsible, // in APs als Verantwortlicher eingetragen werden?
      // EapPermission.UpdateActivitySortIfInvolved,
      // Missing: siehe Gitlab geschätzter Fortschritt (Expertenschätzung)
      EapPermission.UpdateWorkpackages,

      EapPermission.UpdateCategories, //? What does this mean?
      // EapPermission.UpdateWorkpackagesControlinfo, // if responsible authorized:

      EapPermission.UpdateWorkpackagesInvolved,
      // EapPermission.UpdateWorkpackagesDone, //nachschauen
    ],
    [EapProjectRole.Commissioner]: [
      // alle Rechte von Gast
      ...GuestPermissions,
      // "description",
      // "goals",
      // "objective",
      // dependentWP
      EapPermission.UpdateWorkpackagesResponsible,
      EapPermission.UpdateWorkpackagesDone,
      // Enddatum einfrieren
      EapPermission.FreezeWorkpackages, // Aktivität einfrieren oder ganzes AP?
    ],
    [EapProjectRole.ProjectManager]: [
      // alle Rechte von Gast
      ...GuestPermissions,

      EapPermission.CreateProject,
      EapPermission.CreateOrganization,
      EapPermission.CreateActivities,
      EapPermission.CreateCategories,
      EapPermission.CreateWorkpackages,
      EapPermission.CreateVersion,
      EapPermission.CreateDraft,
      EapPermission.CreateTemplate,
      EapPermission.CreateTodoList,
      EapPermission.CreateTodos,
      EapPermission.DeleteActivities,
      EapPermission.DeleteWorkpackages,
      EapPermission.DeleteTodoList,
      EapPermission.DeleteTodos,
      EapPermission.FreezeWorkpackages,
      EapPermission.DeleteCategories,
      EapPermission.DeleteTemplate,
      EapPermission.DeleteDraft,
      EapPermission.ReadActivities,
      EapPermission.ReadCategories,
      EapPermission.ReadUsers,
      EapPermission.ReadWorkpackages,
      EapPermission.ReadDrafts,
      EapPermission.ReadProject,
      EapPermission.ReadOrganization,
      EapPermission.ReadVersion,
      EapPermission.ReadTemplate,
      EapPermission.ReadTemplateList,
      EapPermission.UpdateActivities,
      EapPermission.ReadTodoList,
      EapPermission.UpdateCategories,
      EapPermission.UpdateWorkpackagesControlinfo,
      EapPermission.UpdateWorkpackages,
      EapPermission.UpdateWorkpackagesCommissioner,
      EapPermission.UpdateWorkpackagesMetaInfo,
      EapPermission.UpdateWorkpackagesResponsible,
      EapPermission.UpdateWorkpackagesInvolved,
      EapPermission.UpdateWorkpackagesDone,
      EapPermission.UpdateWorkpackagesDecision,
      EapPermission.UpdateWorkpackagesPlannedStart,
      EapPermission.UpdateWorkpackagesPlannedFinish,
      EapPermission.UpdateActivitySort,
      EapPermission.UpdateActivitySortIfResponsible,
      EapPermission.UpdateTodoList,
      EapPermission.UpdateTodos,
      EapPermission.UpdateTemplate,
      EapPermission.RoleResponsible,
      EapPermission.RoleCommissioner,
      EapPermission.RoleUserManager,
      EapPermission.RoleProjectManager,
      EapPermission.DownloadXlsx,
      EapPermission.AdminRestorebackup,
      EapPermission.UpdateTodoSortIfResponsible,
      EapPermission.UpdateTodoSort,
      EapPermission.ReadOrganization,
      EapPermission.UpdateOrganization,
      EapPermission.ReadProject,
      EapPermission.UpdateProject,
    ],
    [EapProjectRole.UserManager]: [],
  } as const;
export const OrganizationPermissionsByRole: Record<
  EapOrganizationRole,
  EapPermission[]
> = {
  [EapOrganizationRole.Guest]: [EapPermission.ReadOrganization],
  [EapOrganizationRole.Manager]: [
    EapPermission.ReadOrganization,
    EapPermission.UpdateOrganization,
    EapPermission.CreateProject,
  ],
} as const;
