import { Route, Routes } from "react-router-dom";
import { AdminView } from "./AdminView";
import { OrganizationAdmin } from "./OrganizationAdmin";
import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";
import { SplashScreen } from "../reusable/SplashScreen";
import { RouterErrorView } from "../RouterErrorView";

export const AdminRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ErrorBoundary key="/admin/index">
            <Suspense fallback={<SplashScreen />}>
              <AdminView />
            </Suspense>
          </ErrorBoundary>
        }
      />
      <Route
        path=":organizationId"
        element={
          <ErrorBoundary
            key="/admin/:organizationId"
            fallback={<RouterErrorView key="/admin/:organizationId" />}
          >
            <Suspense fallback={<SplashScreen />}>
              <OrganizationAdmin />
            </Suspense>
          </ErrorBoundary>
        }
        errorElement={<RouterErrorView key="/admin/:organizationId" />}
      />
      <Route path="*" element={<RouterErrorView key="/admin/404" />} />
    </Routes>
  );
};
