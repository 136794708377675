import {
  Atoms,
  Identifiers,
  EapProjectRoleLabel,
  type EapProjectRole,
} from "@project/shared";
import { Card, Skeleton, Tag } from "antd";
import { useSelector } from "react-redux";
import type { State } from "../state";
import { DefaultIdentifiers } from "@atoms/atom-types";
import { useThunkDispatch } from "../useThunkDispatch";
import { AppApi } from "../state/app";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useAtom } from "@project/api";

export interface ProjectPreviewProps {
  projectId: string;
  organizationId: string;
}

export const ProjectPreview = ({
  projectId,
  organizationId,
}: ProjectPreviewProps) => {
  const myUserId = useSelector((state: State) => state.app.myUserData?.id);
  console.log("myUserId", myUserId);
  const isDefaultProject = useSelector(
    (state: State) =>
      state.app.defaultProject?.orgId === organizationId &&
      state.app.defaultProject?.projectId === projectId,
  );
  const dispatch = useThunkDispatch();
  const myRoles = useAtom(
    Atoms.UserRoles,
    (roles) => roles.orgs[organizationId]?.projects[projectId] ?? [],
    {
      [DefaultIdentifiers.UserId]: myUserId,
    },
  );
  const project = useAtom(Atoms.ProjectInfo, (info) => info, {
    [Identifiers.ProjectId]: projectId,
    [Identifiers.OrganizationId]: organizationId,
  });

  const setDefaultProject = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      dispatch(
        AppApi.set("defaultProject", {
          orgId: organizationId,
          projectId: projectId,
        }),
      );
    },
    [dispatch, organizationId, projectId],
  );

  const clearDefaultProject = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      dispatch(AppApi.set("defaultProject", undefined));
    },
    [dispatch],
  );

  const isDisabled = !myRoles || myRoles.length === 0;

  if (!project) {
    return (
      <Card style={{ height: 200 }}>
        <Skeleton active />
      </Card>
    );
  }

  const CardContent = (
    <Card
      title={project.name}
      extra={
        !isDisabled && (
          <>
            {isDefaultProject ? (
              <Tag
                color="green"
                style={{ marginLeft: 8 }}
                onClick={clearDefaultProject}
              >
                Standard
              </Tag>
            ) : (
              <a onClick={setDefaultProject} style={{ marginLeft: 8 }}>
                Als Standard festlegen
              </a>
            )}
          </>
        )
      }
      hoverable={!isDisabled}
      style={{ height: "100%" }}
    >
      <p>{project.description}</p>
      {isDisabled ? (
        <Tag color="red">Benutzer deaktiviert</Tag>
      ) : (
        <p>
          {myRoles.map((role: EapProjectRole) => (
            <Tag key={role} color="blue" style={{ marginRight: 4 }}>
              {EapProjectRoleLabel[role]}
            </Tag>
          ))}
        </p>
      )}
    </Card>
  );

  return isDisabled ? (
    CardContent
  ) : (
    <Link to={`/-/${organizationId}/${projectId}`}>{CardContent}</Link>
  );
};
