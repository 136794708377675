import { Atoms } from "@project/shared";
import { useAtom } from "@project/api";

export function useCodes() {
  const categories = useAtom(Atoms.ProjectTree, (tree) => tree.entries) ?? [];
  const drafts = useAtom(Atoms.Draft, (drafts) => drafts.entries) ?? [];
  const result: Record<string, string | undefined> = {};
  for (const c of categories) {
    result[c.code] = c.label;
  }
  for (const d of drafts) {
    if (d.label) {
      result[d.code] = d.label;
    }
  }
  return result;
}
