import { App as AntApp, ConfigProvider, Spin } from "antd";
import deDE from "antd/locale/de_DE";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import moment from "moment";
import type { ReactNode } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import thunk from "redux-thunk";
import * as timeago from "timeago.js";
import de from "timeago.js/lib/lang/de";
import { CoreApp } from "../CoreApp";
import { rootReducer } from "../state";
import "../style/app.scss";
import { AtomsProvider } from "@atoms/atom-client";
import { useEapContext } from "../hooks/useEapContext";
import { SplashScreenNode } from "./SplashScreenNode";

dayjs.locale("de");
dayjs.extend(relativeTime);
moment.locale("de");
timeago.register("de", de);
const store = createStore(rootReducer, applyMiddleware(thunk));
store.subscribe(() => {
  (window as any).state = store.getState();
});

const persistor = persistStore(store);
const FixedPersistGate = PersistGate as any;

export const MainComponent: ReactNode = (
  <>
    <Provider store={store}>
      <FixedPersistGate
        loading={
          <Spin
            tip="Lade Einstellungen"
            className="!w-screen !h-screen !max-h-[unset]"
          >
            <div />
          </Spin>
        }
        persistor={persistor}
      >
        {" "}
        <ConfigProvider
          locale={deDE}
          theme={{
            token: {
              fontFamily: "PT Sans",
              colorPrimary: "#0097cc",
              colorLink: "#0097cc",
            },
          }}
        >
          <AtomsProvider
            splashScreen={SplashScreenNode}
            getContextHook={useEapContext}
          >
            <AntApp>
              <CoreApp />
            </AntApp>
          </AtomsProvider>
        </ConfigProvider>
      </FixedPersistGate>
    </Provider>
  </>
);
