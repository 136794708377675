import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Col, Empty } from "antd";
import Button from "antd/es/button";

import { MenuOutlined } from "@ant-design/icons";
import { TextAreaOrDiv } from "@atoms/atom-components";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { EapPermission, type IToDo, type ITodoList } from "@project/shared";
import ButtonGroup from "antd/es/button/button-group";
import { ConfigContext } from "antd/es/config-provider";
import DatePicker from "antd/es/date-picker";
import Row from "antd/es/grid/row";
import Popconfirm from "antd/es/popconfirm";
import SkeletonButton from "antd/es/skeleton/Button";
import SkeletonInput from "antd/es/skeleton/Input";
import type { ColumnsType } from "antd/es/table/interface";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import dayjs from "dayjs";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import moment from "moment";
import {
  Children,
  type ReactElement,
  cloneElement,
  memo,
  useContext,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { UIElement } from "../UIElements";
import { useHasPermission } from "../hooks/useHasPermission";
import type { State } from "../state";
import { compareDates } from "../util/compareDates";
import { getLabelProperties } from "../util/getLabelProperties";
import { ReduxTable } from "./ReduxTable";
import "./TodoTable.scss";
import {
  CreateTodoModal,
  type CreateTodoModalRef,
} from "./modals/CreateTodoModal";
import {
  UpdateTodoModal,
  type UpdateTodoModalRef,
} from "./modals/UpdateTodoModal";
import { AsyncButton } from "./reusable/AsyncButton";

interface TodoTableProps {
  todoList: ITodoList | undefined;
  responsibleUserId?: string | null;
  involvedUserIds?: string[] | null;
  readonly?: boolean;
  className?: string;
  updateDraft: (draft: Partial<ITodoList>) => void;
}

interface DraggableBodyRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

export const TodoTable = memo(
  ({
    responsibleUserId,
    involvedUserIds,
    todoList,
    className,
    updateDraft,
  }: TodoTableProps) => {
    const hasPermission = useHasPermission();
    const loading = todoList === undefined;

    const updateTodoModal = useRef<UpdateTodoModalRef>(null);
    const createTodoModal = useRef<CreateTodoModalRef>(null);

    const todos = orderBy(
      Object.values(todoList?.todos ?? {})
        .reverse()
        .map((a) => {
          const todo = { ...a };
          if (todo.sortIndex === null) {
            todo.sortIndex = -1;
          }

          return todo;
        }),
      ["sortIndex"],
      ["asc"],
    ).map((a, idx) => {
      const todo = { ...a };
      todo.sortIndex = idx;
      return todo;
    });

    const myUserId = useSelector((state: State) => state.app.myUserData?.id);
    const amIInvolved =
      (myUserId && todoList?.involvedUserIds?.some((a) => a === myUserId)) ||
      (myUserId && todoList?.responsibleUserId === myUserId);
    const isResponsible = myUserId && responsibleUserId === myUserId;
    const isInvolved = myUserId && involvedUserIds?.includes(myUserId);
    const antdConfig = useContext(ConfigContext);

    const hasTodoSortPermission =
      hasPermission(EapPermission.UpdateTodoSort) ||
      (isResponsible &&
        hasPermission(EapPermission.UpdateTodoSortIfResponsible)) ||
      isInvolved;

    const columns: ColumnsType<IToDo> = [
      {
        key: "sortIndex",
        dataIndex: "sortIndex",
        sortOrder: "ascend",
        width: 20,
        className: "drag-visible",
      },
      isResponsible || isInvolved || hasPermission(EapPermission.UpdateTodoList)
        ? {
            key: "erledigt",
            title: "Erledigt",
            sorter: loading
              ? undefined
              : (a: IToDo, b: IToDo) => {
                  return a.done === b.done ? 0 : a.done ? 1 : -1;
                },

            width: 100,
            render: (_value: string, record: IToDo, idx: number) => (
              <ButtonGroup key={`btn-grp_${idx}`}>
                {loading ? (
                  <SkeletonButton size="small" active />
                ) : (
                  <Checkbox
                    checked={record.done}
                    onClick={() => {
                      const todos = Object.assign({}, todoList.todos);
                      todos[record.uuid] = {
                        ...todos![record.uuid]!,
                        done: !record.done,
                      };
                      updateDraft({
                        todos,
                      });
                    }}
                  />
                )}
              </ButtonGroup>
            ),
          }
        : undefined,
      {
        key: "beschreibung",
        title: "Beschreibung",
        dataIndex: "description",
        sortOrder: "ascend",
        sorter: loading
          ? undefined
          : (a: IToDo, b: IToDo) => {
              return a.description.localeCompare(b.description);
            },
        width: 400,
        render: (value: string, record: IToDo, idx: number) => {
          if (loading) {
            return <SkeletonInput active size="small" block />;
          }

          return (
            <TextAreaOrDiv
              key={`ta_${idx}`}
              value={value}
              style={{
                textDecoration: record.done ? "line-through" : "none",
              }}
              autoSize
              disabled
            />
          );
        },
      },
      {
        key: "enddatum",
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <span>Fällig am</span>
          </div>
        ),
        dataIndex: "plannedFinish",
        sorter: loading
          ? undefined
          : (a: IToDo, b: IToDo, sortOrder: "ascend" | "descend") => {
              return compareDates(a.plannedFinish, b.plannedFinish, sortOrder);
            },
        sortOrder: "ascend",
        width: 170,
        render: (value: string, record: IToDo) => {
          if (loading) {
            return <SkeletonInput active size="small" />;
          }

          return (
            <>
              {" "}
              <DatePicker
                placeholder={""}
                format="DD.MM.YYYY"
                value={value ? dayjs(value) : undefined}
                style={{
                  textDecoration: record.done ? "line-through" : "none",
                  width:
                    value &&
                    moment(value).isBefore(moment().add(7, "days")) &&
                    amIInvolved
                      ? "110px"
                      : undefined,
                }}
                disabled
              />
              {value &&
              moment(value).isBefore(moment().add(7, "days")) &&
              amIInvolved ? (
                <Tooltip title="Fällig in weniger als 7 Tagen">
                  <Tag
                    color="red"
                    style={{ marginLeft: "5px", marginRight: "0px" }}
                  >
                    !
                  </Tag>
                </Tooltip>
              ) : null}
            </>
          );
        },
      },
      {
        key: "statusbericht",
        title: "Bericht zu den ToDos",
        dataIndex: "status",
        className: "min-w-[200px]",
        sorter: loading
          ? undefined
          : (a: IToDo, b: IToDo) => {
              return a.status.localeCompare(b.status);
            },
        render: (value: string, record: IToDo, idx: number) => {
          if (loading) {
            return <SkeletonInput active size="small" block />;
          }

          return (
            <TextAreaOrDiv
              value={value}
              autoSize
              disabled
              style={{
                textDecoration: record.done ? "line-through" : "none",
              }}
            />
          );
        },
      },
    ].filter(Boolean) as ColumnsType<IToDo>;

    if (
      isResponsible ||
      isInvolved ||
      hasPermission(EapPermission.UpdateTodoList)
    ) {
      columns.push({
        key: "aktionen",
        title: "Aktionen",
        width: 150,
        render: (_value, record, idx) => (
          <ButtonGroup key={`btn-grp_${idx}`}>
            {loading ? (
              <SkeletonButton size="small" active />
            ) : (
              <Button
                onClick={async () => {
                  try {
                    const draft = await updateTodoModal.current?.update(
                      record,
                      todoList,
                    );
                    if (draft) {
                      const todos = { ...todoList.todos, [draft.uuid!]: draft };
                      updateDraft({ todos });
                    }
                  } catch {
                    // swallow errror
                  }
                }}
                disabled={
                  record.done ||
                  (!hasPermission(EapPermission.UpdateTodos) &&
                    !isResponsible &&
                    !isInvolved)
                }
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  title="Bearbeiten"
                  style={{ marginRight: 0 }}
                />
              </Button>
            )}
            {hasPermission(EapPermission.DeleteTodos) ||
            isResponsible ||
            isInvolved ? (
              <>
                {loading ? (
                  <SkeletonButton size="small" active />
                ) : (
                  <Popconfirm
                    placement="topLeft"
                    title="ToDo-Item wirklich löschen?"
                    onConfirm={() => {
                      const todos = Object.assign({}, todoList.todos);
                      delete todos![record.uuid];
                      updateDraft({ todos });
                    }}
                    okText="Löschen"
                    okButtonProps={{
                      type: "primary",
                      danger: true,
                      className: UIElement.Todo_ConfirmDeleteTodoButton,
                    }}
                    cancelText="Abbrechen"
                    icon={
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        style={{ marginRight: 0, color: "red" }}
                        className="anticon"
                      />
                    }
                  >
                    <Button danger className={UIElement.Todo_DeleteTodoButton}>
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        title="Löschen"
                        style={{ marginRight: 0 }}
                      />
                    </Button>
                  </Popconfirm>
                )}
              </>
            ) : null}
          </ButtonGroup>
        ),
        fixed: "right",
      });
    }

    const onSortEnd = ({ active, over }: DragEndEvent) => {
      const newIndex = todos.findIndex((todo) => todo.uuid === over?.id);
      const oldIndex = todos.findIndex((todo) => todo.uuid === active.id);

      if (oldIndex === null || oldIndex !== newIndex) {
        const oldTodos = Object.values(todos).sort(
          (a, b) => a.sortIndex! - b.sortIndex!,
        );
        const newSortedTodos = [...oldTodos];
        newSortedTodos.splice(
          newIndex,
          0,
          newSortedTodos.splice(oldIndex!, 1)[0]!,
        );
        newSortedTodos.forEach((a, i) => (a.sortIndex = i));
        updateDraft({ todos: keyBy(newSortedTodos, "uuid") });
      }
    };

    const DraggableBodyRow = ({
      children,
      ...props
    }: DraggableBodyRowProps) => {
      const uuid = props["data-row-key"];

      const {
        attributes,
        setNodeRef,
        transform,
        transition,
        isDragging,
        listeners,
        setActivatorNodeRef,
      } = useSortable({
        id: uuid,
      });

      const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(
          transform && { ...transform, scaleY: 1 },
        ),
        transition,
        ...(isDragging ? { position: "relative", zIndex: 999 } : {}),
      };

      return (
        <tr
          key={uuid}
          {...props}
          ref={setNodeRef}
          style={style}
          {...attributes}
        >
          {Children.map(children, (child) => {
            if ((child as ReactElement).key === "sortIndex") {
              return cloneElement(child as ReactElement, {
                children: (
                  <>
                    <div className="table-row-tag" />
                    {hasTodoSortPermission ? (
                      <MenuOutlined
                        ref={setActivatorNodeRef}
                        className="drag-handle"
                        {...listeners}
                      />
                    ) : null}
                  </>
                ),
              });
            }
            return child;
          })}
        </tr>
      );
    };

    return (
      <>
        {!loading ? (
          <>
            <CreateTodoModal ref={createTodoModal} />
            <UpdateTodoModal ref={updateTodoModal} />
          </>
        ) : null}
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onSortEnd}>
          <SortableContext
            // rowKey array
            items={todos.map((todo) => todo.uuid)}
            strategy={verticalListSortingStrategy}
          >
            <ReduxTable<IToDo>
              columns={columns}
              dataSource={loading ? ([1, 2, 3, 4] as any) : todos!}
              scroll={todos.length === 0 ? undefined : { x: "max-content" }}
              className={"todoTable " + className}
              pagination={false}
              sticky={{
                offsetHeader: 127,
              }}
              locale={{
                ...antdConfig.locale?.Table,
                emptyText: (
                  <Empty
                    description="Keine ToDos vorhanden"
                    className="mt-10 mb-10 text-gray-400"
                  />
                ),
              }}
              size="large"
              title={() => (
                <Row align="middle">
                  <Col
                    style={{
                      fontSize: "larger",
                      fontWeight: "bold",
                      marginRight: "50px",
                    }}
                    flex={1}
                  >
                    ToDos
                  </Col>
                  <Col>
                    {!isResponsible &&
                    !isInvolved &&
                    !hasPermission(EapPermission.UpdateTodoList) ? undefined : (
                      <AsyncButton
                        type="primary"
                        onClick={async () => {
                          if (!todoList) {
                            return;
                          }

                          try {
                            const draft =
                              await createTodoModal.current?.create(todoList);
                            if (draft) {
                              const todos = {
                                ...todoList.todos,
                                [draft.uuid!]: draft,
                              };
                              updateDraft({ todos });
                            }
                          } catch {
                            // swallow error
                          }
                        }}
                        disabled={loading}
                        className={UIElement.Todo_CreateTodoButton}
                      >
                        Erstellen
                      </AsyncButton>
                    )}
                  </Col>
                </Row>
              )}
              rowKey={(row) => row.uuid ?? JSON.stringify(row)}
              components={{
                body: {
                  row: DraggableBodyRow,
                },
              }}
              rowClassName={(record, idx) => {
                const classNames = new Array<string>();

                const isTodoFinished = Boolean(record.done);
                const isPlannedFinishOverdue =
                  record.plannedFinish &&
                  moment(record.plannedFinish).isBefore(
                    moment().startOf("day"),
                  );

                if (!isTodoFinished && isPlannedFinishOverdue) {
                  classNames.push("todo-table-row-overdue");
                }

                classNames.push(
                  idx % 2 === 0 ? "table-row-even" : "table-row-odd",
                );

                if (record.label) {
                  const labelProps = getLabelProperties(record.label);
                  classNames.push("table-row-with-tag");
                  if (labelProps?.color) {
                    classNames.push(`table-row-${record.label}`);
                  }
                }

                return classNames.join(" ");
              }}
              tableId="todoTable"
            />
          </SortableContext>
        </DndContext>
      </>
    );
  },
);
