import type { AtomAccessPermissions } from "@atoms/atom-types";
import { EapPermission } from "../enums/Permission";
import type { IWorkpackage } from "../interfaces/IWorkpackage";

export const workpackagePermissions: AtomAccessPermissions<
  IWorkpackage,
  EapPermission
> = {
  create: [EapPermission.CreateWorkpackages],
  read: [EapPermission.ReadWorkpackages],
  dynamicPermissions: {
    mayEditControlInfo: (self: IWorkpackage, context): boolean => {
      const actingUserId = context.getActingUserOrThrow();
      const isCommissioner = self.commissionerUserId === actingUserId;
      const isResponsible = self.responsibleUserId === actingUserId;
      return (
        context.hasPermission(EapPermission.UpdateWorkpackagesControlinfo) &&
        (isResponsible || isCommissioner)
      );
    },
    mayEditMetaInfo: (self: IWorkpackage, context): boolean => {
      const actingUserId = context.getActingUserOrThrow();
      const isCommissioner = self.commissionerUserId === actingUserId;
      return (
        context.hasPermission(EapPermission.UpdateWorkpackagesMetaInfo) &&
        isCommissioner
      );
    },
  },
  fieldPermissions: [
    {
      fields: ["label"],
      update: [EapPermission.CreateVersion],
    },
    {
      fields: [
        "decisionRequired",
        "decisionRequiredReason",
        "signal",
        "progress",
        "progressReason",
        "status",
        "expectedFinish",
      ],
      update: [EapPermission.UpdateWorkpackages, "mayEditControlInfo"],
    },
    {
      fields: ["decision"],
      update: [EapPermission.UpdateWorkpackagesDecision],
    },
    {
      fields: [
        "label",
        "description",
        "goals",
        "objective",
        "plannedFinish",
        "plannedStart",
      ],
      update: [EapPermission.UpdateWorkpackages, "mayEditMetaInfo"],
    },
    {
      fields: ["responsibleUserId"],
      update: [
        EapPermission.UpdateWorkpackages,
        "mayEditMetaInfo",
        EapPermission.UpdateWorkpackagesResponsible,
      ],
    },
    {
      fields: ["involvedUserIds"],
      update: [
        EapPermission.UpdateWorkpackages,
        "mayEditMetaInfo",
        EapPermission.UpdateWorkpackagesInvolved,
      ],
    },
    {
      fields: ["commissionerUserId"],
      update: [EapPermission.UpdateWorkpackagesCommissioner],
    },
    {
      fields: ["done"],
      update: [EapPermission.UpdateWorkpackagesDone],
    },
    {
      fields: ["plannedStart"],
      update: [EapPermission.UpdateWorkpackagesPlannedStart],
    },
    {
      fields: ["plannedFinish"],
      update: [EapPermission.UpdateWorkpackagesPlannedFinish],
    },
    {
      fields: ["frozen"],
      update: [EapPermission.FreezeWorkpackages],
    },
  ],
};
