import "@ant-design/pro-layout/es/components/PageHeader/style/index";
import {
  type AiBackendApi,
  AiContextProvider,
} from "@atoms/ai-module/frontend";
import { Button, Layout, Result } from "antd";
import "./style/antd-styles";
import "dayjs/locale/de";
import { Suspense, lazy } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { AdminRoute } from "./components/Admin/AdminRoute";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Header } from "./components/Header";
import { MyProjects } from "./components/MyProjects";
import { ProjectRoutes } from "./components/ProjectRoutes";
import { RouterErrorView } from "./components/RouterErrorView";
import { UnsavedChangesHandler } from "./components/UnsavedChangesWarning";
import ChangeLogModal from "./components/modals/ChangeLogModal";
import { SplashScreen } from "./components/reusable/SplashScreen";
import { useApiClient } from "@project/api";
import { useHandleOidcEvents } from "./hooks/useHandleOidcEvents";
import type { State } from "./state";
import { Content } from "antd/es/layout/layout";
import { NotFoundView } from "./components/NotFoundView";
import { MyOrganizations } from "./components/MyOrganizations";

// lazily loaded components

// preloaded components

export const CoreApp = () => {
  const { isAuthenticated } = useAuth();
  useHandleOidcEvents();
  const myUserId = useSelector((state: State) => state.app.myUserData?.id);
  const api = useApiClient();
  const userGroups = useSelector((state: State) => state.app.myUserGroups);
  if (!myUserId) {
    return <SplashScreen />;
  }
  return (
    <>
      <ChangeLogModal />
      {/* <PlaywrightUtilLoader> */}
      <AiContextProvider
        backendApi={
          "AgentSession" in api ? (api.AgentSession as AiBackendApi) : undefined
        }
        enabled={import.meta.env.AI_ENABLED === "true"}
      >
        <RouterProvider
          router={createBrowserRouter(
            createRoutesFromElements(
              <Route
                element={
                  <ErrorBoundary key="outlet">
                    <UnsavedChangesHandler>
                      <Layout style={{ minHeight: "100vh" }}>
                        <Header />
                        <Outlet />
                      </Layout>
                    </UnsavedChangesHandler>
                  </ErrorBoundary>
                }
                errorElement={<RouterErrorView key="outlet" />}
              >
                {isAuthenticated && (!userGroups || userGroups.length === 0) ? (
                  <Route
                    path="*"
                    element={
                      <ErrorBoundary key="403">
                        <Content>
                          <Result
                            status="403"
                            title="Zugriff verweigert"
                            subTitle="Sie haben aktuell keinen Zugriff auf das AP-Tool."
                            extra={
                              <Button
                                type="primary"
                                // href={`mailto:${
                                //   import.meta.env.SUPPORT_EMAIL
                                // }?subject=${import.meta.env.ACCESS_REQUEST_SUBJECT}`}
                              >
                                Zugriff anfragen
                              </Button>
                            }
                          />
                        </Content>
                      </ErrorBoundary>
                    }
                    errorElement={<RouterErrorView key="403" />}
                  />
                ) : (
                  <>
                    <Route
                      path="/projects"
                      element={
                        <ErrorBoundary key="projects">
                          <Suspense fallback={<SplashScreen />}>
                            <MyProjects />
                          </Suspense>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="/organizations"
                      element={
                        <ErrorBoundary key="organizations">
                          <Suspense fallback={<SplashScreen />}>
                            <MyOrganizations />
                          </Suspense>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="/admin/*"
                      element={
                        <ErrorBoundary key="/admin">
                          <Suspense fallback={<SplashScreen />}>
                            <Layout>
                              <AdminRoute />
                            </Layout>
                          </Suspense>
                        </ErrorBoundary>
                      }
                      errorElement={<RouterErrorView key="/admin" />}
                    />

                    <Route
                      path="/-/:organizationId/:projectId/*"
                      element={<ProjectRoutes />}
                    />
                    <Route
                      path="/*"
                      element={
                        <ErrorBoundary key="/*">
                          <Suspense fallback={<SplashScreen />}>
                            <ProjectRoutes />
                          </Suspense>
                        </ErrorBoundary>
                      }
                      errorElement={<RouterErrorView key="/" />}
                    />
                  </>
                )}
              </Route>,
            ),
          )}
        />
        {/* </PlaywrightUtilLoader> */}
      </AiContextProvider>
    </>
  );
};
