import { AsyncButton, makeAtomTable } from "@atoms/atom-components";
import type { UserPublicID } from "@atoms/atom-types";
import { useApiClient, type atomDataIndex } from "@project/api";
import { Atoms, EapOrganizationRole } from "@project/shared";
import { Card, Form, Input, message, Select } from "antd";
import { useSelector } from "react-redux";
import { useSetTitle } from "../../hooks/useSetTitle";
import type { State } from "../../state";
import { UserName } from "../UserName";
import { OrganizationRoleEditor } from "./OrganizationRoleEditor";

const EapTable = makeAtomTable<atomDataIndex>();

export const OrganizationUserManager = () => {
  const api = useApiClient();
  useSetTitle("Organisationsnutzerverwaltung");
  const myUserId = useSelector(
    (state: State) => state.app.myUserData?.id,
  ) as UserPublicID;
  const [form] = Form.useForm();

  const handleAddUser = async (values: {
    email: string;
    name: string;
    roles: EapOrganizationRole[];
  }) => {
    try {
      await api.Organization.addUserToByEmail(
        values.email,
        values.name,
        values.roles,
      );
      message.success("Benutzer erfolgreich eingeladen");
      form.resetFields();
    } catch (error) {
      message.error("Fehler beim Einladen des Benutzers");
    }
  };

  return (
    <Card>
      <div style={{ marginBottom: "20px" }}>
        <Form
          form={form}
          layout="inline"
          onFinish={handleAddUser}
          initialValues={{ roles: [] }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
              },
            ]}
          >
            <Input placeholder="E-Mail-Adresse" style={{ width: 200 }} />
          </Form.Item>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "Bitte geben Sie einen Namen ein" },
            ]}
          >
            <Input placeholder="Name" style={{ width: 150 }} />
          </Form.Item>
          <Form.Item
            name="roles"
            rules={[
              {
                required: true,
                message: "Bitte wählen Sie mindestens eine Rolle",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Rollen"
              style={{ width: 200 }}
              options={Object.keys(EapOrganizationRole).map((role) => ({
                label: role,
                value: role,
              }))}
            />
          </Form.Item>
          <Form.Item>
            <AsyncButton htmlType="submit">Benutzer einladen</AsyncButton>
          </Form.Item>
        </Form>
      </div>

      <h3>Organisationsmitglieder</h3>
      <EapTable
        atomType={Atoms.OrganizationMembers}
        actions={(key, record) => (
          <AsyncButton
            onClick={async () => {
              return await api.Organization.updateMembersEntry(key, {
                ...record,
                disabled: !record.disabled,
              });
            }}
          >
            {record.disabled ? "Aktivieren" : "Deaktivieren"}
          </AsyncButton>
        )}
        columns={[
          {
            title: "Benutzer",
            dataIndex: "key",
            render: (userId) => <UserName publicId={userId} />,
          },
          {
            title: "Rollen",
            dataIndex: "roles",
            render: (roles, record) => (
              <OrganizationRoleEditor
                roles={roles as EapOrganizationRole[]}
                onChange={(newRoles) =>
                  api.Organization.updateMembersEntry(record.key, {
                    ...record,
                    roles: newRoles,
                  })
                }
              />
            ),
          },
          {
            title: "Status",
            dataIndex: "disabled",
            render: (isDisabled) => (
              <span>{isDisabled ? "Deaktiviert" : "Aktiv"}</span>
            ),
          },
        ]}
      />
    </Card>
  );
};
