import type { ContextValues } from "./ContextValues";

export function pathFromTemplatesAndContext(
  pathTemplates: string[],
  forContext: ContextValues,
): [string | null, ContextValues, string[]] {
  const missingContextValues: string[] = [];
  pathloop: for (const pathTemplate of pathTemplates) {
    let path = pathTemplate.slice(0);
    const usedContextValues: ContextValues = {};
    const matches = path.match(/(<.*?>)/g);
    if (matches === null) {
      return [path.toLowerCase(), usedContextValues, missingContextValues];
    }

    for (const matchedContextKey of matches) {
      const matchedContextValue = forContext[matchedContextKey];
      if (matchedContextValue === undefined || matchedContextValue === null) {
        missingContextValues.push(matchedContextKey);
        continue pathloop;
      }

      // console.log("match", matchedContextKey, matchedContextValue);
      usedContextValues[matchedContextKey] = matchedContextValue;
      path = path.replace(matchedContextKey, matchedContextValue.toString());
    }

    return [path.toLowerCase(), usedContextValues, []];
  }

  return [null, {}, missingContextValues];
}
