import { Tooltip } from "antd";
import {
  type InputHTMLAttributes,
  type KeyboardEvent,
  useLayoutEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useCodeValidation } from "../../hooks/useCodeValidation";
import type { State } from "../../state";

interface WBSCodeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  wwidth: number;
  x?: number;
  onlyLastPart?: boolean;
  oldCode: string;
  onTab?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onValidationChange: (valid: boolean) => void;
}

export const WBSCodeInput = ({
  onTab,
  onEnter,
  onValidationChange,
  oldCode,
  onlyLastPart,
  x,
  wwidth,
  ...props
}: WBSCodeInputProps) => {
  const displaySettings = useSelector(
    (state: State) => state.wbs.displaySettings,
  );

  const [value, setValue] = useState("");
  const help = useCodeValidation(value, oldCode);

  if (help) {
    onValidationChange?.(false);
  } else {
    onValidationChange?.(true);
  }

  useLayoutEffect(() => {
    setValue(props.value as string);
  }, [props.value]);
  return (
    <foreignObject x={x ?? -1} y={-1} width={wwidth} height={100}>
      <Tooltip
        placement="top"
        title={help}
        open={help !== undefined && value !== ""}
        color="salmon"
      >
        <input
          {...props}
          onKeyDown={(e: any) => {
            switch (e.key) {
              case ".":
                e.preventDefault();
                break;
              case "Tab":
                e.preventDefault();
                break;
              case "Enter":
                e.preventDefault();
                e.stopPropagation();
                onEnter?.(e);
                break;
              case "Escape":
                break;
              default:
                // e.preventDefault();
                e.stopPropagation();
                break;
            }
          }}
          style={{
            borderRadius: 0,
            ...props.style,
            // border: "none",
            background: "none",
            paddingTop: -5 + displaySettings.padding,
            width: wwidth,
            fontSize: displaySettings.fontSize,
          }}
          value={value}
          onChange={(e) => {
            // make sure only the last part of the value has been changed
            if (
              onlyLastPart &&
              !e.target.value.startsWith(
                value.substring(0, value.lastIndexOf(".") + 1),
              )
            ) {
              return;
            }
            setValue(e.target.value);
            props.onChange?.(e);
          }}
        />
      </Tooltip>
    </foreignObject>
  );
};
