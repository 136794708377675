import { faStar } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationCircle,
  faStar as faStarSolid,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Atoms, Signal } from "@project/shared";
import { Badge, Button, Empty } from "antd";
import Col from "antd/es/col";
import { ConfigContext } from "antd/es/config-provider";
import { default as Progress } from "antd/es/progress";
import { default as Row } from "antd/es/row";
import SkeletonAvatar from "antd/es/skeleton/Avatar";
import SkeletonButton from "antd/es/skeleton/Button";
import SkeletonInput from "antd/es/skeleton/Input";
import type { ColumnsType } from "antd/es/table";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import dayjs from "dayjs";
import moment from "moment";
import { useContext } from "react";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TimeAgo from "timeago-react";
import {
  type FilteredPackages,
  useFilteredPackages,
} from "../hooks/useFilteredPackages";
import { useReactTransition } from "../hooks/useReactTransition";
import { useSetTitle } from "../hooks/useSetTitle";
import type { State } from "../state";
import { AppApi } from "../state/app";
import { useThunkDispatch } from "../useThunkDispatch";
import { sortByWBSCode } from "../util/sortByWBS";
import { ProjectUserAvatar } from "@atoms/atom-components";
import { ProjectUserName } from "./ProjectUserName";
import { ReduxTable } from "./ReduxTable";
import { WorkPackageSearchWithFilter } from "./WorkPackageSearchWithFilter";
import { WorkPackageTableReportBtn } from "./WorkpackageTableReportBtn";
import { useScopeAwareNavigate } from "../hooks/useScopeAwareNavigate";
import { useAtom } from "@project/api";

export const seperator = ".";

export const WorkPackageList = () => {
  const filteredPackages = useFilteredPackages();
  const dispatch = useThunkDispatch();
  const navigate = useScopeAwareNavigate();
  const antdConfig = useContext(ConfigContext);

  const projectUsers =
    useAtom(Atoms.ProjectUsers, ({ entries }) => entries) ?? [];
  const loading = useReactTransition() || filteredPackages === undefined;
  const searchFilter = useSelector((state: State) => state.filters.label);
  useSetTitle("Übersicht");

  const columns: ColumnsType<FilteredPackages[0]> = [
    {
      title: "Favorit",
      width: 70,
      dataIndex: "favorite",
      defaultSortOrder: "ascend",
      sorter: loading
        ? undefined
        : (a, b): number => {
            if (a.favorite && b.favorite) {
              return 0;
            }
            if (a.favorite) {
              return -1;
            }
            if (b.favorite) {
              return 1;
            }
            return 0;
          },
      render: (fav, row) => {
        if (loading) {
          return <SkeletonButton active size="small" style={{ width: 20 }} />;
        }

        return (
          <Button
            ghost
            icon={
              <FontAwesomeIcon
                className={fav ? "favoriteChecked" : "favorite"}
                icon={fav ? faStarSolid : faStar}
              />
            }
            onClick={(e) => {
              e.stopPropagation();
              dispatch(AppApi.toggleFavorite(row.code));
            }}
            className="favoriteButton"
          />
        );
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      width: 150,
      sorter: loading ? undefined : sortByWBSCode,
      render: (code) => {
        if (loading) {
          return <SkeletonInput size="small" active />;
        }

        return (
          <span data-tag="allowRowEvents">
            {searchFilter ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchFilter]}
                textToHighlight={code}
              />
            ) : (
              code
            )}
          </span>
        );
      },
    },
    {
      title: "Titel",
      dataIndex: "label",
      sorter: loading
        ? undefined
        : (a, b) => {
            if (!a.label && !b.label) {
              return 0;
            }
            if (!a.label) {
              return -1;
            }
            if (!b.label) {
              return 1;
            }

            return a.label.localeCompare(b.label);
          },
      className: "min-w-[100px]",
      render: (_, row) => {
        if (loading) {
          return <SkeletonInput size="small" active block />;
        }

        return (
          <span className="signal">
            {searchFilter ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchFilter]}
                textToHighlight={row.label}
              />
            ) : (
              row.label
            )}{" "}
            {row.signal === Signal.YELLOW ? (
              <FontAwesomeIcon className="yellow" icon={faExclamationCircle} />
            ) : row.signal === Signal.RED ? (
              <FontAwesomeIcon className="red" icon={faTimesCircle} />
            ) : null}{" "}
            {row.decisionRequired ? (
              <Tooltip title="Entscheidungsbedarf">
                <Tag color="warning" style={{ marginLeft: "10px" }}>
                  <img src="/icons/decision.svg" style={{ height: "20px" }} />
                </Tag>
              </Tooltip>
            ) : null}
          </span>
        );
      },
    },
    {
      title: "Fortschritt",
      width: 120,
      sorter: loading ? undefined : (a, b) => a.progress - b.progress,
      dataIndex: "combinedProgress",
      render: (progress) => {
        if (loading) {
          return <SkeletonInput size="small" active />;
        }

        return (
          <Tooltip title={`${progress}%`}>
            <Progress percent={progress} showInfo={false} />
          </Tooltip>
        );
      },
    },
    {
      title: "Start (plan)",
      width: 110,
      dataIndex: "plannedStart",
      sorter: loading
        ? undefined
        : (a, b) => {
            const plannedStartA = +new Date(a.plannedStart ?? 0);
            const plannedStartB = +new Date(b.plannedStart ?? 0);
            return plannedStartA - plannedStartB;
          },
      render: (plannedStart) => {
        if (loading) {
          return <SkeletonInput size="small" active block />;
        }

        return (
          <span className="noWrap" data-tag="allowRowEvents">
            {plannedStart
              ? moment(plannedStart ?? new Date()).format("DD.MM.YYYY")
              : "Unbekannt"}
          </span>
        );
      },
    },
    {
      title: "Ende (plan)",
      width: 110,
      dataIndex: "plannedFinish",
      sorter: loading
        ? undefined
        : (a, b) => {
            const plannedFinishA = +new Date(a.plannedFinish ?? 0);
            const plannedFinishB = +new Date(b.plannedFinish ?? 0);
            return plannedFinishA - plannedFinishB;
          },
      render: (plannedFinish) => {
        if (loading) {
          return <SkeletonInput size="small" active block />;
        }

        return (
          <span className="noWrap" data-tag="allowRowEvents">
            {plannedFinish
              ? moment(plannedFinish ?? new Date()).format("DD.MM.YYYY")
              : "Unbekannt"}
          </span>
        );
      },
    },
    {
      title: "Letzte Änderung",
      width: 140,
      dataIndex: "updatedAt",
      sorter: loading
        ? undefined
        : (a, b) => {
            return (
              +new Date(b.date ?? new Date()) - +new Date(a.date ?? new Date())
            );
          },
      render: (_, row) => {
        if (loading) {
          return <SkeletonInput size="small" active />;
        }

        return (
          <Tooltip
            title={
              <>
                am: {dayjs(row.date ?? new Date()).format("DD.MM.YYYY")}
                <>
                  <br />
                  {"durch: "}
                  <ProjectUserName userId={row.author} />
                </>
              </>
            }
          >
            <span className="noWrap" data-tag="allowRowEvents">
              <TimeAgo
                datetime={row.date ?? new Date()}
                locale="de"
                opts={{ minInterval: 60 }}
              />
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Zuständigkeit",
      width: 110,
      dataIndex: "responsibleUserId",
      sorter: loading
        ? undefined
        : (a, b) => {
            if (!a.responsibleUserId && !b.responsibleUserId) {
              return 0;
            }

            if (!a.responsibleUserId) {
              return -1;
            }
            if (!b.responsibleUserId) {
              return 1;
            }

            const responsibleA =
              projectUsers.find((u) => u.userId === a.responsibleUserId)
                ?.name ?? "";
            const responsibleB =
              projectUsers.find((u) => u.userId === b.responsibleUserId)
                ?.name ?? "";

            return responsibleA.localeCompare(responsibleB);
          },
      render: (_, row) => {
        if (loading) {
          return <SkeletonAvatar size="small" active />;
        }

        if (!row.responsibleUserId) return "Niemand";

        return (
          <Tooltip
            title={
              <ProjectUserName userId={row.responsibleUserId ?? "Unbekannt"} />
            }
          >
            {/* this span is needed for the tooltip to work */}
            <span>
              <ProjectUserAvatar userId={row.responsibleUserId} />
            </span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      <Row
        gutter={[10, 10]}
        align="middle"
        wrap={false}
        className="pl-1 pr-1 pt-3 pb-3 bg-white rounded-tl-lg rounded-tr-lg !ml-0 !mr-0"
        style={{ border: "1px solid rgb(216, 216, 216)", borderBottom: 0 }}
      >
        <Col
          style={{
            fontSize: "larger",
            fontWeight: "bold",
            marginRight: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Arbeitspakete
          <Tooltip title="Geplante Arbeitspakete">
            <Badge
              className="ml-2"
              count={
                filteredPackages?.filter(
                  (p) => p.progress === 0 || p.progress === null,
                ).length ?? 0
              }
              showZero
              offset={[5, 0]}
              overflowCount={99999999}
              style={{ background: "gray" }}
            />
          </Tooltip>
          <Tooltip title="Aktive Arbeitspakete">
            <Badge
              className="ml-1"
              count={filteredPackages?.filter((p) => !p.done)?.length ?? 0}
              showZero
              offset={[5, 0]}
              overflowCount={99999999}
              style={{ background: "#0097cc" }}
            />
          </Tooltip>
          <Tooltip title="Erledigte Arbeitspakete">
            <Badge
              className="ml-1"
              count={filteredPackages?.filter((p) => p.done)?.length ?? 0}
              showZero
              offset={[5, 0]}
              overflowCount={99999999}
              style={{ background: "rgb(127,194,54)" }}
            />
          </Tooltip>
        </Col>
        <Col
          flex={1}
          className="unprintable"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <WorkPackageSearchWithFilter
            style={{
              fontSize: "larger",
              fontWeight: "bold",
              marginRight: "50px",
              display: "flex",
              alignItems: "center",
            }}
          />
        </Col>
        <Col className="unprintable">
          <WorkPackageTableReportBtn />
        </Col>
      </Row>
      <ReduxTable<FilteredPackages[0]>
        tableId="workpackageTable"
        columns={columns}
        dataSource={loading ? ([1, 2, 3, 4, 5] as any) : filteredPackages}
        className="workpackageTable printable"
        pagination={{
          pageSizeOptions: [20, 50, 100, 200, 500],
        }}
        locale={{
          ...antdConfig.locale?.Table,
          emptyText: (
            <Empty
              description="Keine Arbeitspakete vorhanden"
              className="mt-10 mb-10 text-gray-400"
            />
          ),
        }}
        sticky={{ offsetHeader: 64 }}
        scroll={
          filteredPackages?.length === 0 ? undefined : { x: "max-content" }
        }
        size="middle"
        onRow={(record) => ({
          onClick: loading
            ? undefined
            : () => {
                if (window.getSelection()?.toString() === "") {
                  return navigate(`/p/${record.code}`);
                } else {
                  console.debug(
                    "did not navigate because of selection:",
                    window.getSelection()?.toString(),
                  );
                }
              },
        })}
        rowKey={(row) => row?.code ?? JSON.stringify(row)}
        rowClassName={(_, idx) =>
          idx % 2 === 0 ? "table-row-even" : "table-row-odd"
        }
      />
    </div>
  );
};
